import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    isLoadingScreen: true,
    isPopupImage: false,
    popupImages: [],
  },
  reducers: {
    loadingHandlerCompleted: (state) => {
      return { ...state, isLoadingScreen: false }
    },
    loadingHandlerUncompleted: (state) => {
      return { ...state, isLoadingScreen: true }
    },
    changePopupImage: (state, action) => {
      return {
        ...state,
        isPopupImage: !state.isPopupImage,
        popupImages: action.payload,
      }
    },
  },
})

export const {
  loadingHandlerCompleted,
  loadingHandlerUncompleted,
  changePopupImage,
} = globalSlice.actions

export default globalSlice.reducer
