import css from './StatusItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const StatusItem = ({ className, name, icon, from, to }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_icon}>
      <img src={icon} alt={name} />
    </div>
    <div className={css.item_name}>{name}</div>
    <div className={css.item_text}>
      from <span className={css.item_white}>{from}</span> to {to}
    </div>
  </div>
)

StatusItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  from: PropTypes.string,
  to: PropTypes.string,
}

export default StatusItem
