import css from './CatalogItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Components
import { Link } from 'UI'
// Images
import errorImg from 'images/error.png'

const Wrapper = ({ href, onClick, children, small, active, className, id }) => {
  if (href) {
    return (
      <Link
        className={cn(
          css.item,
          small && css.small,
          active && css.active,
          className
        )}
        href={href || '/'}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <div
        className={cn(
          css.item,
          small && css.small,
          active && css.active,
          className
        )}
        onClick={() => onClick(id)}
      >
        {children}
      </div>
    )
  }
}

const CatalogItem = ({
  className,
  name,
  image,
  category,
  small,
  onClick,
  id,
  active,
  href,
}) => (
  <Wrapper
    href={href}
    onClick={onClick}
    small={small}
    active={active}
    className={className}
    id={id}
  >
    <div className={css.item_wrapper}>
      <img
        src={image}
        alt={name}
        className={css.item_img}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = errorImg
        }}
      />
    </div>
    <div className={css.item_name}>{name}</div>
    <div className={css.item_category}>{category}</div>
  </Wrapper>
)

CatalogItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  active: PropTypes.bool,
}

export default CatalogItem
