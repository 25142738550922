import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'

export const auctionNfts = [
  {
    id: 1,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/1',
  },
  {
    id: 2,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    href: '/profile/auction/2',
  },
  {
    id: 3,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    href: '/profile/auction/3',
  },
  {
    id: 4,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/4',
  },
  {
    id: 5,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/5',
  },
  {
    id: 6,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    href: '/profile/auction/6',
  },
  {
    id: 7,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    href: '/profile/auction/7',
  },
  {
    id: 8,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/8',
  },
  {
    id: 9,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/9',
  },
  {
    id: 10,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    href: '/profile/auction/10',
  },
  {
    id: 11,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    href: '/profile/auction/11',
  },
  {
    id: 12,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    href: '/profile/auction/12',
  },
]
