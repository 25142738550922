import * as yup from 'yup'

export const categories = [
  { id: 1, label: 'Art' },
  { id: 2, label: 'Collectibles' },
  { id: 3, label: 'Music' },
  { id: 4, label: 'Photography' },
  { id: 5, label: 'Sports' },
  { id: 6, label: 'Trading Cards' },
  { id: 7, label: 'Utility' },
]

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/

export const schema = yup.object().shape({
  description: yup.string().max(250, 'Максимум 250 символов'),
  name: yup.string().required('Введите что-нибудь!'),
})
