import { useEffect, useState } from 'react'
import css from './UnlockContent.module.scss'
import cn from 'classnames'
// Components
import { Switch, Input } from 'UI'
// Images
import unlockIcon from 'icons/unlock.svg'

const UnlockContent = ({ className, value, setFieldValue }) => {
  const [checked, setChecked] = useState(false)

  const onChangeSwitch = () => {
    setChecked(!checked)
    setFieldValue('unlockableContent', '')
  }

  useEffect(() => {
    if (value && value.trim() !== '') {
      setChecked(true)
    }
  }, [value])

  return (
    <div className={cn(css.item, className)}>
      <div className={css.item_wrap}>
        <img src={unlockIcon} alt="img" className={css.item_icon} />
        <div className={css.item_info}>
          <div className={css.item_title}>Unlockable Content</div>
          <div className={css.item_description}>
            Include unlockable content that can only be revealed by the owner of
            the item.
          </div>
        </div>
      </div>
      <Switch checked={checked} onChange={onChangeSwitch} />
      {checked && (
        <Input
          name="unlockableContent"
          textarea
          className={css.item_input}
          placeholder="Include unlockable content that can only be revealed by the owner of the item."
        />
      )}
    </div>
  )
}

export default UnlockContent
