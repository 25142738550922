import css from './TooltipQuestion.module.scss'
// Components
import questionIcon from 'icons/question.svg'

const TooltipQuestion = ({ id }) => (
  <a className={css.tooltip} data-tip data-for={id}>
    <img src={questionIcon} alt="question" />
  </a>
)

export default TooltipQuestion
