import { useState } from 'react'
import css from './Activity.module.scss'
import { Formik } from 'formik'
// Components
import { Select, Checkbox } from 'UI'
import FilterPanel from '../FilterPanel/FilterPanel'
import NftCollectionItem from 'components/Items/NftCollectionItem'
// Images
import filterIcon from 'icons/filter.svg'
import nftCollection1Img from 'images/nft-collection1.png'

const options = [
  { label: 'Last 7 days', value: 1 },
  { label: 'Last 14 days', value: 2 },
  { label: 'Last 30 days', value: 3 },
  { label: 'Last 60 days', value: 4 },
  { label: 'Last 90 days', value: 5 },
  { label: 'Last year', value: 6 },
  { label: 'All time', value: 7 },
]

const Activity = () => {
  const [isSales, setIsSales] = useState(false)
  const [isListings, setIsListings] = useState(false)
  const [isOffers, setIsOffers] = useState(false)
  const [isCollectionOffers, setIsCollectionOffers] = useState(false)
  const [isTransfer, setIsTransfer] = useState(false)
  const [isShowSidebar, setIsShowSidebar] = useState(true)

  const changeShowSidebar = () => {
    setIsShowSidebar(!isShowSidebar)
  }

  const changeSales = () => {
    setIsSales(!isSales)
  }

  const changeListings = () => {
    setIsListings(!isListings)
  }

  const changeOffers = () => {
    setIsOffers(!isOffers)
  }

  const changeCollectionOffers = () => {
    setIsCollectionOffers(!isCollectionOffers)
  }

  const changeTransfer = () => {
    setIsTransfer(!isTransfer)
  }

  return (
    <div>
      <Formik initialValues={{ search: '', filter: options[0] }}>
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.filter} onClick={changeShowSidebar}>
              <img src={filterIcon} alt="filter" />
            </div>
            <Select
              options={options}
              placeholder=""
              name="filter"
              onChange={props.setFieldValue}
              className={css.select}
            />
          </form>
        )}
      </Formik>
      <div className={css.wrapper}>
        {isShowSidebar && (
          <div className={css.sidebar}>
            <Formik initialValues={{ nickname: '', wallet: '', password: '' }}>
              {(props) => (
                <div>
                  <FilterPanel name="Event Type" className={css.panel}>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>Sales</div>
                      <Checkbox
                        onChange={changeSales}
                        value={isSales}
                        id={1}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>Listings</div>
                      <Checkbox
                        onChange={changeListings}
                        value={isListings}
                        id={2}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>Offers</div>
                      <Checkbox
                        onChange={changeOffers}
                        value={isOffers}
                        id={3}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>
                        Collection offers
                      </div>
                      <Checkbox
                        onChange={changeCollectionOffers}
                        value={isCollectionOffers}
                        id={4}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>Transfers</div>
                      <Checkbox
                        onChange={changeTransfer}
                        value={isTransfer}
                        id={5}
                        className={css.checkbox}
                      />
                    </div>
                  </FilterPanel>
                </div>
              )}
            </Formik>
          </div>
        )}
        <div className={css.items}>
          <NftCollectionItem
            title="Future sight"
            price="0.311412 eth"
            href="/"
            image={nftCollection1Img}
          />
        </div>
      </div>
    </div>
  )
}

export default Activity
