import { useDispatch, useSelector } from 'react-redux'
import css from './CreateNftSuccessModal.module.scss'
// Components
import { Popup, Link } from 'UI'
// Store
import { modalCreateNftModalHandler } from 'store/nft'

const CreateNftSuccessModal = () => {
  const dispatch = useDispatch()
  const nft = useSelector((state) => state.nft.nft)
  const isShowCreateNftModal = useSelector(
    (state) => state.nft.isShowCreateNftModal
  )

  return (
    <Popup
      open={isShowCreateNftModal}
      closeModal={() => dispatch(modalCreateNftModalHandler(false))}
      openModal={() => dispatch(modalCreateNftModalHandler(true))}
      title={'You created ' + nft.name}
    >
      <div className={css.popup}>
        You just created <Link href={'/nft/' + nft._id}>{nft.name}</Link>
      </div>
    </Popup>
  )
}

export default CreateNftSuccessModal
