import { useMemo } from 'react'
import css from './NftItemPage.module.scss'
// Components
import CatalogItem from 'components/Items/CatalogItem'
import PrevPage from 'components/PrevPage'
import ItemInfo from 'components/ItemInfo'
import NftItem from 'components/Items/NftItem'
import RecommendedSlider from 'components/Slider/RecommendedSlider'
import NftCollectionItem from 'components/Items/NftCollectionItem'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import catalog1Img from 'images/catalog1.jpeg'
// Utils
import { recomendedItems, recommendedCollections } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const NftItemPage = () => {
  const recomendedItemsContent = useMemo(
    () => (
      <div className={css.main_items}>
        {recomendedItems?.map((item) => (
          <CatalogItem
            {...item}
            key={item.id}
            small
            className={css.main_item}
          />
        ))}
      </div>
    ),
    []
  )

  const sliderContent = useMemo(
    () =>
      recommendedCollections?.map((item) => (
        <NftCollectionItem key={item.id} {...item} />
      )),
    []
  )

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <PrevPage href="/catalog">Back to the catalog</PrevPage>
        <div className={css.main}>
          <div className={css.main_img}>
            <NftItem image={catalog1Img} level="15th level" />
          </div>
          <ItemInfo
            title="Fireball"
            subtitle="Items"
            description="Once he went on a journey with the goal of getting to the top of the mountain of the universe to meditate. Still on the journey."
            category="mars"
            price="0.311412 ETH"
            itemsTitle="Recommended fit items"
            recomendedItems={recomendedItemsContent}
          />
        </div>
        <RecommendedSlider
          items={sliderContent}
          title="Recommended nft collections"
          className={css.slider}
        />
      </div>
    </LayoutWithBg>
  )
}

export default NftItemPage
