import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './FaqPage.module.scss'
// Components
import QuestionItem from 'components/Items/QuestionItem'
import { Spinner, Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Api
import { getPageRequest } from 'api/pages'
// Helmet
import CustomHelmet from './Helmet'

const FaqPage = () => {
  const { i18n } = useTranslation()
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const questionHandler = useCallback(
    (id) => {
      const copyQuestions = questions.map((item) => {
        if (item.id === id) {
          item.active = !item.active
        }

        return item
      })

      setQuestions(copyQuestions)
    },
    [questions]
  )

  useEffect(() => {
    fetchPage()
  }, [i18n.language])

  const fetchPage = async () => {
    setIsLoading(true)

    try {
      const { data } = await getPageRequest('faq')
      const content = data.content.map((item) => ({
        ...item,
        active: false,
      }))

      setIsLoading(false)
      setQuestions(content)
    } catch (error) {}
  }

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.content}>
          <Title className={css.h2} type="h2">
            F.A.Q
          </Title>
          {isLoading ? (
            <div className={css.spinner}>
              <Spinner />
            </div>
          ) : (
            <div className={css.items}>
              {questions?.map((item, index) => (
                <QuestionItem
                  key={index}
                  {...item}
                  onClick={questionHandler}
                  className={css.item}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default FaqPage
