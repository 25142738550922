import { useState } from 'react'
import css from './MakeAnOffer.module.scss'
import { Formik } from 'formik'
import { DatePicker } from 'rsuite'
// Components
import { Button, Input, Select } from 'UI'
// Images
import arrowIcon from 'icons/arrow.svg'
// Utils
import { currencies } from './utils'

const MakeAnOffer = ({ changeStep }) => {
  const [date, setDate] = useState(null)

  const onSubmit = (values) => {
    // changeStep()
  }

  return (
    <div>
      <div className={css.back} onClick={() => changeStep(1)}>
        <img src={arrowIcon} alt="arrow" />
      </div>
      <Formik
        initialValues={{ amount: '', currency: currencies[0] }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.input}>
              <div className={css.label}>Offer amount</div>
              <div className={css.input_inner}>
                <Select
                  options={currencies}
                  placeholder=""
                  name="currency"
                  onChange={props.setFieldValue}
                  className={css.select}
                  isSearchable={false}
                />
                <Input
                  name="amount"
                  type="text"
                  placeholder="Amount"
                  className={css.input_wrap}
                />
              </div>
            </div>
            <div className={css.input}>
              <div className={css.label}>Offer amount</div>
              <div className={css.datepicker}>
                <DatePicker
                  format="yyyy-MM-dd HH:mm"
                  value={date}
                  onChange={setDate}
                  placeholder="Select Date"
                  className={css.datepicker_input}
                />
              </div>
            </div>
            <Button className={css.btn} type="submit">
              Make offer
            </Button>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default MakeAnOffer
