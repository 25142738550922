import bubbles1Img from 'images/bubbles1.jpeg'
import bubbles2Img from 'images/bubbles2.jpeg'
import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'
import nftCollection1Img from 'images/nft-collection1.png'
import nftCollection2Img from 'images/nft-collection2.png'
import nftCollection3Img from 'images/nft-collection3.png'
import avatar1Img from 'images/avatar1.png'
import avatar2Img from 'images/avatar2.png'

export const aboutItems = [
  {
    id: 1,
    title: 'nft cards',
    description: 'Common, venus, mars, mercury, jupiter, saturn in-game cards)',
    image: bubbles1Img,
  },
  {
    id: 2,
    title: 'nft items',
    description: '(arts, pics, audio, video, gifs etc.)',
    image: bubbles2Img,
  },
]

export const tabsNftCards = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'Common',
  },
  {
    id: 3,
    text: 'Venus',
  },
  {
    id: 4,
    text: 'Mars',
  },
  {
    id: 5,
    text: 'Mercury',
  },
  {
    id: 6,
    text: 'Jupiter',
  },
  {
    id: 7,
    text: 'Saturn',
  },
]

export const tabsNftCollections = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'Art',
  },
  {
    id: 3,
    text: 'Techno',
  },
  {
    id: 4,
    text: 'Music',
  },
]

export const tabsNftCardsItems = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
  { id: 5, image: nftCard1Img },
  { id: 6, image: nftCard2Img },
  { id: 7, image: nftCard1Img },
  { id: 8, image: nftCard2Img },
]

export const tabsNftCollectionsItems = [
  {
    id: 1,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 2,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 3,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 4,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 5,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 6,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 7,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 8,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
]

export const table1 = [
  {
    id: 1,
    name: 'Custom_top_seller',
    price: '146 nfts',
    status: 'sold',
    avatar: avatar1Img,
  },
  {
    id: 2,
    name: 'Custom_top_seller',
    price: '146 nfts',
    status: 'sold',
    avatar: avatar2Img,
  },
  {
    id: 3,
    name: 'Custom_top_seller',
    price: '146 nfts',
    status: 'sold',
    avatar: avatar1Img,
  },
  {
    id: 4,
    name: 'Custom_top_seller',
    price: '146 nfts',
    status: 'sold',
    avatar: avatar2Img,
  },
  {
    id: 5,
    name: 'Custom_top_seller',
    price: '146 nfts',
    status: 'sold',
    avatar: avatar1Img,
  },
]

export const table2 = [
  { id: 1, name: 'Dragon set (in-game collection)', avatar: avatar1Img },
  { id: 2, name: 'Wind art collection', avatar: avatar2Img },
  { id: 3, name: 'Wind art collection', avatar: avatar1Img },
  { id: 4, name: 'Wind art collection', avatar: avatar2Img },
  { id: 5, name: 'Wind art collection', avatar: avatar1Img },
]

export const table3 = [
  {
    id: 1,
    name: 'Deckaaaa',
    price: '78.33%',
    status: 'win rate',
    avatar: avatar1Img,
    subtitle: 'Venus',
  },
  {
    id: 2,
    name: 'Deckaaaa',
    price: '78.33%',
    status: 'win rate',
    avatar: avatar2Img,
    subtitle: 'Venus',
  },
  {
    id: 3,
    name: 'Deckaaaa',
    price: '78.33%',
    status: 'win rate',
    avatar: avatar1Img,
    subtitle: 'Venus',
  },
  {
    id: 4,
    name: 'Deckaaaa',
    price: '78.33%',
    status: 'win rate',
    avatar: avatar2Img,
    subtitle: 'Venus',
  },
  {
    id: 5,
    name: 'Deckaaaa',
    price: '78.33%',
    status: 'win rate',
    avatar: avatar1Img,
    subtitle: 'Venus',
  },
]

export const mintLinks = [
  { id: 1, text: 'How to mint', href: '/' },
  { id: 2, text: 'F.A.Q', href: '/faq' },
]
