import css from './Links.module.scss'
import cn from 'classnames'
// Components
import { Input } from 'UI'
// Images
import globalIcon from 'icons/global.svg'
import discordGreyIcon from 'icons/social-network/discord-grey.svg'
import instagramGreyIcon from 'icons/social-network/instagram-grey.svg'
import telegramGreyIcon from 'icons/social-network/telegram-grey.svg'
import mediumGreyIcon from 'icons/social-network/medium.svg'

const Links = ({ className }) => {
  return (
    <div className={cn(css.links, className)}>
      <Input
        name="globalLink"
        classNameInput={css.input}
        leftIcon={
          <img src={globalIcon} alt="icon" className={css.input_icon} />
        }
        placeholder="yoursite.io"
      />
      <Input
        name="discord"
        classNameInput={css.input}
        prefix="https://discord.gg/"
        leftIcon={
          <img src={discordGreyIcon} alt="icon" className={css.input_icon} />
        }
      />
      <Input
        name="instagram"
        classNameInput={css.input}
        prefix="https://www.instagram.com/"
        leftIcon={
          <img src={instagramGreyIcon} alt="icon" className={css.input_icon} />
        }
      />
      <Input
        name="telegram"
        classNameInput={css.input}
        prefix="https://t.me/"
        leftIcon={
          <img src={telegramGreyIcon} alt="icon" className={css.input_icon} />
        }
      />
      <Input
        name="medium"
        classNameInput={css.input}
        prefix="https://www.medium.com/"
        leftIcon={
          <img src={mediumGreyIcon} alt="icon" className={css.input_icon} />
        }
      />
    </div>
  )
}

export default Links
