import css from './Tooltip.module.scss'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

const Tooltip = ({ type = 'light', children, id, className, ...props }) => (
  <ReactTooltip
    type={type}
    effect="solid"
    id={id}
    className={cn(css.tooltip, className)}
    {...props}
  >
    {children}
  </ReactTooltip>
)

Tooltip.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Tooltip
