import nftCollection1Img from 'images/nft-collection1.png'
import nftCollection2Img from 'images/nft-collection2.png'
import nftCollection3Img from 'images/nft-collection3.png'
import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'

export const recomendedItems = [
  { id: 1, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 2, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 3, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 4, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
]

export const recommendedCollections = [
  {
    id: 1,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 2,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 3,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 4,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 5,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 6,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 7,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 8,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 9,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
]
