import css from './NftSellCardPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
// Components
import PrevPage from 'components/PrevPage'
import SellNftForm from 'components/Forms/SellNftForm'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Helmet
import CustomHelmet from './Helmet'
// Store
import { getNftHandler } from 'store/nft'

const NftSellCardPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const nft = useSelector((state) => state.nft.nft)

  useEffect(() => {
    dispatch(getNftHandler(id))
  }, [dispatch, id])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.wrapper}>
          <PrevPage
            href={'/nft/' + nft._id}
            image={process.env.REACT_APP_IMAGE_URL + nft.file}
            className={css.prevpage}
          >
            Back to the NFT
          </PrevPage>
          <Title className={css.title} type="h2">
            List item for sale
          </Title>
          <SellNftForm />
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default NftSellCardPage
