import css from './TimedAuctionForm.module.scss'
import { Formik } from 'formik'
import { useState } from 'react'
// Utils
import { methodsOptions, priceOptions } from './utils'
// Components
import { Select, Tooltip, Input, Button } from 'UI'
import TooltipQuestion from 'components/TooltipQuestion'
import Datepicker from './Datepicker/Datepicker'

const TimedAuctionForm = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const onSubmit = (values) => {
    console.log(values)
  }

  return (
    <div className={css.wrapper}>
      <Formik
        initialValues={{
          method: methodsOptions[0],
          priceCurrency: priceOptions[0],
        }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>Method</div>
                <TooltipQuestion id="method" />
              </div>
              <Select
                options={methodsOptions}
                placeholder=""
                name="method"
                onChange={props.setFieldValue}
                className={css.select}
              />
            </div>
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>Starting price</div>
              </div>
              <div className={css.input_price}>
                <Select
                  options={priceOptions}
                  placeholder=""
                  name="priceCurrency"
                  onChange={props.setFieldValue}
                  className={css.select_price}
                />
                <Input
                  name="startingPrice"
                  placeholder="Amount"
                  className={css.input_wrap}
                />
              </div>
            </div>
            <Datepicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>Ending price</div>
              </div>
              <div className={css.input_price}>
                <Select
                  options={priceOptions}
                  placeholder=""
                  name="priceCurrency"
                  onChange={props.setFieldValue}
                  className={css.select_price}
                />
                <Input
                  name="endingPrice"
                  placeholder="Amount"
                  className={css.input_wrap}
                />
              </div>
            </div>
            <div className={css.info}>
              <div className={css.info_item}>
                <span className={css.info_subtitle}>Fees</span>
                <TooltipQuestion id="fees" />
              </div>
              <div className={css.info_item}>
                <span>Service Fee</span>
                <span>2.5%</span>
              </div>
              <div className={css.info_item}>
                <span>Creator Fee</span>
                <span>1%</span>
              </div>
            </div>
            <Button type="submit">Complete listing</Button>
          </form>
        )}
      </Formik>
      <Tooltip id="method">
        Learn more about the two types of listing options in our Help Center
      </Tooltip>
      <Tooltip id="fees">
        Listing is free. Once sold, the following fees will be deducted. Learn
        more
      </Tooltip>
    </div>
  )
}

export default TimedAuctionForm
