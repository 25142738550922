import css from './404Page.module.scss'
// Components
import { Button, Link, Title } from 'UI'
// Images
import mainBgImg from 'images/main-bg.png'
import mainBgMobileImg from 'images/main-bg-mobile.png'
import moonPurpleImg from 'images/moon-purple.svg'
// Helmet
import CustomHelmet from './Helmet'

const Custom404Page = () => {
  return (
    <div className={css.container}>
      <CustomHelmet />
      <div className={css.section_top}>
        <div className={css.section_top_wrap}>
          <div className={css.h1}>
            <Title type="h1">4</Title>
            <img src={moonPurpleImg} alt="moon" />
            <Title type="h1">4</Title>
          </div>
          <Title className={css.description} type="h2">
            page not found
          </Title>
          <div className={css.text}>
            The link you click may be broken or the page
            <br />
            may have been removed
          </div>
          <Link href="/">
            <Button>Go to homepage</Button>
          </Link>
        </div>
        <img src={mainBgImg} alt="bg" className={css.section_top_bg} />
        <img
          src={mainBgMobileImg}
          alt="bg"
          className={css.section_top_bg_mobile}
        />
      </div>
    </div>
  )
}

export default Custom404Page
