import { useCallback, useEffect, useMemo, useState } from 'react'
import css from './MintNftCollectionPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Formik } from 'formik'
import cn from 'classnames'
// Components
import { Button, Input, Dropdown, Title } from 'UI'
import MainTabItem from 'components/Items/MainTabItem'
import CatalogItem from 'components/Items/CatalogItem'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import duckImg from 'images/duck.png'
import searchIcon from 'icons/search.svg'
// Utils
import { currencies, tabs, nftItems } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const search = (items, term) => {
  if (term.length === 0) {
    return items
  }

  return items.filter((item) => {
    return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
  })
}

const MintNftCollectionPage = () => {
  const [currency, setCurrency] = useState({})
  const [items, setItems] = useState(nftItems)

  const submitHandler = useCallback((values) => {
    console.log(values)
  }, [])

  const onChangeCurrency = useCallback((value) => {
    setCurrency(value)
  }, [])

  useEffect(() => {
    setCurrency(currencies[0])
  }, [])

  const submitSearch = useCallback((values) => {
    console.log(values)
  }, [])

  const changeActiveItem = useCallback(
    (id) => {
      const copyArr = [...items]
      copyArr.forEach((item) => {
        if (item.id === id) {
          if (item.active) {
            item.active = false
          } else {
            item.active = true
          }
        }
      })
      setItems(copyArr)
    },
    [items]
  )

  const nftItemsContent = useMemo(
    () => (
      <div className={css.items}>
        {items?.map((item) => (
          <CatalogItem
            className={css.items_item}
            {...item}
            key={item.id}
            small
            onClick={changeActiveItem}
            active={item.active}
          />
        ))}
      </div>
    ),
    [items, changeActiveItem]
  )

  const onChangeSearch = useCallback((e, onChange) => {
    onChange('search', e.target.value)
    const filteredArr = search(nftItems, e.target.value)
    setItems(filteredArr)
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.wrapper}>
          <div className={css.content}>
            <div className={css.avatar_wrap}>
              <div className={cn(css.avatar, css.avatar_left)}>
                <img src={duckImg} alt="duck1" />
              </div>
              <div className={css.avatar}>
                <img src={duckImg} alt="duck2" />
              </div>
              <div className={cn(css.avatar, css.avatar_right)}>
                <img src={duckImg} alt="duck3" />
              </div>
            </div>
            <Title className={css.title} type="h2">
              mint nft collection
            </Title>
            <Tabs className={css.tabs}>
              <TabList className={css.tabs_tablist}>
                <div className={cn(css.tabs_titles, 'main-tabs')}>
                  {tabs?.map((item) => (
                    <Tab
                      key={item.id}
                      selectedClassName="main-tab-active"
                      className="main-tab"
                    >
                      <MainTabItem {...item} className="main-tab-item-active" />
                    </Tab>
                  ))}
                </div>
              </TabList>
              <TabPanel>
                <div className={css.tabs_tabpanel}>
                  <Formik
                    initialValues={{ name: '', description: '', cost: '' }}
                    onSubmit={(values) => submitHandler(values)}
                  >
                    {(props) => (
                      <form className={css.form} onSubmit={props.handleSubmit}>
                        <div className={css.form_input}>
                          <div className={css.form_input_name}>
                            NFT Collection Name
                          </div>
                          <Input
                            name="name"
                            type="text"
                            className={css.input}
                            classNameInput={css.form_input_wrap}
                          />
                        </div>
                        <div className={css.form_textarea}>
                          <div className={css.form_input_name}>Description</div>
                          <Input
                            textarea
                            name="description"
                            type="text"
                            className={css.textarea}
                          />
                          <div className={css.form_textarea_up}>up to 250</div>
                        </div>
                        <div className={css.form_input}>
                          <div className={css.form_input_name}>
                            Cost of the NFT collection
                          </div>
                          <Input
                            name="cost"
                            type="text"
                            className={css.input}
                            classNameInput={css.form_input_wrap}
                            placeholder="0.00"
                            rightIcon={
                              <Dropdown
                                list={currencies}
                                onChange={onChangeCurrency}
                                activeItem={currency}
                              />
                            }
                          />
                        </div>
                        <Formik
                          initialValues={{ search: '' }}
                          onSubmit={(values) => submitSearch(values)}
                        >
                          {(props) => (
                            <div className={css.form_search}>
                              <Input
                                name="search"
                                type="text"
                                placeholder="Search for NFT item"
                                className={css.form_search_input}
                                onChange={(e) =>
                                  onChangeSearch(e, props.setFieldValue)
                                }
                                leftIcon={<img src={searchIcon} alt="search" />}
                              />
                            </div>
                          )}
                        </Formik>
                        {nftItemsContent}
                        <Button type="submit" className={css.form_btn}>
                          Mint NFT
                        </Button>
                      </form>
                    )}
                  </Formik>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={css.tabs_tabpanel}>
                  <Formik
                    initialValues={{ name: '', description: '', cost: '' }}
                    onSubmit={(values) => submitHandler(values)}
                  >
                    {(props) => (
                      <form className={css.form} onSubmit={props.handleSubmit}>
                        <div className={css.form_input}>
                          <div className={css.form_input_name}>
                            NFT Collection Name
                          </div>
                          <Input
                            name="name"
                            type="text"
                            className={css.input}
                            classNameInput={css.form_input_wrap}
                          />
                        </div>
                        <div className={css.form_textarea}>
                          <div className={css.form_input_name}>Description</div>
                          <Input
                            textarea
                            name="description"
                            type="text"
                            className={css.textarea}
                          />
                          <div className={css.form_textarea_up}>up to 250</div>
                        </div>
                        <div className={css.form_input}>
                          <div className={css.form_input_name}>
                            Cost of the NFT collection
                          </div>
                          <Input
                            name="cost"
                            type="text"
                            className={css.input}
                            classNameInput={css.form_input_wrap}
                            placeholder="0.00"
                            rightIcon={
                              <Dropdown
                                list={currencies}
                                onChange={onChangeCurrency}
                                activeItem={currency}
                              />
                            }
                          />
                        </div>
                        <Formik
                          initialValues={{ search: '' }}
                          onSubmit={(values) => submitSearch(values)}
                        >
                          {(props) => (
                            <div className={css.form_search}>
                              <Input
                                name="search"
                                type="text"
                                placeholder="Search for NFT item"
                                className={css.form_search_input}
                                onChange={(e) =>
                                  onChangeSearch(e, props.setFieldValue)
                                }
                                leftIcon={<img src={searchIcon} alt="search" />}
                              />
                            </div>
                          )}
                        </Formik>
                        {nftItemsContent}
                        <Button type="submit" className={css.form_btn}>
                          Mint NFT
                        </Button>
                      </form>
                    )}
                  </Formik>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default MintNftCollectionPage
