import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'How to play | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="We created a step-by-step guide for you to ease the process of playing. Just follow these 3 steps and you are all set!"
      />
    </Helmet>
  )
}

export default CustomHelmet
