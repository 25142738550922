export const tableTitles = [
  {
    id: 1,
    text: '#',
  },
  {
    id: 2,
    text: 'Item name',
  },
  {
    id: 3,
    text: 'Race/element',
  },
  {
    id: 4,
    text: 'Cost',
  },
  {
    id: 5,
    text: 'Seller',
  },
  {
    id: 6,
    text: 'Purchase date',
  },
]

export const tableRows = [
  {
    id: 1,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 2,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 3,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 4,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 5,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 6,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 7,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 8,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
]

export const tabsTable = [
  {
    id: 1,
    text: 'Purchase history',
  },
  {
    id: 2,
    text: 'Sales history',
  },
]
