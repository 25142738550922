import { useState } from 'react'
import css from './Items.module.scss'
import { Formik } from 'formik'
// Components
import { Input, Select, Button, Checkbox } from 'UI'
import FilterPanel from '../FilterPanel/FilterPanel'
import NftCollectionItem from 'components/Items/NftCollectionItem'
// Images
import searchIcon from 'icons/search.svg'
import filterIcon from 'icons/filter.svg'
import nftCollection1Img from 'images/nft-collection1.png'

const options = [
  { label: 'Price low to high', value: 1 },
  { label: 'Recently listed', value: 2 },
  { label: 'Recently created', value: 3 },
  { label: 'Recently sold', value: 4 },
  { label: 'Recently recieved', value: 5 },
]

const Items = () => {
  const [isBuyNow, setIsBuyNow] = useState(false)
  const [isOnAuction, setIsOnAuction] = useState(false)
  const [isCurrencyEth, setIsCurrencyEth] = useState(false)
  const [isCurrencyWeth, setIsCurrencyWeth] = useState(false)
  const [isShowSidebar, setIsShowSidebar] = useState(true)

  const changeBuyNow = () => {
    setIsBuyNow(!isBuyNow)
  }

  const changeShowSidebar = () => {
    setIsShowSidebar(!isShowSidebar)
  }

  const changeOnAuction = () => {
    setIsOnAuction(!isOnAuction)
  }

  const changeCurrencyEth = () => {
    setIsCurrencyEth(!isCurrencyEth)
  }

  const changeCurrencyWeth = () => {
    setIsCurrencyWeth(!isCurrencyWeth)
  }

  return (
    <div>
      <Formik initialValues={{ search: '', filter: options[0] }}>
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.filter} onClick={changeShowSidebar}>
              <img src={filterIcon} alt="filter" />
            </div>
            <Input
              name="search"
              type="text"
              placeholder="Search by name or attribute"
              className={css.input}
              leftIcon={<img src={searchIcon} alt="search" />}
            />
            <Select
              options={options}
              placeholder=""
              name="filter"
              onChange={props.setFieldValue}
              className={css.select}
            />
            <Button>Make collection offer</Button>
          </form>
        )}
      </Formik>
      <div className={css.wrapper}>
        {isShowSidebar && (
          <div className={css.sidebar}>
            <Formik initialValues={{ nickname: '', wallet: '', password: '' }}>
              {(props) => (
                <div>
                  <FilterPanel name="Status" className={css.panel}>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>Buy Now</div>
                      <Checkbox
                        onChange={changeBuyNow}
                        value={isBuyNow}
                        id={1}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>On Auction</div>
                      <Checkbox
                        onChange={changeOnAuction}
                        value={isOnAuction}
                        id={2}
                        className={css.checkbox}
                      />
                    </div>
                  </FilterPanel>
                  <FilterPanel name="Currency" className={css.panel}>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>ETH</div>
                      <Checkbox
                        onChange={changeCurrencyEth}
                        value={isCurrencyEth}
                        id={3}
                        className={css.checkbox}
                      />
                    </div>
                    <div className={css.checkbox}>
                      <div className={css.checkbox_label}>WETH</div>
                      <Checkbox
                        onChange={changeCurrencyWeth}
                        value={isCurrencyWeth}
                        id={4}
                        className={css.checkbox}
                      />
                    </div>
                  </FilterPanel>
                </div>
              )}
            </Formik>
          </div>
        )}
        <div className={css.items}>
          <NftCollectionItem
            title="Future sight"
            price="0.311412 eth"
            href="/"
            image={nftCollection1Img}
          />
        </div>
      </div>
    </div>
  )
}

export default Items
