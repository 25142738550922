import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Create new account | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Welcome to BOT Metamarket - a perfect NFT marketplace to buy and sell NFT items, cards, collections to play Nexus Shift and much more. Sing up to become a part of our marketplace!"
      />
    </Helmet>
  )
}

export default CustomHelmet
