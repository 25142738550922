import css from './AboutItemsItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const AboutItemsItem = ({ className, title, description, image }) => (
  <div className={cn(css.item, className)}>
    <img src={image} alt={title} className={css.item_img} />
    <div className={css.item_info}>
      <div className={css.item_title}>{title}</div>
      <div className={css.item_description}>{description}</div>
    </div>
  </div>
)

AboutItemsItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

export default AboutItemsItem
