import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './PropertyInput.module.scss'
// Components
import { Input } from 'UI'
// Images
import closeWhiteIcon from 'icons/close-white.svg'

const PropertyInput = ({ name1, name2, className, close }) => (
  <div className={cn(css.property, className)}>
    <div className={css.property_close} onClick={close}>
      <img src={closeWhiteIcon} alt="close" />
    </div>
    <Input name={name1} type="text" className={css.property_input} />
    <Input name={name2} type="text" className={css.property_input} />
  </div>
)

PropertyInput.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
}

export default PropertyInput
