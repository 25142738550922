import css from './Numbers.module.scss'
import cn from 'classnames'

const Numbers = ({ className }) => {
  return (
    <div className={cn(css.numbers, className)}>
      <div className={css.numbers_item}>
        <div className={css.numbers_item_value}>1</div>
        <div className={css.numbers_item_name}>items</div>
      </div>
      <div className={css.numbers_item}>
        <div className={css.numbers_item_value}>1</div>
        <div className={css.numbers_item_name}>owners</div>
      </div>
      <div className={css.numbers_item}>
        <div className={css.numbers_item_value}>0.00</div>
        <div className={css.numbers_item_name}>total volume</div>
      </div>
      <div className={css.numbers_item}>
        <div className={css.numbers_item_value}>---</div>
        <div className={css.numbers_item_name}>floor price</div>
      </div>
      <div className={css.numbers_item}>
        <div className={css.numbers_item_value}>---</div>
        <div className={css.numbers_item_name}>best offer</div>
      </div>
    </div>
  )
}

export default Numbers
