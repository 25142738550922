import { useCallback, useEffect, useState } from 'react'
import css from './HeaderConnect.module.scss'
import { useLocation } from 'react-router-dom'
// Images
import walletIcon from 'icons/wallet.svg'
import certikIcon from 'icons/certik.svg'
import hackenIcon from 'icons/hacken.svg'
// Components
import { Button } from 'UI'

const HeaderConnect = () => {
  const { pathname } = useLocation()
  const [activeMobileMenu, setActiveMobileMenu] = useState(false)

  const changeActiveMobileMenu = useCallback(() => {
    setActiveMobileMenu(!activeMobileMenu)
  }, [activeMobileMenu])

  useEffect(() => {
    setActiveMobileMenu(false)
  }, [pathname])

  return (
    <div className={css.header_connect}>
      <a
        href="https://hacken.io/audits/#bot_planet"
        target="_blank"
        rel="noreferrer"
      >
        <img src={hackenIcon} alt="hacken" />
      </a>
      <a
        href="https://www.certik.com/projects/bot-planet"
        target="_blank"
        rel="noreferrer"
      >
        <img src={certikIcon} alt="certikIcon" />
      </a>
      <Button variant="blue">
        <div className={css.header_connect_btn}>
          <img src={walletIcon} alt="wallet" />
          Connect Wallet
        </div>
      </Button>
    </div>
  )
}

export default HeaderConnect
