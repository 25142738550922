export const tableTitles = [
  {
    id: 1,
    text: '#',
  },
  {
    id: 2,
    text: 'Date',
  },
  {
    id: 3,
    text: 'Price',
  },
  {
    id: 4,
    text: 'Seller',
  },
  {
    id: 6,
    text: 'Status',
  },
  {
    id: 7,
    text: '',
  },
]

export const tableRows = [
  {
    id: 1,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: true,
  },
  {
    id: 2,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
  {
    id: 3,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
  {
    id: 4,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
  {
    id: 5,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
  {
    id: 6,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
  {
    id: 7,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: true,
  },
  {
    id: 8,
    date: '10/10/2022 10:10 AM',
    cost: '0.143 ETH',
    author: 'Unnamed',
    status: false,
  },
]
