import css from './CreateNftPage.module.scss'
import { useState } from 'react'
// Components
import MainTabItem from 'components/Items/MainTabItem'
import { Title } from 'UI'
import MintNftForm from 'components/Forms/MintNftForm'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import duckImg from 'images/duck.png'
// Utils
import { links } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const CreateNftPage = () => {
  const [type, setType] = useState(links[0].type)

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.wrapper}>
          <div className={css.content}>
            <div className={css.avatar}>
              <img src={duckImg} alt="duck" />
            </div>
            <Title className={css.title} type="h2">
              Create NFT
            </Title>
            <div className={css.tabs}>
              {links?.map((link) => (
                <div
                  key={link.id}
                  className={css.tabs_link}
                  onClick={() => setType(link.type)}
                >
                  <MainTabItem active={type === link.type} text={link.text} />
                </div>
              ))}
            </div>
            <MintNftForm type={type} typeForm="CREATE" />
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default CreateNftPage
