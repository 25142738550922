import css from './SellTabItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const SellTabItem = ({ className, name, icon, active }) => (
  <div className={cn(css.item, active && css.active, className)}>
    <div className={css.item_icon}>
      <img src={icon} alt="icon" />
    </div>
    <div className={css.item_name}>{name}</div>
  </div>
)

SellTabItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool,
}

export default SellTabItem
