import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'
import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'
import avatar3Img from 'images/avatar3.png'
import avatar4Img from 'images/avatar4.png'

export const recommendedCollections = [
  { id: 1, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 2, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 3, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 4, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
  { id: 5, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 6, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 7, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 8, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
]

export const sliderItems = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
  { id: 5, image: nftCard1Img },
  { id: 6, image: nftCard2Img },
  { id: 7, image: nftCard1Img },
  { id: 8, image: nftCard2Img },
  { id: 9, image: nftCard1Img },
  { id: 10, image: nftCard2Img },
]

export const sliderInfoItems = [
  { id: 1, image: avatar3Img, text: 'Moon-Lee' },
  { id: 2, image: avatar4Img, text: 'Alpha' },
  { id: 3, image: avatar3Img, text: 'Moon-Lee' },
  { id: 4, image: avatar4Img, text: 'Alpha' },
  { id: 5, image: avatar3Img, text: 'Moon-Lee' },
  { id: 6, image: avatar4Img, text: 'Alpha' },
]
