import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './LevelInput.module.scss'
// Components
import { Input } from 'UI'
// Images
import closeWhiteIcon from 'icons/close-white.svg'
// Utils
import { minMaxNumValidate } from 'utils'

const LevelInput = ({
  name1,
  name2,
  name3,
  className,
  close,
  max,
  onChange,
}) => {
  const onChangeInput = (e, name) => {
    const value = minMaxNumValidate(0, 999999999, e.target.value)

    onChange(name, value)
  }

  return (
    <div className={cn(css.property, className)}>
      <div className={css.property_close} onClick={close}>
        <img src={closeWhiteIcon} alt="close" />
      </div>
      <Input name={name1} type="text" className={css.property_input} />
      <div className={css.property_inputs}>
        <Input
          name={name2}
          type="number"
          onChange={(e) => onChangeInput(e, name2)}
        />
        <div className={css.property_of}>of</div>
        <Input
          name={name3}
          type="number"
          onChange={(e) => onChangeInput(e, name3)}
        />
      </div>
    </div>
  )
}

LevelInput.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  name3: PropTypes.string.isRequired,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
}

export default LevelInput
