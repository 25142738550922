import { useNavigate } from 'react-router-dom'
import css from './DropdownDots.module.scss'
// Components
import { Tooltip } from 'UI'
import DropdownItem from '../DropdownItem/DropdownItem'
// Data
import { data } from './utils'

const DropdownDots = ({ collectionId }) => {
  const navigate = useNavigate()

  const onClick = (id) => {
    switch (id) {
      case 1:
        navigate(`/collection/${collectionId}/edit`)
        break

      default:
        break
    }
  }

  return (
    <Tooltip
      id="dots"
      place="bottom"
      className={css.dropdown}
      arrowColor="transparent"
      backgroundColor="#07033a"
      globalEventOff="click"
    >
      {data.map((item) => (
        <DropdownItem
          key={item.id}
          {...item}
          onClick={() => onClick(item.id)}
        />
      ))}
    </Tooltip>
  )
}

export default DropdownDots
