import css from './Supply.module.scss'
// Components
import { Input } from 'UI'

const Supply = ({ onChange }) => {
  const onChangeInput = (e) => {
    const value = e.target.value.replace(/\D/g, '')

    onChange('supply', value)
  }

  return (
    <div className={css.form_input}>
      <div className={css.form_input_name}>Supply</div>
      <Input
        name="supply"
        type="text"
        className={css.input}
        classNameInput={css.form_input_wrap}
        onChange={onChangeInput}
      />
    </div>
  )
}

export default Supply
