import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './Dropdown.module.scss'
// Images
import arrowIcon from 'icons/arrow.svg'

const Dropdown = ({ onChange, className, list, activeItem, classNameList }) => (
  <div className={cn(css.dropdown, className)}>
    <div className={css.dropdown_top}>
      <div className={css.dropdown_label}>{activeItem?.text}</div>
      <img src={arrowIcon} alt="arrow" />
    </div>

    {list.length !== 0 && (
      <div className={cn(css.dropdown_list, classNameList)}>
        {list?.map((item) => (
          <div
            className={css.dropdown_list_item}
            key={item.id}
            onClick={() => onChange(item)}
          >
            {item.text}
            {item.icon && (
              <span className={css.dropdown_list_item_icon}>
                <img src={item.icon} alt="l" />
              </span>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
)

Dropdown.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  list: PropTypes.array,
  activeItem: PropTypes.object,
  classNameList: PropTypes.string,
}

export default Dropdown
