import { useRef, useEffect } from 'react'
import css from './QuestionItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const QuestionItem = ({ className, id, name, text, onClick, active }) => {
  const itemRef = useRef()

  useEffect(() => {
    itemRef.current.style.maxHeight = active
      ? `${itemRef.current.scrollHeight}px`
      : '0px'
  }, [itemRef, active])

  return (
    <div className={cn(css.item, active && css.active, className)}>
      <div className={css.item_top} onClick={() => onClick(id)}>
        <div className={css.item_title}>{name}</div>
      </div>
      <div className={css.item_body} ref={itemRef}>
        <div className={css.item_text}>{text}</div>
      </div>
    </div>
  )
}

QuestionItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default QuestionItem
