import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'List item | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="If you want to sell your NFT you need to fill in the data for the listing here."
      />
    </Helmet>
  )
}

export default CustomHelmet
