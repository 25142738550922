import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'NFT-card’s overview | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="See all of the info about your NFT-card with its price, description and the list of recommended fit cards. You can also edit, sell or cancel the listing of your NFT-card here."
      />
    </Helmet>
  )
}

export default CustomHelmet
