import { useLayoutEffect } from 'react'
// import css from './ScrollWrapper.module.scss'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const ScrollWrapper = ({ children }) => {
  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return children
}

ScrollWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollWrapper
