import css from './CreatorEarnings.module.scss'
import cn from 'classnames'
import { FieldArray } from 'formik'
// Components
import { Input, Button } from 'UI'
// Images
import trashIcon from 'icons/trash.svg'

const CreatorEarnings = ({ className, creatorEarnings }) => {
  const initialcreatorEarningObj = { address: '', percent: null }

  return (
    <div className={cn(css.creator, className)}>
      <FieldArray
        name="creatorEarnings"
        render={(arrayHelpers) => (
          <div className={css.creator_wrapper}>
            <div className={css.creator_inputs}>
              {creatorEarnings &&
                creatorEarnings.length > 0 &&
                creatorEarnings.map((_, index) => (
                  <div key={index} className={css.creator_input}>
                    <Input
                      name={`creatorEarnings.${index}.address`}
                      className={css.input}
                      placeholder="Please enter an address, e.g. 0x1ed3... or destination.eth"
                    />
                    <Input
                      name={`creatorEarnings.${index}.percent`}
                      className={css.input}
                      placeholder="0"
                    />
                    {index !== 0 && (
                      <div onClick={() => arrayHelpers.remove(index)}>
                        <img src={trashIcon} alt="icon" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <Button
              variant="blue"
              className={css.creator_add}
              onClick={() => arrayHelpers.push(initialcreatorEarningObj)}
            >
              Add address
            </Button>
          </div>
        )}
      />
    </div>
  )
}

export default CreatorEarnings
