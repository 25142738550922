import { useMemo } from 'react'
import css from './HistoryPurchaseTable.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useSelector } from 'react-redux'
// Components
import { Table } from 'UI'
// Images
import catalog1Img from 'images/catalog1.jpeg'
// Utils
import { tableRows, tableTitles, tabsTable } from './utils'

const HistoryPurchaseTable = () => {
  const data = useSelector((state) => state.nft.history)

  const tableTitlesContent = useMemo(
    () => (
      <div className={css.table_titles}>
        {tableTitles?.map((item) => (
          <div className={css.table_title} key={item.id}>
            {item.text}
          </div>
        ))}
      </div>
    ),
    []
  )

  const tableRowsContent = useMemo(
    () => (
      <div className={css.table_rows}>
        {tableRows?.map((item) => (
          <div className={css.table_row} key={item.id}>
            <div>{item.id}</div>
            <div>
              <img src={catalog1Img} alt="nft" className={css.table_img} />
              {item.name}
            </div>
            <div>{item.race}</div>
            <div>{item.cost}</div>
            <div>{item.seller}</div>
            <div>{item.date}</div>
          </div>
        ))}
      </div>
    ),
    []
  )

  return (
    <Tabs className={css.table_tabs}>
      <TabList className={css.table_tabs_tablist}>
        <div className="table-tabs">
          {tabsTable?.map((item) => (
            <Tab
              key={item.id}
              selectedClassName="table-tab-active"
              className="table-tab"
            >
              <div className="table-tab-item-active">{item.text}</div>
            </Tab>
          ))}
        </div>
      </TabList>
      <TabPanel>
        <Table
          titles={tableTitlesContent}
          rows={tableRowsContent}
          className={css.table}
        />
      </TabPanel>
      <TabPanel>
        <Table
          titles={tableTitlesContent}
          rows={tableRowsContent}
          className={css.table}
        />
      </TabPanel>
    </Tabs>
  )
}

export default HistoryPurchaseTable
