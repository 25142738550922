import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import css from './LoadingScreen.module.scss'
import { CSSTransition } from 'react-transition-group'
// Images
import logoImg from 'images/logo.svg'

const progressPoints = [
  {
    progress: 65,
    ms: 250,
  },
  {
    progress: 85,
    ms: 1000,
  },
  {
    progress: 95,
    ms: 1700,
  },
  {
    progress: 100,
    ms: 2000,
  },
]

const LoadingScreen = () => {
  const isLoadingScreen = useSelector((state) => state.global.isLoadingScreen)
  const [progressLoading, setProgressLoading] = useState(30)

  useEffect(() => {
    if (isLoadingScreen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isLoadingScreen])

  useEffect(() => {
    progressPoints.forEach(({ progress, ms }) => {
      setTimeout(() => setProgressLoading(progress), ms)
    })
  }, [])

  return (
    <CSSTransition
      unmountOnExit
      in={isLoadingScreen}
      timeout={100}
      classNames={{
        enter: css.showEnter,
        enterActive: css.showEnterActive,
        exit: css.showExit,
        exitActive: css.showExitActive,
      }}
    >
      <div className={css.loading}>
        <div
          className={css.loading_progress}
          style={{ width: `${progressLoading}%` }}
        />
        <div className={css.loading_body}>
          <img src={logoImg} alt="logo" className={css.loading_img} />
        </div>
      </div>
    </CSSTransition>
  )
}

export default LoadingScreen
