import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'DeckAAA | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Over here you can build the most suitable deck for your play style and maximize the chemistry between your cards!"
      />
    </Helmet>
  )
}

export default CustomHelmet
