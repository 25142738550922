import css from './FilterPanel.module.scss'
import cn from 'classnames'
import { useState } from 'react'
// Images
import arrowIcon from 'icons/arrow.svg'

const FilterPanel = ({ className, name, children }) => {
  const [isShow, setIsShow] = useState(false)

  const changeShow = () => setIsShow(!isShow)

  return (
    <div className={cn(css.panel, isShow && css.active, className)}>
      <div className={css.panel_top} onClick={changeShow}>
        <div className={css.panel_name}>{name}</div>
        <img src={arrowIcon} alt="arrow" />
      </div>
      <div className={cn(css.panel_content, isShow && css.active)}>
        {children}
      </div>
    </div>
  )
}

export default FilterPanel
