import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './ArrowRight.module.scss'
// Icons
import arrowOutlineIcon from 'icons/arrow-outline.svg'

const ArrowRight = ({ onClick, className }) => (
  <button className={cn(css.button, className)} onClick={onClick}>
    <img src={arrowOutlineIcon} alt="arrow" />
  </button>
)

ArrowRight.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default ArrowRight
