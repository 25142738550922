import css from './CheckMoveCollectionModal.module.scss'
import PropTypes from 'prop-types'
// Components
import { Popup, Button } from 'UI'

const CheckMoveCollectionModal = ({
  open,
  closeModal,
  openModal,
  collection,
}) => {
  return (
    <Popup
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title={'Move to collection ' + collection}
      description="Are you sure you want to move the selected NFTs to another collection?"
    >
      <div className={css.btns}>
        <Button variant="blue" onClick={closeModal}>
          Go back
        </Button>
        <Button>Move</Button>
      </div>
    </Popup>
  )
}

CheckMoveCollectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default CheckMoveCollectionModal
