import css from './PaymentTokens.module.scss'
import cn from 'classnames'
import { FieldArray } from 'formik'
import { useEffect, useState } from 'react'
// Components
import { Select } from 'UI'
import BlockchainItem from 'components/Items/BlockchainItem'
// Utils
import { paymentTokensOptions } from './utils'

const PaymentTokens = ({ className, paymentTokens, isEdit }) => {
  const [options, setOptions] = useState(paymentTokensOptions)

  const customOnChange = (option, helper) => {
    option.name = option.label
    option.currency = option.description
    helper.push(option)

    const resultOptions = []

    options.forEach((item) => {
      if (item.name !== option.name) {
        resultOptions.push(item)
      }
    })

    setOptions(resultOptions)
  }

  const removeItem = (index, item, helper) => {
    helper.remove(index)
    const resultItem = paymentTokensOptions.find(
      (option) => option.label === item.name
    )
    setOptions([resultItem, ...options])
  }

  useEffect(() => {
    if (isEdit) {
      const paymentTokensFiltered = []

      paymentTokensOptions.forEach((item, index) => {
        const paymentToken = paymentTokens.find(
          (token) => token?.name === item.label
        )
        if (!paymentToken) {
          paymentTokensFiltered.push(item)
        }
      })

      setOptions(paymentTokensFiltered)
    }
  }, [paymentTokens])

  return (
    <div className={cn(css.payment, className)}>
      <FieldArray
        name="paymentTokens"
        render={(arrayHelpers) => (
          <div className={css.payment_wrapper}>
            <div className={css.payment_items}>
              {paymentTokens.map((item, index) => (
                <BlockchainItem
                  {...item}
                  key={index}
                  className={css.payment_item}
                  close={() => removeItem(index, item, arrayHelpers)}
                />
              ))}
            </div>
            <Select
              options={options}
              placeholder="Add token"
              name="gender"
              customOnChange={(option) => customOnChange(option, arrayHelpers)}
              className={css.payment_select}
              value=" "
            />
          </div>
        )}
      />
    </div>
  )
}

export default PaymentTokens
