import css from './DropdownShare.module.scss'
// Components
import { Tooltip } from 'UI'
import DropdownItem from '../DropdownItem/DropdownItem'
// Data
import { data } from './utils'

const DropdownShare = () => {
  return (
    <Tooltip
      id="share"
      place="bottom"
      className={css.dropdown}
      arrowColor="transparent"
      backgroundColor="#07033a"
      globalEventOff="click"
    >
      {data.map((item) => (
        <DropdownItem key={item.id} {...item} />
      ))}
    </Tooltip>
  )
}

export default DropdownShare
