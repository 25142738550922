import { useCallback, useEffect, useState } from 'react'
import css from './Header.module.scss'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
// Images
import botIcon from 'icons/wallets/bot-small.svg'
// Components
import Sidebar from 'components/Sidebar'
import { Backdrop } from 'UI'
import HeaderConnect from 'components/HeaderConnect'

const Header = () => {
  const { pathname } = useLocation()
  const [activeMobileMenu, setActiveMobileMenu] = useState(false)

  const changeActiveMobileMenu = useCallback(() => {
    setActiveMobileMenu(!activeMobileMenu)
  }, [activeMobileMenu])

  useEffect(() => {
    setActiveMobileMenu(false)
  }, [pathname])

  return (
    <>
      <header className={cn(css.header, activeMobileMenu && css.active)}>
        <div
          className={cn(css.header_burger, activeMobileMenu && css.active)}
          onClick={changeActiveMobileMenu}
        >
          <i />
        </div>
        <div className={css.header_logo}>
          <img src={botIcon} alt="logo" />
        </div>
      </header>
      <HeaderConnect />
      <Sidebar active={activeMobileMenu} />
      <Backdrop
        active={activeMobileMenu}
        onClick={changeActiveMobileMenu}
        className={css.header_backdrop}
      />
    </>
  )
}

export default Header
