import { createSlice } from '@reduxjs/toolkit'

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    collections: [],
    nfts: [],
    isLoadingFetch: false,
  },
  reducers: {
    nftsFetch: (state) => {
      return { ...state, isLoadingFetch: true }
    },
    collectionsFetch: (state) => {
      return { ...state, isLoadingFetch: true }
    },
    collectionsFetchSuccess: (state, action) => {
      return {
        ...state,
        collections: action.payload.data,
        isLoadingFetch: false,
      }
    },
    nftsFetchSuccess: (state, action) => {
      return {
        ...state,
        nfts: action.payload.data,
        isLoadingFetch: false,
      }
    },
    nftFetchError: (state, action) => {
      return {
        ...state,
        isLoadingFetch: false,
      }
    },
  },
})

export const {
  collectionsFetch,
  nftsFetch,
  collectionsFetchSuccess,
  nftsFetchSuccess,
  nftFetchError,
} = catalogSlice.actions

export default catalogSlice.reducer
