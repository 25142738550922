import { useEffect } from 'react'
import FsLightbox from 'fslightbox-react'
// import css from './App.module.scss'
import { useDispatch, useSelector } from 'react-redux'
// Components
import Snackbar from 'components/Snackbar'
// Router
import Router from 'router'
// Utils
import { getCookie } from 'utils/cookie'
// Store
import { checkLoginHandler } from 'store/auth'

const App = () => {
  const dispatch = useDispatch()
  const isPopupImage = useSelector((state) => state.global.isPopupImage)
  const popupImages = useSelector((state) => state.global.popupImages)

  useEffect(() => {
    const token = getCookie('token')

    if (token) {
      dispatch(checkLoginHandler())
    }
  }, [dispatch])

  return (
    <>
      <Router />
      <Snackbar />
      <FsLightbox toggler={isPopupImage} sources={popupImages} type="image" />
    </>
  )
}

export default App
