import css from './EditCollectionPage.module.scss'
import cn from 'classnames'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Components
import CreateNftCollectionForm from 'components/Forms/CreateNftCollectionForm'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import duckImg from 'images/duck.png'
// Helmet
import CustomHelmet from './Helmet'
// Store
import { selectCollection, getCollectionHandler } from 'store/collection'

const EditCollectionPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const collection = useSelector(selectCollection)

  useEffect(() => {
    dispatch(getCollectionHandler(id))
  }, [dispatch, id])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.wrapper}>
          <div className={css.content}>
            <div className={css.avatar_wrap}>
              <div className={cn(css.avatar, css.avatar_left)}>
                <img src={duckImg} alt="duck1" />
              </div>
              <div className={css.avatar}>
                <img src={duckImg} alt="duck2" />
              </div>
              <div className={cn(css.avatar, css.avatar_right)}>
                <img src={duckImg} alt="duck3" />
              </div>
            </div>
            <Title className={css.title} type="h2">
              Edit My Collection
            </Title>
            <CreateNftCollectionForm data={collection} typeForm="EDIT" />
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default EditCollectionPage
