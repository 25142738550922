import css from './DropdownItem.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'

const DropdownItem = ({ name, icon, onClick, className }) => {
  return (
    <div className={cn(css.item, className)} onClick={onClick}>
      <img src={icon} alt={name} />
      <span>{name}</span>
    </div>
  )
}

DropdownItem.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default DropdownItem
