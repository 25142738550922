import { takeEvery, call, put, select } from 'redux-saga/effects'
import { snackbarOpen } from '../snackbar'
import {
  createCollectionRequest,
  getCollectionByIdRequest,
  removeCollectionRequest,
  editCollectionRequest,
  getCollectionByUrlRequest,
} from 'api/collection'
import {
  createCollectionHandler,
  editCollectionHandler,
  getCollectionHandler,
  getCollectionSuccess,
  modalCreateCollectionHandler,
  modalRemoveCollectionHandler,
  removeCollectionHandler,
} from './collection'
import { history } from 'constants/history'
import { isValidIdMongoose } from 'utils/mongoose'

function* workCreateCollection({ payload }) {
  try {
    const { data } = yield call(createCollectionRequest, payload)
    yield put(getCollectionSuccess(data.data))
    yield put(modalCreateCollectionHandler(true))
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

function* workGetCollection({ payload }) {
  try {
    const { data } = yield call(
      isValidIdMongoose(payload)
        ? getCollectionByIdRequest
        : getCollectionByUrlRequest,
      payload
    )

    yield put(getCollectionSuccess(data.data))
  } catch (e) {
    history.push('/404')
  }
}

function* workRemoveCollection() {
  try {
    const collection = yield select((state) => state.collection.collection)
    const { data } = yield call(removeCollectionRequest, collection._id)
    yield put(modalRemoveCollectionHandler(false))
    history.push('/profile')
    yield put(snackbarOpen({ message: data.data, isError: false }))
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

function* workEditCollection({ payload }) {
  try {
    const { data } = yield call(editCollectionRequest, payload)
    yield put(getCollectionSuccess(data.data))
    history.push('/collection/' + data.data._id)
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

export default function* collectionSagaWatcher() {
  yield takeEvery(createCollectionHandler.type, workCreateCollection)
  yield takeEvery(getCollectionHandler.type, workGetCollection)
  yield takeEvery(removeCollectionHandler.type, workRemoveCollection)
  yield takeEvery(editCollectionHandler.type, workEditCollection)
}
