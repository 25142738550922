import css from './SliderInfoItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const SliderInfoItem = ({ className, text, image }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_img}>
      <img src={image} alt={text} />
    </div>
    <div className={css.item_text}>{text}</div>
  </div>
)

SliderInfoItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.node,
}

export default SliderInfoItem
