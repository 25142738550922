import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import css from './CatalogCollections.module.scss'
// Components
import NftCardItem from 'components/Items/NftCardItem'
import { Spinner, Button } from 'UI'
// Store
import { collectionsFetch } from 'store/catalog'
import { Link } from 'react-router-dom'

const CatalogCollections = () => {
  const dispatch = useDispatch()
  const collections = useSelector((state) => state.catalog.collections)
  const isLoading = useSelector((state) => state.catalog.isLoadingFetch)
  const [loadMore, setLoadMore] = useState(false)

  useEffect(() => {
    dispatch(collectionsFetch())
  }, [dispatch])

  return (
    <div className={css.wrap}>
      <div className={css.items}>
        {collections?.map((item, index) => (
          <Link to={`/collection/${item.url ? item.url : item._id}`}>
            <NftCardItem
              key={index}
              {...item}
              image={process.env.REACT_APP_IMAGE_URL + item.logoImage}
            />
          </Link>
        ))}
      </div>
      {isLoading && <Spinner />}
      {/* {!isLoading && (
        <Button onClick={() => setLoadMore(!loadMore)}>Load More</Button>
      )} */}
    </div>
  )
}

export default CatalogCollections
