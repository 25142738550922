import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  collection: {},
  isShowModalRemoveCollection: false,
  isShowModalCreateCollection: false,
}

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    createCollectionHandler: () => {},
    editCollectionHandler: () => {},
    removeCollectionHandler: () => {},
    getCollectionHandler: () => {},
    getCollectionSuccess: (state, action) => {
      return { ...state, collection: action.payload }
    },
    modalRemoveCollectionHandler: (state, action) => {
      return { ...state, isShowModalRemoveCollection: action.payload }
    },
    modalCreateCollectionHandler: (state, action) => {
      return { ...state, isShowModalCreateCollection: action.payload }
    },
  },
})

export const {
  createCollectionHandler,
  editCollectionHandler,
  removeCollectionHandler,
  getCollectionHandler,
  getCollectionSuccess,
  modalRemoveCollectionHandler,
  modalCreateCollectionHandler,
} = collectionSlice.actions

export default collectionSlice.reducer
