import cn from 'classnames'
import PropTypes from 'prop-types'
import { Collapse as CollapseCustom } from 'react-collapse'
import css from './Collapse.module.scss'
// Hooks
import useModal from 'hooks/useModal'
// Images
import arrowIcon from 'icons/arrow.svg'

const Collapse = ({ children, title, className, icon }) => {
  const [collapseIsOpen, collapseOpen, collapseClose] = useModal()

  return (
    <div className={className}>
      <div
        className={cn(css.collapse_top, collapseIsOpen && css.active)}
        onClick={!collapseIsOpen ? collapseOpen : collapseClose}
      >
        <div className={css.collapse_name}>
          <img src={icon} alt="icon" />
          {title}
        </div>
        <div className={cn(css.collapse_arrow, collapseIsOpen && css.active)}>
          <img src={arrowIcon} alt="icon" />
        </div>
      </div>
      <CollapseCustom isOpened={collapseIsOpen}>
        <div className={css.collapse_content}>{children}</div>
      </CollapseCustom>
    </div>
  )
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
}

export default Collapse
