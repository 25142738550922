import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Collection | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Here you can see all of the info about this collection and see what items are in there!"
      />
    </Helmet>
  )
}

export default CustomHelmet
