import { useState } from 'react'
import css from './CatalogPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import cn from 'classnames'
// Components
import MainTabItem from 'components/Items/MainTabItem'
import CatalogNfts from 'components/CatalogNfts'
import CatalogCollections from 'components/CatalogCollections'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Utils
import { tabsCatalog } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const CatalogPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.section_top}>
          <div>
            <Title className={css.h1}>Catalog</Title>
            <Tabs className={css.tabs} onSelect={setTabIndex}>
              <TabList className={css.tabs_tablist}>
                <div className={cn(css.tabs_tablist_items, 'main-tabs')}>
                  {tabsCatalog?.map((item) => (
                    <Tab
                      key={item.id}
                      selectedClassName="main-tab-active"
                      className="main-tab"
                    >
                      <MainTabItem {...item} className="main-tab-item-active" />
                    </Tab>
                  ))}
                </div>
              </TabList>
              <TabPanel>{tabIndex === 0 && <CatalogNfts />}</TabPanel>
              <TabPanel>{tabIndex === 1 && <CatalogCollections />}</TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default CatalogPage
