import css from './ExistingTabItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const ExistingTabItem = ({
  className,
  text,
  onClick,
  id,
  image,
  active,
  classNameImg,
}) => (
  <div
    className={cn(css.item, className)}
    onClick={onClick ? () => onClick(id) : null}
  >
    <div className={cn(css.item_img, active && css.active, classNameImg)}>
      <img src={image} alt={text} />
    </div>
    <div className={css.item_text}>{text}</div>
  </div>
)

ExistingTabItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.number,
  image: PropTypes.node,
  active: PropTypes.bool,
  classNameImg: PropTypes.string,
}

export default ExistingTabItem
