import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import css from './CatalogNfts.module.scss'
import { Formik } from 'formik'
// Components
import { Spinner, Input } from 'UI'
import CatalogItem from 'components/Items/CatalogItem'
// Store
import { nftsFetch } from 'store/catalog'
// Images
import searchIcon from 'icons/search.svg'
// Utils
import { search } from 'utils'

const CatalogNfts = () => {
  const dispatch = useDispatch()
  const nfts = useSelector((state) => state.catalog.nfts)
  const isLoading = useSelector((state) => state.catalog.isLoadingFetch)
  const [loadMore, setLoadMore] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(nftsFetch())
  }, [loadMore])

  useEffect(() => {
    if (nfts.lenght !== 0) {
      setData(nfts)
    }
  }, [nfts])

  const submitFilter = useCallback(
    (values) => {
      const filteredData = search(nfts, values.search)
      setData(filteredData)
    },
    [nfts]
  )

  return (
    <>
      <Formik
        initialValues={{ search: '' }}
        onSubmit={(values) => submitFilter(values)}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <Input
              name="search"
              type="text"
              placeholder="Search nft"
              className={css.input}
              leftIcon={<img src={searchIcon} alt="search" />}
            />
          </form>
        )}
      </Formik>
      <div className={css.wrap}>
        <div className={css.items}>
          {data?.map((item, index) => (
            <CatalogItem
              {...item}
              key={index}
              image={process.env.REACT_APP_IMAGE_URL + item.file}
              name={item.name}
              category={item.blockchain}
              href={`/nft/` + item._id}
            />
          ))}
        </div>
        {isLoading && <Spinner />}
        {/* {!isLoading && (
          <Button onClick={() => setLoadMore(!loadMore)}>Load More</Button>
        )} */}
      </div>
    </>
  )
}

export default CatalogNfts
