import { Routes, Route, Outlet } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// Layout
import Layout from 'components/Layout'
import AuthLayout from 'components/AuthLayout'
// Pages
import MainPage from 'pages/MainPage'
import CatalogPage from 'pages/CatalogPage'
import NftCardPage from 'pages/NftCardPage'
import NftItemPage from 'pages/NftItemPage'
import CollectionPage from 'pages/CollectionPage'
import DeckPage from 'pages/DeckPage'
import PersonalCabinetPage from 'pages/PersonalCabinetPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage'
import FaqPage from 'pages/FaqPage'
import ExistingCardsPage from 'pages/ExistingCardsPage'
import GameRulesPage from 'pages/GameRulesPage'
import HowPlayPage from 'pages/HowPlayPage'
import BestPlayersPage from 'pages/BestPlayersPage'
import MintNftCollectionPage from 'pages/MintNftCollectionPage'
import SignUpPage from 'pages/SignUpPage'
import SignInPage from 'pages/SignInPage'
import Custom404Page from 'pages/404Page'
import CreateNftPage from 'pages/CreateNftPage'
import CreateCollectionPage from 'pages/CreateCollectionPage'
import CollectionDetailPage from 'pages/CollectionDetailPage'
import EditCollectionPage from 'pages/EditCollectionPage'
import NftEditCardPage from 'pages/NftEditCardPage'
import NftSellCardPage from 'pages/NftSellCardPage'
import ProfileAuctionDetailPage from 'pages/ProfileAuctionDetailPage'
import UserPage from 'pages/UserPage'

const Router = () => {
  // const isAuth = useSelector((state) => state.auth.isAuth)

  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route path="/" element={<MainPage />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/nft/:id" element={<NftCardPage />} />
        <Route path="/nft/:id/edit" element={<NftEditCardPage />} />
        <Route path="/nft/:id/sale" element={<NftSellCardPage />} />
        <Route path="/catalog-card-item/:id" element={<NftItemPage />} />
        <Route path="/collection" element={<CollectionPage />} />
        <Route path="/deck" element={<DeckPage />} />
        <Route path="/nft/create" element={<CreateNftPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/existing-cards" element={<ExistingCardsPage />} />
        <Route path="/games/rules" element={<GameRulesPage />} />
        <Route path="/games/how-to-play" element={<HowPlayPage />} />
        <Route path="/games/best-players" element={<BestPlayersPage />} />
        <Route
          path="/mint-nft-collection"
          element={<MintNftCollectionPage />}
        />
        <Route path="/collection/create" element={<CreateCollectionPage />} />
        <Route path="/collection/:id" element={<CollectionDetailPage />} />
        <Route path="/collection/:id/edit" element={<EditCollectionPage />} />
        <Route path="*" element={<Custom404Page />} />
        <Route path="/profile" element={<PersonalCabinetPage />} />
        <Route
          path="/profile/auction/:id"
          element={<ProfileAuctionDetailPage />}
        />
        <Route path="/user/:id" element={<UserPage />} />
      </Route>
      <Route
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        <Route path="/auth/sign-in" element={<SignInPage />} />
        <Route path="/auth/sign-up" element={<SignUpPage />} />
      </Route>
    </Routes>
  )
}

export default Router
