import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Collection edit | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Visit this page any time you want to make some changes to your collection: our user friendly interface will help you to edit you collection quickly."
      />
    </Helmet>
  )
}

export default CustomHelmet
