import { $apiWithToken } from '.'

export const getUserRequest = (id) => {
  return $apiWithToken.get('/users/' + id)
}

export const editUserNicknameRequest = ({ id, nickname }) => {
  return $apiWithToken.post('/users/edit-nickname', {
    id,
    nickname,
  })
}

export const editUserEmailRequest = ({ id, email }) => {
  return $apiWithToken.post('/users/edit-email', {
    id,
    email,
  })
}

export const editUserPasswordRequest = ({ id, oldPassword, newPassword }) => {
  return $apiWithToken.post('/users/edit-password', {
    id,
    oldPassword,
    newPassword,
  })
}
