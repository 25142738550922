import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Privacy policy | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Privacy is important for us. Please, make sure to learn our privacy policy below."
      />
    </Helmet>
  )
}

export default CustomHelmet
