import { $api } from '.'

export const getAllCollectionsRequest = () => {
  return $api.get('/collection')
}

export const createCollectionRequest = (payload) => {
  const formData = new FormData()
  console.log(payload)
  formData.append('logoImage', payload.logoImage)
  formData.append('name', payload.name)
  formData.append('featuredImage', payload.featuredImage)
  formData.append('bannerImage', payload.bannerImage)
  formData.append('url', payload.url)
  formData.append('description', payload.description)

  if (payload.category) {
    formData.append('category', payload.category)
  }

  formData.append('links', payload.links)
  formData.append('creatorEarnings', JSON.stringify(payload.creatorEarnings))
  formData.append('blockchain', 'ethereum')
  formData.append('paymentTokens', payload.paymentTokens)
  formData.append(
    'explicitAndSensitiveContent',
    payload.explicitAndSensitiveContent
  )

  return $api.post('/collection', formData, {
    headers: {
      'Content-Type': 'multipart/form-data; ',
    },
  })
}

export const editCollectionRequest = (payload) => {
  const formData = new FormData()

  if (payload.logoImage instanceof File) {
    formData.append('logoImage', payload.logoImage)
  }
  if (payload.featuredImage instanceof File) {
    formData.append('featuredImage', payload.featuredImage)
  }
  if (payload.bannerImage instanceof File) {
    formData.append('bannerImage', payload.bannerImage)
  }

  formData.append('name', payload.name)
  formData.append('url', payload.url)
  formData.append('description', payload.description)

  if (payload.category) {
    formData.append('category', payload.category)
  }

  formData.append('links', payload.links)
  formData.append('creatorEarnings', JSON.stringify(payload.creatorEarnings))
  formData.append('blockchain', 'ethereum')
  formData.append('paymentTokens', payload.paymentTokens)
  formData.append(
    'explicitAndSensitiveContent',
    payload.explicitAndSensitiveContent
  )

  return $api.put('/collection/' + payload.id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data; ',
    },
  })
}

export const removeCollectionRequest = (id) => {
  return $api.delete('/collection/' + id)
}

export const getCollectionByIdRequest = (id) => {
  return $api.get('/collection/' + id)
}

export const getCollectionByUrlRequest = (url) => {
  return $api.get('/collection/by-url/' + url)
}
