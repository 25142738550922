import { useDispatch, useSelector } from 'react-redux'
import css from './CollectionDetail.module.scss'
import { ReactSVG } from 'react-svg'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
// Store
import { changePopupImage } from 'store/global'
// Images
import starIcon from 'icons/star.svg'
import shareIcon from 'icons/share.svg'
import dotsIcon from 'icons/dots.svg'
// Components
import DropdownDots from './DropdownDots/DropdownDots'
import DropdownShare from './DropdownShare/DropdownShare'
import Numbers from './Numbers/Numbers'
import Items from './Items/Items'
import { Title } from 'UI'
import Activity from './Activity/Activity'
// Store
import { getCollectionHandler, selectCollection } from 'store/collection'

const CollectionDetail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const collection = useSelector(selectCollection)

  const detailPopupImage = useCallback(() => {
    dispatch(
      changePopupImage([process.env.REACT_APP_IMAGE_URL + collection.logoImage])
    )
  }, [dispatch, collection.logoImage])

  useEffect(() => {
    dispatch(getCollectionHandler(id))
  }, [id, dispatch])

  return (
    <div className={css.wrap}>
      <div className={css.banner}>
        {collection.bannerImage ? (
          <img
            src={process.env.REACT_APP_IMAGE_URL + collection.bannerImage}
            alt="banner"
            className={css.banner_img}
          />
        ) : (
          <div className={css.banner_bg}></div>
        )}

        <div className={css.detail} onClick={detailPopupImage}>
          <img
            src={process.env.REACT_APP_IMAGE_URL + collection.logoImage}
            alt="detail"
            className={css.detail_img}
          />
        </div>
      </div>
      <div className={css.info}>
        <Title className={css.title} type="h3">
          {collection.name}
        </Title>
        <div className={css.actions}>
          <div className={css.actions_item}>
            <ReactSVG src={starIcon} />
          </div>
          <a
            className={css.actions_item}
            data-tip
            data-for="share"
            data-event="click"
          >
            <ReactSVG src={shareIcon} />
          </a>
          <a
            className={css.actions_item}
            data-tip
            data-for="dots"
            data-event="click"
          >
            <ReactSVG src={dotsIcon} />
          </a>
        </div>
      </div>
      <Numbers />
      <Tabs className={css.tabs}>
        <TabList className={css.tabs_tablist}>
          <Tab className={css.tabs_tab}>Items</Tab>
          <Tab className={css.tabs_tab}>Activity</Tab>
        </TabList>
        <TabPanel>
          <Items />
        </TabPanel>
        <TabPanel>
          <Activity />
        </TabPanel>
      </Tabs>
      <DropdownDots collectionId={collection._id} />
      <DropdownShare />
    </div>
  )
}

export default CollectionDetail
