import css from './NftItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const NftItem = ({ className, image, level }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_img}>
      <img src={image} alt="img" />
    </div>
    <div>
      <div className={css.item_level}>{level}</div>
    </div>
  </div>
)

NftItem.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  level: PropTypes.string,
}

export default NftItem
