import css from './FundsPurchase.module.scss'
import { Formik } from 'formik'
// Components
import { Button, Tooltip, Input } from 'UI'
// import TooltipQuestion from 'components/TooltipQuestion/TooltipQuestion'
// Images
import arrowIcon from 'icons/arrow.svg'

const FundsPurchase = ({ changeStep }) => {
  const onSubmit = (values) => {
    // changeStep()
  }

  return (
    <div>
      <div className={css.back} onClick={() => changeStep(2)}>
        <img src={arrowIcon} alt="arrow" />
      </div>
      <div className={css.title}>
        You need 5 USDC + <span>gas fees</span>
      </div>
      <div className={css.description}>
        Transfer funds to your wallet or add funds with a card. It can take up
        to a minute for your balance to update.
      </div>
      <Formik initialValues={{ wallet: '' }} onSubmit={onSubmit}>
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <Input
              label="Your USDC wallet:"
              name="wallet"
              type="text"
              className={css.input}
            />
            <Button className={css.btn} type="submit">
              Continue
            </Button>
          </form>
        )}
      </Formik>
      <Tooltip id="copy">Copy</Tooltip>
    </div>
  )
}

export default FundsPurchase
