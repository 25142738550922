import { createSlice } from '@reduxjs/toolkit'
import { removeCookie } from 'utils/cookie'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuth: false,
    errorsReg: {},
    errorsLogin: {},
    profile: {},
    errorsEditNickname: {},
    errorsEditEmail: {},
    errorsEditPassword: {},
  },
  reducers: {
    loginHandler: (state) => {},
    registerHandler: (state) => {},
    loginHandlerMetamask: (state) => {},
    loginHandlerPhantom: (state) => {},
    checkLoginHandler: (state) => {},
    editUserNicknameHandler: (state) => {},
    editUserEmailHandler: (state) => {},
    editUserPasswordHandler: (state) => {},
    loginSuccess: (state, action) => {
      return { ...state, isAuth: true, profile: action.payload }
    },
    loginError: (state, action) => {
      return { ...state, errorsLogin: action.payload }
    },

    registerError: (state, action) => {
      return { ...state, errorsReg: action.payload }
    },
    logOut: (state) => {
      removeCookie('token')
      removeCookie('userId')

      return { ...state, isAuth: false, profile: {} }
    },
    editErrorNickname: (state, action) => {
      return { ...state, errorsEditNickname: action.payload }
    },
    editErrorEmail: (state, action) => {
      return { ...state, errorsEditEmail: action.payload }
    },
    editErrorPassword: (state, action) => {
      return { ...state, errorsEditPassword: action.payload }
    },
  },
})

export const {
  loginHandler,
  loginSuccess,
  registerHandler,
  registerError,
  loginError,
  loginHandlerMetamask,
  loginHandlerPhantom,
  checkLoginHandler,
  logOut,
  editUserNicknameHandler,
  editUserEmailHandler,
  editErrorNickname,
  editErrorEmail,
  editErrorPassword,
  editUserPasswordHandler,
} = authSlice.actions

export default authSlice.reducer
