import css from './CostItem.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
// Components
import { Button } from 'UI'
import { BuyNftModal, MakeOfferModal } from 'components/Popups'
// Hooks
import useModal from 'hooks/useModal'

const CostItem = ({ price, className }) => {
  const [makeOfferModal, makeOfferModalOpen, makeOfferModalClose] = useModal()
  const [buyModal, buyModalOpen, buyModalClose] = useModal()

  return (
    <div className={cn(css.cost, className)}>
      <div className={css.cost_left}>
        <div className={css.cost_subtitle}>COST</div>
        <div className={css.cost_num}>{price}</div>
      </div>
      <div className={css.cost_btns}>
        <Button className={css.cost_btn} onClick={buyModalOpen}>
          Buy
        </Button>
        <Button
          className={css.cost_btn}
          variant="blue"
          onClick={makeOfferModalOpen}
        >
          Make offer
        </Button>
      </div>
      <BuyNftModal
        open={buyModal}
        closeModal={buyModalClose}
        openModal={buyModalOpen}
      />
      <MakeOfferModal
        open={makeOfferModal}
        closeModal={makeOfferModalClose}
        openModal={makeOfferModalOpen}
      />
    </div>
  )
}

CostItem.propTypes = {
  price: PropTypes.string,
  className: PropTypes.string,
}

export default CostItem
