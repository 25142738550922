import { useCallback, useEffect, useMemo, useState } from 'react'
import css from './ProfileMyNfts.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
// Components
import CatalogItem from 'components/Items/CatalogItem'
import { Button, Input, Select } from 'UI'
import TabItem from 'components/Items/TabItem'
import {
  CheckMoveCollectionModal,
  CheckRemoveCollectionModal,
} from 'components/Popups'
// Images
import searchIcon from 'icons/search.svg'
// Utils
import { auctionNfts, tabsNftCards, categories } from './utils'
// Hooks
import useModal from 'hooks/useModal'

const ProfileMyNfts = () => {
  const navigate = useNavigate()
  const [dataNfts, setDataNfts] = useState(auctionNfts)
  const [
    isMoveCollectionModal,
    isMoveCollectionModalOpen,
    isMoveCollectionModalClose,
  ] = useModal()
  const [
    isRemoveCollectionModal,
    isRemoveCollectionModalOpen,
    isRemoveCollectionModalClose,
  ] = useModal()
  const [
    checkMoveCollectionModal,
    checkMoveCollectionModalOpen,
    checkMoveCollectionModalClose,
  ] = useModal()
  const [
    checkRemoveCollectionModal,
    checkRemoveCollectionModalOpen,
    checkRemoveCollectionModalClose,
  ] = useModal()
  const [currentCollection, setCurrentCollection] = useState(null)

  const changeCollection = (item) => {
    setCurrentCollection(item)
    checkMoveCollectionModalOpen()
  }

  const submitSearch = useCallback((values) => {
    console.log(values)
  }, [])

  useEffect(() => {
    if (isMoveCollectionModal || isRemoveCollectionModal) {
      setCurrentCollection(null)
    } else {
      const copyArr = [...auctionNfts]
      copyArr.forEach((item) => {
        item.active = false
      })
      setDataNfts(copyArr)
    }
  }, [isMoveCollectionModal, isRemoveCollectionModal])

  const nftHandler = (id) => {
    if (isMoveCollectionModal || isRemoveCollectionModal) {
      const copyArr = [...auctionNfts]

      copyArr.forEach((item) => {
        if (item.id === id) {
          if (item.active) {
            item.active = false
          } else {
            item.active = true
          }
        }
      })

      setDataNfts(copyArr)
    } else {
      navigate('/nft/' + id)
    }
  }

  const myNfstContent = useMemo(
    () => (
      <div className={css.items}>
        {dataNfts?.map((item) => (
          <CatalogItem {...item} key={item.id} onClick={nftHandler} />
        ))}
      </div>
    ),
    [dataNfts, nftHandler]
  )

  return (
    <div className={css.mynfts}>
      <Tabs className={css.tabs}>
        <TabList className={css.tabs_tablist}>
          <div className={css.tabs_tablist_wrap}>
            {tabsNftCards?.map((item) => (
              <Tab key={item.id} selectedClassName="tab-active" className="tab">
                <TabItem {...item} className="tab-item-active" />
              </Tab>
            ))}
          </div>
        </TabList>
        <Formik
          initialValues={{ search: '', collection: '' }}
          onSubmit={(values) => submitSearch(values)}
        >
          {(props) => (
            <form className={css.tabs_form} onSubmit={props.handleSubmit}>
              <Input
                name="search"
                type="text"
                placeholder="Search nft"
                classNameInput={css.tabs_input}
                leftIcon={<img src={searchIcon} alt="search" />}
                className={css.input}
              />
              <Select
                options={categories}
                placeholder="Collection"
                name="collection"
                onChange={props.setFieldValue}
                classNameInput={css.tabs_input}
                className={css.input}
              />
            </form>
          )}
        </Formik>
        <div className={css.btns}>
          {!isMoveCollectionModal && !isRemoveCollectionModal && (
            <Button onClick={isRemoveCollectionModalOpen}>
              Remove from collection
            </Button>
          )}
          {isRemoveCollectionModal && (
            <div className={css.cancel}>
              <div className={css.cancel_text}>
                Select nft to remove from collection
              </div>
              <Button
                onClick={checkRemoveCollectionModalOpen}
                className={css.cancel_remove}
              >
                Remove
              </Button>
              <Button onClick={isRemoveCollectionModalClose} variant="blue">
                Cancel
              </Button>
            </div>
          )}
          {!isRemoveCollectionModal && !isMoveCollectionModal && (
            <Button onClick={isMoveCollectionModalOpen}>
              Move to collection
            </Button>
          )}
          {isMoveCollectionModal && (
            <div className={css.cancel}>
              <div className={css.cancel_text}>
                Select nft to move to collection
              </div>
              <Formik initialValues={{ collection: '' }}>
                {(props) => (
                  <Select
                    options={categories}
                    placeholder=""
                    name="collection"
                    customOnChange={(option) => changeCollection(option)}
                    className={css.select}
                    classNameInput={css.input_wrap}
                    value={currentCollection}
                  />
                )}
              </Formik>
              <Button onClick={isMoveCollectionModalClose} variant="blue">
                Cancel
              </Button>
            </div>
          )}
        </div>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <TabPanel className={css.tabs_tabpanel}>{myNfstContent}</TabPanel>
        <div className={css.tabs_btn_wrap}>
          <Button className={css.tabs_btn}>Load More</Button>
        </div>
      </Tabs>
      <CheckMoveCollectionModal
        open={checkMoveCollectionModal}
        closeModal={checkMoveCollectionModalClose}
        openModal={checkMoveCollectionModalOpen}
        collection={currentCollection?.label}
      />
      <CheckRemoveCollectionModal
        open={checkRemoveCollectionModal}
        closeModal={checkRemoveCollectionModalClose}
        openModal={checkRemoveCollectionModalOpen}
      />
    </div>
  )
}

export default ProfileMyNfts
