import { useCallback, useEffect, useMemo, useState } from 'react'
import css from './CollectionPage.module.scss'
// Components
import PrevPage from 'components/PrevPage'
import NftItem from 'components/Items/NftItem'
import RecommendedSlider from 'components/Slider/RecommendedSlider'
import CostItem from 'components/Items/CostItem'
import CatalogItem from 'components/Items/CatalogItem'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import mainBgImg from 'images/main-bg.png'
import mainBgMobileImg from 'images/main-bg-mobile.png'
// Utils
import { recomendedItems, recommendedCollections } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const CollectionPage = () => {
  const [selectedItem, setSelectedItem] = useState({})

  const changeActiveItem = useCallback((id) => {
    const collectionItem = recomendedItems.find((item) => item.id === id)
    setSelectedItem(collectionItem)
  }, [])

  const collectionItemsContent = useMemo(
    () => (
      <div className={css.collection_items}>
        {recomendedItems?.map((item) => (
          <CatalogItem
            {...item}
            key={item.id}
            small
            onClick={changeActiveItem}
            active={selectedItem.id === item.id}
          />
        ))}
      </div>
    ),
    [selectedItem.id, changeActiveItem]
  )

  const sliderContent = useMemo(
    () =>
      recommendedCollections?.map((item) => (
        <CatalogItem key={item.id} {...item} className={css.slider_item} />
      )),
    []
  )

  useEffect(() => {
    setSelectedItem(recomendedItems[0])
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.main_wrapper}>
        <div className={css.container}>
          <PrevPage href="/" className={css.prevpage}>
            Back to the main page
          </PrevPage>
          <div className={css.section_top}>
            <div className={css.section_top_wrap}>
              <Title className={css.h1}>New great NFT cards collection!</Title>
              <div className={css.description}>
                A new collection of wars of forgotten China, heirs of forgotten
                wisdom.
              </div>
              <CostItem price="10.417009 ETH" className={css.cost} />
            </div>
            <img src={mainBgImg} alt="bg" className={css.section_top_bg} />
            <img
              src={mainBgMobileImg}
              alt="bg"
              className={css.section_top_bg_mobile}
            />
          </div>
        </div>
      </div>
      <div className={css.section_info}>
        <div className={css.container}>
          <Title className={css.h3} type="h3">
            Collection content
          </Title>
          <div className={css.section_info_wrap}>
            <div className={css.collection_item}>
              <NftItem
                image={selectedItem.image}
                level="15th level"
                className={css.collection_item_wrap}
              />
              <div className={css.collection_item_info}>
                <div className={css.collection_item_subtitle}>ITEMS</div>
                <div className={css.collection_item_title}>
                  {selectedItem.name}
                </div>
                <div className={css.collection_item_description}>
                  {selectedItem.description}
                </div>
                <div className={css.collection_item_category}>
                  {selectedItem.category}
                </div>
              </div>
              {collectionItemsContent}
            </div>
          </div>
        </div>
      </div>
      <div className={css.container}>
        <RecommendedSlider
          items={sliderContent}
          title="Recommended fit items"
          className={css.slider}
        />
      </div>
    </LayoutWithBg>
  )
}

export default CollectionPage
