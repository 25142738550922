import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './Checkbox.module.scss'

const Checkbox = ({ label, value, onChange, className, id }) => (
  <div className={cn(css.checkbox, className)}>
    <input
      type="checkbox"
      checked={value}
      onChange={onChange}
      className={css.checkbox_input}
      id={id}
    />
    <label className={css.checkbox_label} htmlFor={id}>
      <div className={css.checkbox_label_wrap}>{label}</div>
    </label>
  </div>
)

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.number,
}

export default Checkbox
