import { useCallback, useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import css from './MintNftForm.module.scss'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
// Components
import { Select, Tooltip, Input, Button } from 'UI'
import NftInfoItem from 'components/Items/NftInfoItem'
import {
  AddPropertiesModal,
  AddLevelsModal,
  AddStatsModal,
  CreateNftSuccessModal,
  DeleteNftModal,
} from 'components/Popups'
import UnlockContent from './UnlockContent/UnlockContent'
import SensitiveContent from './SensitiveContent/SensitiveContent'
import Supply from './Supply/Supply'
import Blockchain from './Blockchain/Blockchain'
import FreezeMetadata from './FreezeMetadata/FreezeMetadata'
import TooltipQuestion from 'components/TooltipQuestion'
// Images
import cloudIcon from 'icons/cloud.svg'
import closeIcon from 'icons/close.svg'
import propertiesIcon from 'icons/properties.svg'
import starIcon from 'icons/star.svg'
import statsIcon from 'icons/stats.svg'
// Hooks
import useModal from 'hooks/useModal'
// Utils
import {
  formatData,
  formatDataPropertiesToEdit,
  formatDataToEdit,
  schema,
} from './utils'
import { isEmptyObject } from 'utils'
import { blockchains } from './Blockchain/utils'
// Store
import {
  createNftHandler,
  modalCreateNftModalHandler,
  editNftHandler,
  modalRemoveNftHandler,
} from 'store/nft'

const MintNftForm = ({ type, data, typeForm }) => {
  const dispatch = useDispatch()
  const [properiesModal, properiesModalOpen, properiesModalClose] = useModal()
  const [levelsModal, levelsModalOpen, levelsModalClose] = useModal()
  const [statsModal, statsModalOpen, statsModalClose] = useModal()
  const inputRef = useRef(null)
  const [image, setImage] = useState({})
  const [imageUrl, setImageUrl] = useState('')
  const [properties, setProperties] = useState([])
  const [levels, setLevels] = useState([])
  const [stats, setStats] = useState([])
  const formikRef = useRef()

  const submitHandler = useCallback(
    (values) => {
      const result = {
        ...values,
        image,
        properties,
        levels: formatData(levels),
        stats: formatData(stats),
        type,
      }

      if (typeForm === 'CREATE') {
        dispatch(createNftHandler(result))
      } else {
        dispatch(editNftHandler({ ...result, type: data.type, id: data._id }))
      }
    },
    [image, dispatch, properties, levels, stats, type, typeForm, data]
  )

  const clickButton = useCallback(() => {
    inputRef.current.click()
  }, [])

  const handleImageChange = useCallback((event) => {
    const imgObj = event.target.files && event.target.files[0]

    if (!imgObj) {
      return
    }

    setImage(imgObj)
    setImageUrl(URL.createObjectURL(imgObj))
  }, [])

  const closeImage = useCallback(() => {
    setImage({})
    setImageUrl('')
  }, [])

  useEffect(() => {
    const formik = formikRef.current

    if (formik && typeForm === 'EDIT' && !isEmptyObject(data)) {
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('description', data.description)
      formik.setFieldValue('externalLink', data.externalLink)
      formik.setFieldValue('supply', data.supply)
      formik.setFieldValue(
        'explicitAndSensitiveContent',
        data.explicitAndSensitiveContent
      )
      formik.setFieldValue('unlockableContent', data.unlockableContent)
      formik.setFieldValue('url', data.url ?? '')
      setProperties(formatDataPropertiesToEdit(data.properties))
      setLevels(formatDataToEdit(data.levels))
      setStats(formatDataToEdit(data.stats))
      setImageUrl(process.env.REACT_APP_IMAGE_URL + data.file)
    }

    return () => modalCreateNftModalHandler(false)
  }, [typeForm, data])

  return (
    <div className={css.form}>
      <div className={cn(css.uploadimage, imageUrl && css.active)}>
        {imageUrl ? (
          <>
            <img src={imageUrl} alt="img" />
            <div className={css.uploadimage_close} onClick={closeImage}>
              <img src={closeIcon} alt="close" />
            </div>
          </>
        ) : (
          <>
            <div className={css.uploadimage_icon}>
              <img src={cloudIcon} alt="cloud" />
            </div>
            <div className={css.uploadimage_title}>
              Select a file or drag and drop here
              <span className={css.red}>*</span>
            </div>
            <div className={css.uploadimage_description}>
              JPG, PNG or PDF, file size no more than 10MB
            </div>
            <Button className={css.uploadimage_btn} onClick={clickButton}>
              Upload Image
            </Button>
          </>
        )}

        <input
          ref={inputRef}
          type="file"
          className={css.hide}
          onChange={handleImageChange}
          accept="image/*"
        />
      </div>
      <Formik
        initialValues={{
          name: '',
          description: '',
          externalLink: '',
          explicitAndSensitiveContent: false,
          supply: '',
          blockchain: blockchains[0],
          collection: '',
          unlockableContent: '',
          url: '',
        }}
        validationSchema={schema}
        onSubmit={submitHandler}
        innerRef={formikRef}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                NFT Name <span className={css.red}>*</span>
              </div>
              <Input
                name="name"
                type="text"
                className={css.input}
                classNameInput={css.form_input_wrap}
              />
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>URL</div>
              <Input
                name="url"
                type="text"
                className={css.input}
                classNameInput={css.form_input_wrap}
              />
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                External link <TooltipQuestion id="external-link" />
              </div>
              <Input
                name="externalLink"
                type="text"
                className={css.input}
                classNameInput={css.form_input_wrap}
              />
            </div>
            <div className={css.form_textarea}>
              <div className={css.form_input_name}>
                Description <TooltipQuestion id="description" />
              </div>
              <Input
                textarea
                name="description"
                type="text"
                className={css.textarea}
              />
              <div className={css.form_textarea_up}>
                {props.values.description.length} up to 250
              </div>
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                Collection <TooltipQuestion id="collection" />
              </div>
              <Select
                options={[]}
                placeholder=""
                name="collection"
                onChange={props.setFieldValue}
                className={css.select}
                classNameInput={css.input_wrap}
              />
            </div>
            <div>
              <NftInfoItem
                title="Properties"
                description="Textual traits that show up as rectangles"
                icon={propertiesIcon}
                btnClickHandler={properiesModalOpen}
                addedItems={properties}
                className={css.form_collection_item}
                typeForm={typeForm}
              />
              <NftInfoItem
                title="Levels"
                description="Numerical traits that show as a progress bar"
                icon={starIcon}
                btnClickHandler={levelsModalOpen}
                addedItems={levels}
                type="progress"
                className={css.form_collection_item}
                typeForm={typeForm}
              />
              <NftInfoItem
                title="Stats"
                description="Numerical traits that just show as numbers"
                icon={statsIcon}
                btnClickHandler={statsModalOpen}
                addedItems={stats}
                type="withoutProgress"
                className={css.form_collection_item}
                typeForm={typeForm}
              />
              <UnlockContent
                className={css.form_collection_item}
                value={props.values.unlockableContent}
                setFieldValue={props.setFieldValue}
              />
              <SensitiveContent
                className={css.form_collection_item}
                onChange={props.setFieldValue}
                name="explicitAndSensitiveContent"
                checked={props.values.explicitAndSensitiveContent}
              />
            </div>
            <Supply onChange={props.setFieldValue} />
            <Blockchain onChange={props.setFieldValue} />
            <FreezeMetadata tooltip={<TooltipQuestion id="freeze" />} />
            {typeForm === 'CREATE' ? (
              <Button
                type="submit"
                className={css.form_btn}
                disabled={!props.isValid && !isEmptyObject(image)}
              >
                Create
              </Button>
            ) : (
              <div className={css.form_btns}>
                <Button
                  type="submit"
                  disabled={!props.isValid && !isEmptyObject(image)}
                >
                  Submit changes
                </Button>
                <Button
                  variant="blue"
                  onClick={() => dispatch(modalRemoveNftHandler(true))}
                >
                  Delete item
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
      <Tooltip id="external-link">
        OpenSea will include a link to this URL on this item's detail page, so
        that users can click to learn more about it. You are welcome to link to
        your own webpage with more details.
      </Tooltip>
      <Tooltip id="description">
        The description will be included on the item's detail page underneath
        its image. Markdown syntax is supported.
      </Tooltip>
      <Tooltip id="collection">
        This is the collection where your item will appear.
      </Tooltip>
      <Tooltip id="freeze">
        Once locked, your content cannot be edited or removed as it is
        permanently stored in decentralized file storage, which will be
        accessible for other clients to view and use. Learn more about freezing
        metadata here.
      </Tooltip>
      <AddPropertiesModal
        open={properiesModal}
        closeModal={properiesModalClose}
        openModal={properiesModalOpen}
        addedItems={properties}
        setFunc={setProperties}
      />
      <AddLevelsModal
        open={levelsModal}
        closeModal={levelsModalClose}
        openModal={levelsModalOpen}
        addedItems={levels}
        setFunc={setLevels}
      />
      <AddStatsModal
        open={statsModal}
        closeModal={statsModalClose}
        openModal={statsModalOpen}
        addedItems={stats}
        setFunc={setStats}
      />
      {typeForm === 'CREATE' && <CreateNftSuccessModal />}
      {typeForm === 'EDIT' && <DeleteNftModal />}
    </div>
  )
}

export default MintNftForm
