import css from './DeleteNftModal.module.scss'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
// Components
import { Popup, Button } from 'UI'
// Store
import { modalRemoveNftHandler, removeNftHandler } from 'store/nft'

const DeleteNftModal = () => {
  const dispatch = useDispatch()
  const isShowRemoveNft = useSelector((state) => state.nft.isShowRemoveNft)

  const close = () => dispatch(modalRemoveNftHandler(false))

  return (
    <Popup
      open={isShowRemoveNft}
      closeModal={close}
      openModal={() => dispatch(modalRemoveNftHandler(true))}
      title="Remove nft ?"
      description="Are you sure you want to remove the selected nft?"
    >
      <div className={css.btns}>
        <Button variant="blue" onClick={close}>
          Go back
        </Button>
        <Button onClick={() => dispatch(removeNftHandler())}>Remove</Button>
      </div>
    </Popup>
  )
}

DeleteNftModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default DeleteNftModal
