import { $api } from '.'

export const getAllCollectionsRequest = () => {
  return $api.get('/nft/collections')
}

export const getAllCardsRequest = () => {
  return $api.get('/nft')
}

export const getNftItemsRequest = () => {
  return $api.get('/nft')
}

export const createNftRequest = (payload) => {
  const formData = new FormData()
  console.log(payload)
  formData.append('type', payload.type)
  formData.append('file', payload.image)
  formData.append('name', payload.name)
  formData.append(
    'explicitAndSensitiveContent',
    payload.explicitAndSensitiveContent
  )
  formData.append('blockchain', 'ethereum')
  formData.append('externalLink', payload.externalLink)
  formData.append('url', payload.url)
  formData.append('description', payload.description)
  formData.append('unlockableContent', payload.unlockableContent)
  formData.append('supply', payload.supply)
  formData.append('properties', JSON.stringify(payload.properties))
  formData.append('levels', JSON.stringify(payload.levels))
  formData.append('stats', JSON.stringify(payload.stats))

  return $api.post('/nft', formData, {
    headers: {
      'Content-Type': 'multipart/form-data; ',
    },
  })
}

export const editNftRequest = (payload) => {
  const formData = new FormData()

  formData.append('type', payload.type)

  if (payload.image instanceof File) {
    formData.append('file', payload.image)
  }

  formData.append('name', payload.name)
  formData.append(
    'explicitAndSensitiveContent',
    payload.explicitAndSensitiveContent
  )
  formData.append('blockchain', 'ethereum')
  formData.append('externalLink', payload.externalLink)
  formData.append('url', payload.url)
  formData.append('description', payload.description)
  formData.append('unlockableContent', payload.unlockableContent)
  formData.append('supply', payload.supply)
  formData.append('properties', JSON.stringify(payload.properties))
  formData.append('levels', JSON.stringify(payload.levels))
  formData.append('stats', JSON.stringify(payload.stats))

  return $api.put('/nft/' + payload.id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data; ',
    },
  })
}

export const removeNftRequest = (id) => {
  return $api.delete('/nft/' + id)
}

export const getNftByIdRequest = (id) => {
  return $api.get('/nft/' + id)
}

export const getHistoryNftByIdRequest = (id) => {
  return $api.get('/nft/' + id + '/history')
}
