import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Not found | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content="This page is lost." />
    </Helmet>
  )
}

export default CustomHelmet
