import css from './Popup.module.scss'
import PropTypes from 'prop-types'
import { default as CustomPopup } from 'reactjs-popup'
// Images
import closeWhiteIcon from 'icons/close-white.svg'
// Components
import { Title } from 'UI'

const Popup = ({
  open,
  closeModal,
  openModal,
  children,
  title,
  description,
  closeOnDocumentClick = true,
  ...props
}) => (
  <CustomPopup
    open={open}
    onClose={closeModal}
    onOpen={openModal}
    closeOnDocumentClick={closeOnDocumentClick}
    className={css.popup}
    {...props}
  >
    <div onClick={closeModal} className={css.popup_close}>
      <img src={closeWhiteIcon} alt="close" />
    </div>
    {title && (
      <Title className={css.popup_title} type="h3">
        {title}
      </Title>
    )}
    {description && <div className={css.popup_description}>{description}</div>}
    {children}
  </CustomPopup>
)

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  closeOnDocumentClick: PropTypes.bool,
}

export default Popup
