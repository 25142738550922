import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// Reducers
import authReducer from 'store/auth/auth'
import snackbarReducer from 'store/snackbar/snackbar'
import nftReducer from 'store/nft/nft'
import globalReducer from 'store/global/global'
import catalogReducer from './catalog/catalog'
import collection from './collection/collection'
// Saga
import { rootWatcher } from './saga'

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    nft: nftReducer,
    global: globalReducer,
    catalog: catalogReducer,
    collection: collection,
  },
  middleware: [saga],
})

saga.run(rootWatcher)
