import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Best players | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="A huge number of players and NFT collectors are out there on our platform. Find all of them in the rating page below."
      />
    </Helmet>
  )
}

export default CustomHelmet
