import { useCallback, useMemo, useState } from 'react'
import css from './ExistingCardsPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import cn from 'classnames'
import { Formik } from 'formik'
// Components
import ExistingTabItem from 'components/Items/ExistingTabItem'
import NftCardItem from 'components/Items/NftCardItem'
import { Button, Input, Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import searchIcon from 'icons/search.svg'
// Utils
import { tabs, items } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const ExistingCardsPage = () => {
  const [indexTab, setIndexTab] = useState(0)

  const onChangeTabs = useCallback((index) => {
    setIndexTab(index)
  }, [])

  const submitSearch = useCallback((values) => {
    console.log(values)
  }, [])

  const itemsContent = useMemo(
    () => (
      <div>
        <div className={css.items}>
          {items?.map((item) => (
            <NftCardItem {...item} key={item.id} />
          ))}
        </div>
        <div className={css.btn}>
          <Button>Load More</Button>
        </div>
      </div>
    ),
    []
  )

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <Title className={css.h2} type="h2">
          Existing cards
        </Title>
        <div className={css.description}>
          A huge number of players and NFT collectors on our platform,
          <br />
          presented in the rating page below
        </div>
        <Tabs className={css.tabs} onSelect={onChangeTabs}>
          <TabList>
            <div className={cn(css.tabs_tablist, 'tabs')}>
              {tabs?.map((item, index) => (
                <Tab key={item.id} className={css.tabs_tab}>
                  <ExistingTabItem {...item} active={index === indexTab} />
                </Tab>
              ))}
            </div>
          </TabList>
          <Formik
            initialValues={{ search: '' }}
            onSubmit={(values) => submitSearch(values)}
          >
            {(props) => (
              <form className={css.form} onSubmit={props.handleSubmit}>
                <Input
                  name="search"
                  type="text"
                  placeholder="Search nft"
                  className={css.form_input}
                  leftIcon={<img src={searchIcon} alt="search" />}
                />
              </form>
            )}
          </Formik>
          <TabPanel>{itemsContent}</TabPanel>
          <TabPanel>{itemsContent}</TabPanel>
          <TabPanel>{itemsContent}</TabPanel>
          <TabPanel>{itemsContent}</TabPanel>
          <TabPanel>{itemsContent}</TabPanel>
          <TabPanel>{itemsContent}</TabPanel>
        </Tabs>
      </div>
    </LayoutWithBg>
  )
}

export default ExistingCardsPage
