import css from './CompleteCheckout.module.scss'
// Components
import { Button, Tooltip, Link } from 'UI'
import TooltipQuestion from 'components/TooltipQuestion'
// Images
import arrowIcon from 'icons/arrow.svg'

const CompleteCheckout = ({ changeStep }) => {
  return (
    <div>
      <div className={css.back} onClick={() => changeStep(1)}>
        <img src={arrowIcon} alt="arrow" />
      </div>
      <div className={css.wrap}>
        <div className={css.wrap_top}>
          <span>Item</span>
          <span>Total</span>
        </div>
        <div className={css.wrap_inner}>
          <div className={css.item}>
            <img
              src="https://cs12.pikabu.ru/post_img/big/2019/11/28/6/1574930142134287464.jpg"
              alt="img"
              className={css.item_img}
            />
            <div className={css.item_wrap}>
              <Link className={css.item_collection} href="/">
                PirateBay
              </Link>
              <div className={css.item_name}>Pirate 1</div>
              <div className={css.item_description}>
                Creator Fees: 1% <TooltipQuestion id="fees" />
              </div>
            </div>
          </div>
          <div className={css.price}>$5,00</div>
        </div>
      </div>
      <Button className={css.btn} onClick={() => changeStep(3)}>
        Complete purchase
      </Button>
      <Tooltip id="fees">
        The creator(s) of this collection will receive 1% for every sale
      </Tooltip>
    </div>
  )
}

export default CompleteCheckout
