import css from './FreezeMetadata.module.scss'
import cn from 'classnames'

const FreezeMetadata = ({ className, tooltip }) => {
  return (
    <div className={cn(css.item, className)}>
      <div className={css.item_wrap}>
        <div className={css.item_info}>
          <div className={css.item_title}>Freeze metadata {tooltip}</div>
          <div className={css.item_description}>
            Freezing your metadata will allow you to permanently lock and store
            all of this item's content in decentralized file storage.
          </div>
          <div className={css.item_bottom}>
            To freeze your metadata, you must create your item first.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreezeMetadata
