// Images
import copyIcon from 'icons/copy.svg'
import facebookIcon from 'icons/social-network/facebook.svg'
import twitterIcon from 'icons/social-network/twitter.svg'

export const data = [
  { id: 1, name: 'Copy link', icon: copyIcon },
  { id: 2, name: 'Share on Facebook', icon: facebookIcon },
  { id: 3, name: 'Share on Twitter', icon: twitterIcon },
]
