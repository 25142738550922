import css from './LinkItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Images
import arrowOutlineIcon from 'icons/arrow-outline.svg'
// Components
import { Link } from 'UI'

const LinkItem = ({ className, text, href }) => (
  <Link className={cn(css.item, className)} href={href}>
    <div className={css.item_text}>{text}</div>
    <div className={css.item_arrow}>
      <img src={arrowOutlineIcon} alt="arrow" />
    </div>
  </Link>
)

LinkItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
}

export default LinkItem
