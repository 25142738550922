import Cookies from 'js-cookie'

export const setCookie = (key, value, expires = 1) => {
  Cookies.set(key, value, { expires })
}

export const removeCookie = (key) => {
  Cookies.remove(key)
}

export const getCookie = (key) => {
  return Cookies.get(key)
}
