import css from './CheckRemoveCollectionModal.module.scss'
import PropTypes from 'prop-types'
// Components
import { Popup, Button } from 'UI'

const CheckRemoveCollectionModal = ({ open, closeModal, openModal }) => {
  return (
    <Popup
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title="Move from collection"
      description="Are you sure you want to remove the selected nft from the collection?"
    >
      <div className={css.btns}>
        <Button variant="blue" onClick={closeModal}>
          Go back
        </Button>
        <Button>Remove</Button>
      </div>
    </Popup>
  )
}

CheckRemoveCollectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default CheckRemoveCollectionModal
