import { useEffect, useState } from 'react'
// import css from './MakeOfferModal.module.scss'
import PropTypes from 'prop-types'
// Components
import { Popup } from 'UI'
import InfoNft from '../BuyNftModal/InfoNft/InfoNft'
import MakeAnOffer from './MakeAnOffer/MakeAnOffer'

const MakeOfferModal = ({ open, closeModal, openModal }) => {
  const [checked, setChecked] = useState(false)
  const [step, setStep] = useState(1) // 1, 2, 3

  const changeChecked = () => {
    setChecked(!checked)
    setStep(2)
  }

  const changeStep = (num) => {
    setStep(num)
  }

  useEffect(() => {
    if (open) {
      setChecked(false)
      setStep(1)
    }
  }, [open])

  useEffect(() => {
    setChecked(false)
  }, [step])

  return (
    <Popup
      closeOnDocumentClick={false}
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title={
        step === 1
          ? 'This is an unreviewed collection'
          : step === 2
          ? 'Make an offer'
          : 'Add funds to purchase'
      }
    >
      {(() => {
        switch (step) {
          case 1:
            return <InfoNft checked={checked} changeChecked={changeChecked} />
          case 2:
            return <MakeAnOffer changeStep={changeStep} />
          default:
            return null
        }
      })()}
    </Popup>
  )
}

MakeOfferModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default MakeOfferModal
