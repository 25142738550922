import { useCallback, useMemo } from 'react'
import css from './PersonalCabinetPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Formik } from 'formik'
import cn from 'classnames'
// Components
import CatalogItem from 'components/Items/CatalogItem'
import MainTabItem from 'components/Items/MainTabItem'
import WalletItem from 'components/Items/WalletItem'
import { Table, Button, Input } from 'UI'
import TabItem from 'components/Items/TabItem'
import NftCardItem from 'components/Items/NftCardItem'
import ProfileAuction from 'components/ProfileAuction'
import ProfileMyNfts from 'components/ProfileMyNfts'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
import ProfileSettings from 'components/ProfileSettings'
// Images
import planetsImg from 'images/planets.jpeg'
import duckImg from 'images/duck.png'
import catalog1Img from 'images/catalog1.jpeg'
import searchIcon from 'icons/search.svg'
// Utils
import {
  personalItems,
  tabs,
  wallets,
  tabsTable,
  tableTitles,
  tableRows,
  tabsMyNftCards,
  tabsNftCardsItems,
} from './utils'
// Helmet
import CustomHelmet from './Helmet'

const PersonalCabinetPage = () => {
  const tableRowsContent = useMemo(
    () => (
      <div className={css.table_rows}>
        {tableRows?.map((item) => (
          <div className={css.table_row} key={item.id}>
            <div>{item.id}</div>
            <div>
              <img src={catalog1Img} alt="nft" className={css.table_img} />
              {item.name}
            </div>
            <div>{item.race}</div>
            <div>{item.cost}</div>
            <div>{item.seller}</div>
            <div>{item.date}</div>
          </div>
        ))}
      </div>
    ),
    []
  )

  const myCardsContent = useMemo(
    () => (
      <div className={css.items_cards}>
        {tabsNftCardsItems?.map((item) => (
          <NftCardItem {...item} key={item.id} />
        ))}
      </div>
    ),
    []
  )

  const tableTitlesContent = useMemo(
    () => (
      <div className={css.table_titles}>
        {tableTitles?.map((item) => (
          <div className={css.table_title} key={item.id}>
            {item.text}
          </div>
        ))}
      </div>
    ),
    []
  )

  const submitSearch = useCallback((values) => {
    console.log(values)
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div>
          <div className={css.section_top}>
            <img
              src={planetsImg}
              alt="planets"
              className={css.section_top_bg}
            />
            <div className={css.section_top_avatar}>
              <img src={duckImg} alt="duck" />
            </div>
          </div>
          <div className={css.name}>John Watts</div>
        </div>
        <Tabs className={css.tabs}>
          <TabList className={css.tabs_tablist}>
            <div className={cn(css.tabs_titles, 'main-tabs')}>
              {tabs?.map((item) => (
                <Tab
                  key={item.id}
                  selectedClassName="main-tab-active"
                  className="main-tab"
                >
                  <MainTabItem {...item} className="main-tab-item-active" />
                </Tab>
              ))}
            </div>
          </TabList>
          <TabPanel>
            <div className={css.tabs_tabpanel}>
              <div className={css.tabs_items}>
                {personalItems?.map((item) => (
                  <CatalogItem {...item} key={item.id} />
                ))}
              </div>
              <Button className={css.tabs_btn}>Load More</Button>
            </div>
            <div className={css.wallets}>
              <Title className={css.wallets_title} type="h3">
                Wallets
              </Title>
              <div className={css.wallets_items}>
                {wallets?.map((item) => (
                  <WalletItem
                    {...item}
                    key={item.id}
                    className={css.wallets_item}
                    nameRadio="wallet"
                  />
                ))}
                <div className={css.wallets_item_connect}>
                  <div className={css.wallets_item_name}>
                    Connect another wallet
                  </div>
                  <Button>Connect</Button>
                </div>
              </div>
            </div>
            <Tabs className={css.table_tabs}>
              <TabList className={css.table_tabs_tablist}>
                <div className="table-tabs">
                  {tabsTable?.map((item) => (
                    <Tab
                      key={item.id}
                      selectedClassName="table-tab-active"
                      className="table-tab"
                    >
                      <div className="table-tab-item-active">{item.text}</div>
                    </Tab>
                  ))}
                </div>
              </TabList>
              <TabPanel>
                <Table
                  titles={tableTitlesContent}
                  rows={tableRowsContent}
                  className={css.table}
                />
              </TabPanel>
              <TabPanel>
                <Table
                  titles={tableTitlesContent}
                  rows={tableRowsContent}
                  className={css.table}
                />
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <ProfileMyNfts />
          </TabPanel>
          <TabPanel>
            <Tabs className={css.tabs}>
              <TabList className={css.tabs_tablist}>
                <div className={css.tabs_tablist_wrap}>
                  {tabsMyNftCards?.map((item) => (
                    <Tab
                      key={item.id}
                      selectedClassName="tab-active"
                      className="tab"
                    >
                      <TabItem {...item} className="tab-item-active" />
                    </Tab>
                  ))}
                </div>
              </TabList>
              <Formik
                initialValues={{ search: '' }}
                onSubmit={(values) => submitSearch(values)}
              >
                {(props) => (
                  <form className={css.tabs_form} onSubmit={props.handleSubmit}>
                    <Input
                      name="search"
                      type="text"
                      placeholder="Search nft"
                      className={css.tabs_input}
                      leftIcon={<img src={searchIcon} alt="search" />}
                    />
                  </form>
                )}
              </Formik>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <div className={css.tabs_btn_wrap}>
                <Button className={css.tabs_btn}>Load More</Button>
              </div>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <ProfileSettings />
          </TabPanel>
          <TabPanel>
            <ProfileAuction />
          </TabPanel>
        </Tabs>
      </div>
    </LayoutWithBg>
  )
}

export default PersonalCabinetPage
