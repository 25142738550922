import { useCallback, useEffect, useRef, useState } from 'react'
import css from './SignInPage.module.scss'
import { Formik } from 'formik'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Components
import { Button, Input, Link, Title } from 'UI'
import Connect from 'components/Connect'
// Images
import eyeIcon from 'icons/eye.svg'
import eyeHideIcon from 'icons/eye-hide.svg'
// Store
import { loginHandler } from 'store/auth'
// Helmet
import CustomHelmet from './Helmet'

const SignUpPage = () => {
  const formikRef = useRef()
  const dispath = useDispatch()
  const [isTypePassword, setIsTypePassword] = useState(false)
  const errors = useSelector((state) => state.auth.errorsLogin)
  const navigate = useNavigate()

  const onSubmitHandler = useCallback(
    (values) => {
      dispath(
        loginHandler({
          walletOrNickname: values.walletOrNickname,
          password: values.password,
          navigate,
        })
      )
    },
    [dispath, navigate]
  )

  const toggleTypePassword = useCallback(() => {
    setIsTypePassword(!isTypePassword)
  }, [isTypePassword])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(errors)
    }
  }, [errors])

  return (
    <>
      <CustomHelmet />
      <Formik
        innerRef={formikRef}
        initialValues={{ walletOrNickname: '', password: '' }}
        onSubmit={(values) => onSubmitHandler(values)}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <Title className={css.h1}>sign in</Title>
            <Input
              label="Wallet number or nickname"
              name="walletOrNickname"
              type="text"
              placeholder="Johny-tungle"
              className={css.input}
              classNameInput={css.input_wrap}
            />
            <Input
              type={isTypePassword ? 'text' : 'password'}
              label="Password"
              name="password"
              placeholder="********"
              className={cn(css.input, css.input_password)}
              classNameInput={css.input_wrap}
              rightIcon={
                <div className={css.input_icon}>
                  {isTypePassword ? (
                    <img src={eyeHideIcon} alt="eye" />
                  ) : (
                    <img src={eyeIcon} alt="eye" />
                  )}
                </div>
              }
              onClickRightIcon={toggleTypePassword}
            />
            <div className={css.btn}>
              <Button type="submit">Sign in</Button>
            </div>
            <Connect />
            <div className={css.signin}>
              Still don’t have an account?{' '}
              <Link href="/auth/sign-up" className={css.link}>
                Sign up
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SignUpPage
