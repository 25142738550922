import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit'

const selectSelf = (state) => state

const unsafeSelector = createSelector(selectSelf, (state) => state.collection)

export const selectCollection = createDraftSafeSelector(
  unsafeSelector,
  (state) => state.collection
)

export const selectShowModalRemoveCollection = createDraftSafeSelector(
  unsafeSelector,
  (state) => state.isShowModalRemoveCollection
)

export const selectShowModalCreateCollection = createDraftSafeSelector(
  unsafeSelector,
  (state) => state.isShowModalCreateCollection
)
