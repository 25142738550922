import diamondImg from 'images/diamond.svg'
import swordImg from 'images/sword.svg'
import elixirImg from 'images/elixir.svg'
import monolithImg from 'images/cards/monolith.png'
import corsairImg from 'images/cards/corsair.png'
import dragonsplicersImg from 'images/cards/dragonsplicers.png'
import hullsImg from 'images/cards/hulls.png'
import syntaxImg from 'images/cards/syntax.png'
import starIcon from 'icons/star.svg'
import starsIcon from 'icons/stars.svg'
import magicWandIcon from 'icons/magic-wand.svg'
import diamondIcon from 'icons/diamond.svg'
import diamond2Icon from 'icons/diamond2.svg'
import circleIcon from 'icons/circle.svg'

export const cardTypes = [
  {
    id: 1,
    text: 'Health (protection)',
    image: diamondImg,
  },
  {
    id: 2,
    text: 'Monolith Zardom cards',
    image: swordImg,
  },
  {
    id: 3,
    text: 'Corsair Coalition cards',
    image: elixirImg,
  },
]

export const cardTypes2 = [
  {
    id: 1,
    text: 'Monolith Zardom',
    image: monolithImg,
  },
  {
    id: 2,
    text: 'Corsair Coalition',
    image: corsairImg,
  },
  {
    id: 3,
    text: 'Dragonsplicers',
    image: dragonsplicersImg,
  },
  {
    id: 4,
    text: 'Hulls',
    image: hullsImg,
  },
  {
    id: 5,
    text: 'The Order of Syntax',
    image: syntaxImg,
  },
]

export const statusItems = [
  {
    id: 1,
    name: 'Legendary',
    from: '25% (+ 3% per level,+ 4% at 10th level)',
    to: '60%',
    icon: starIcon,
  },
  {
    id: 2,
    name: 'Epic',
    from: '10% (+ 2% per level)',
    to: '40%',
    icon: starsIcon,
  },
  {
    id: 3,
    name: 'Mythical',
    from: '15% (+ 2% per level)',
    to: '35%',
    icon: magicWandIcon,
  },
  {
    id: 4,
    name: 'Super rare',
    from: '10% (+ 2% per level)',
    to: '30%',
    icon: diamondIcon,
  },
  {
    id: 5,
    name: 'Rare',
    from: '5% (+ 2% per level)',
    to: '25%',
    icon: diamond2Icon,
  },
  {
    id: 6,
    name: 'Common',
    from: '0% (+ 1% per level)',
    to: '60%',
    icon: circleIcon,
  },
]
