import css from './PaginationItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const PaginationItem = ({ className, num, active, onClick }) => (
  <div
    className={cn(css.item, active && css.active, className)}
    onClick={onClick}
  >
    {num}
  </div>
)

PaginationItem.propTypes = {
  className: PropTypes.string,
  num: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

export default PaginationItem
