import { useDispatch, useSelector } from 'react-redux'
import css from './CreateCollectionSuccessModal.module.scss'
// Components
import { Popup, Link } from 'UI'
// Store
import {
  selectCollection,
  selectShowModalCreateCollection,
  modalCreateCollectionHandler,
} from 'store/collection'

const CreateCollectionSuccessModal = () => {
  const dispatch = useDispatch()
  const collection = useSelector(selectCollection)
  const isShowModalCreateCollection = useSelector(
    selectShowModalCreateCollection
  )

  return (
    <Popup
      open={isShowModalCreateCollection}
      closeModal={() => dispatch(modalCreateCollectionHandler(false))}
      openModal={() => dispatch(modalCreateCollectionHandler(true))}
      title={'You created ' + collection.name}
    >
      <div className={css.popup}>
        You just created{' '}
        <Link href={'/collection/' + collection._id}>{collection.name}</Link>
      </div>
    </Popup>
  )
}

export default CreateCollectionSuccessModal
