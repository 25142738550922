import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Catalog | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Introducing to you BOT Metamarket, where you will be able to find the right NFTs just for your collection. Here you will find NFTs created by our in-house artists as well as the independent pieces made by the community! Use the search bar to get just what you need!"
      />
    </Helmet>
  )
}

export default CustomHelmet
