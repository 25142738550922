import { useCallback, useEffect } from 'react'
import css from './MainPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
// Components
import { Button, Title } from 'UI'
import AboutItemsItem from 'components/Items/AboutItemsItem'
import TabItem from 'components/Items/TabItem'
import NftCardItem from 'components/Items/NftCardItem'
import NftCollectionItem from 'components/Items/NftCollectionItem'
import TableItem from 'components/Items/TableItem'
import LinkItem from 'components/Items/LinkItem'
import LoadingScreen from 'components/LoadingScreen'
// Images
import mainBgImg from 'images/main-bg.png'
import mainBgMobileImg from 'images/main-bg-mobile.png'
import iceBgImg from 'images/ice-bg.png'
import disсordImg from 'images/disсord.svg'
import icePurpleBgImg from 'images/ice-purple-bg.png'
import moonImg from 'images/moon.png'
// Utils
import {
  aboutItems,
  tabsNftCards,
  tabsNftCollections,
  table1,
  table2,
  table3,
  mintLinks,
} from './utils'
// Helmet
import CustomHelmet from './Helmet'
// Store
import { collectionsHandler } from 'store/nft'

const MainPage = () => {
  const dispatch = useDispatch()
  const collections = useSelector((state) => state.nft.collections)
  const cards = useSelector((state) => state.nft.cards)

  const renderTableItems = useCallback(
    (arr) =>
      arr?.map((item) => (
        <TableItem key={item.id} {...item} className={css.section_table_item} />
      )),
    []
  )

  useEffect(() => {
    dispatch(collectionsHandler())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className={css.main_wrapper}>
        <CustomHelmet />
        <div className={css.container}>
          <div className={css.section_top}>
            <div className={css.section_top_wrap}>
              <Title className={css.h1}>New great NFT cards collection!</Title>
              <div className={css.description}>
                A new collection of wars of forgotten China, heirs of forgotten
                wisdom.
              </div>
              <Button>Check it</Button>
            </div>
            <img src={mainBgImg} alt="bg" className={css.section_top_bg} />
            <img
              src={mainBgMobileImg}
              alt="bg"
              className={css.section_top_bg_mobile}
            />
          </div>
        </div>
      </div>
      <div className={css.section_info}>
        <div className={css.container}>
          <div className={css.section_info_wrap}>
            {aboutItems?.map((item) => (
              <AboutItemsItem key={item.id} {...item} />
            ))}
          </div>
        </div>
      </div>
      <div className={css.section_items}>
        <div className={css.container}>
          <Title className={css.h2} type="h2">
            Hottest nft cards
          </Title>
          <Tabs className={css.tabs}>
            <TabList className={css.tabs_tablist}>
              <div className={css.tabs_tablist_wrap}>
                {tabsNftCards?.map((item) => (
                  <Tab
                    key={item.id}
                    selectedClassName="tab-active"
                    className="tab"
                  >
                    <TabItem {...item} className="tab-item-active" />
                  </Tab>
                ))}
              </div>
            </TabList>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(0, 8)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(8, 16)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(16, 24)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(24, 32)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(32, 40)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(40, 48)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel}>
              {cards.slice(48, 56)?.map((item) => (
                <NftCardItem
                  key={item.id}
                  {...item}
                  image={item.meta.content[0].url}
                />
              ))}
            </TabPanel>
          </Tabs>
        </div>
        <img className={css.section_items_bg} src={iceBgImg} alt="bg" />
      </div>
      <div className={cn(css.section_items, css.collections)}>
        <div className={css.container}>
          <Title className={css.h2} type="h2">
            hottest nft collections
          </Title>
          <Tabs className={css.tabs}>
            <TabList className={css.tabs_tablist}>
              <div className={css.tabs_tablist_wrap}>
                {tabsNftCollections?.map((item) => (
                  <Tab
                    key={item.id}
                    selectedClassName="tab-active"
                    className="tab"
                  >
                    <TabItem {...item} className="tab-item-active" />
                  </Tab>
                ))}
              </div>
            </TabList>
            <TabPanel className={css.tabs_tabpanel_collection}>
              {collections.slice(0, 8)?.map((item, index) => (
                <NftCollectionItem
                  key={index}
                  {...item}
                  image={item.meta.content[0].url}
                  title={item.meta.name}
                  price={item?.bestBidOrder?.takePrice}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel_collection}>
              {collections.slice(8, 16)?.map((item, index) => (
                <NftCollectionItem
                  key={index}
                  {...item}
                  image={item.meta.content[0].url}
                  title={item.meta.name}
                  price={item?.bestBidOrder?.takePrice}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel_collection}>
              {collections.slice(16, 24)?.map((item, index) => (
                <NftCollectionItem
                  key={index}
                  {...item}
                  image={item.meta.content[0].url}
                  title={item.meta.name}
                  price={item?.bestBidOrder?.takePrice}
                />
              ))}
            </TabPanel>
            <TabPanel className={css.tabs_tabpanel_collection}>
              {collections.slice(24, 32)?.map((item, index) => (
                <NftCollectionItem
                  key={index}
                  {...item}
                  image={item.meta.content[0].url}
                  title={item.meta.name}
                  price={item?.bestBidOrder?.takePrice}
                />
              ))}
            </TabPanel>
          </Tabs>
        </div>
        <div className={css.section_tables}>
          <div className={css.container}>
            <div className={css.section_tables_wrap}>
              <div className={css.section_table}>
                <div className={css.section_table_title}>top nft sellers</div>
                {renderTableItems(table1)}
              </div>
              <div className={css.section_table}>
                <div className={css.section_table_title}>
                  top nft collections
                </div>
                {renderTableItems(table2)}
              </div>
              <div className={css.section_table}>
                <div className={css.section_table_title}>
                  top in-game complied decks
                </div>
                {renderTableItems(table3)}
              </div>
            </div>
          </div>
        </div>
        <div className={css.section_join}>
          <div className={css.container}>
            <div className={css.section_join_wrap}>
              <img
                className={css.section_join_img}
                src={disсordImg}
                alt="disсord"
              />
              <div className={css.section_join_info}>
                <div className={css.section_join_title}>Become a member</div>
                <div className={css.section_join_description}>
                  Connect with other members of our community!
                </div>
                <Button className={css.section_join_btn}>Join Disсord</Button>
              </div>
            </div>
          </div>
          <img className={css.section_join_bg} src={icePurpleBgImg} alt="bg" />
        </div>
      </div>
      <div className={css.section_mint}>
        <div className={cn(css.container, css.section_mint_container)}>
          <div className={css.section_mint_wrap}>
            <Title className={css.section_mint_title} type="h2">
              mint your own nft
            </Title>
            <div className={css.section_mint_description}>
              Become a part of our community as a seller!
            </div>
            <Button>Load More</Button>
            <div className={css.section_mint_info}>
              {mintLinks?.map((item) => (
                <LinkItem key={item.id} {...item} />
              ))}
            </div>
          </div>
        </div>
        <img className={css.section_mint_moon} src={moonImg} alt="moon" />
        <img src={mainBgImg} alt="bg" className={css.section_mint_bg} />
        <img
          src={mainBgMobileImg}
          alt="bg"
          className={css.section_mint_bg_mobile}
        />
      </div>
      <LoadingScreen />
    </div>
  )
}

export default MainPage
