import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import css from './Sidebar.module.scss'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
// Images
import logoImg from 'images/logo.svg'
import logoMinImg from 'images/logo-min.svg'
import searchIcon from 'icons/search.svg'
// Components
import { Input, Dropdown } from 'UI'
import SidebarLink from './SidebarLink/SidebarLink'
// Utils
import { menuSidebar } from './utils'
// Hooks
import useLanguage from 'hooks/useLanguage'

const Sidebar = ({ active }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const [languages, activeLanguage, onChangeLanguage] = useLanguage()
  const [menu, setMenu] = useState(menuSidebar)

  const submitSearch = (values) => {
    console.log(values)
  }

  useLayoutEffect(() => {
    const currentLanguage = languages.find(
      (language) => language.language === i18n.language
    )

    onChangeLanguage(currentLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, i18n.language])

  const clickHandler = (menuItem) => {
    const menuResult = menu.map((item) => {
      if (menuItem.id === item.id) {
        item.active = true
      } else {
        item.active = false
        item.activeList = false
      }

      if (menuItem.id === item.id && !item.href) {
        item.activeList = !item.activeList
      }

      return item
    })

    setMenu(menuResult)

    if (menuItem.href) {
      navigate(menuItem.href)
    }
  }

  useLayoutEffect(() => {
    const menuResult = menu.map((item) => {
      if (item.href === pathname) {
        item.active = true
      } else {
        item.active = false
        item.activeList = false
      }

      if (pathname.includes(item.listPathname)) {
        item.activeList = true
        item.active = true
      }

      return item
    })

    setMenu(menuResult)
  }, [])

  return (
    <div className={cn(css.sidebar, active && css.active)}>
      <div>
        <div className={css.sidebar_header}>
          <img src={logoMinImg} alt="logo" />
          <img src={logoImg} alt="logo" />
        </div>
        <div className={css.sidebar_nav}>
          <Formik
            initialValues={{ search: '' }}
            onSubmit={(values) => submitSearch(values)}
          >
            {(props) => (
              <form className={css.sidebar_form} onSubmit={props.handleSubmit}>
                <Input
                  name="search"
                  type="text"
                  placeholder="Search nft"
                  className={css.sidebar_input}
                  leftIcon={<img src={searchIcon} alt="search" />}
                  classNameInput={css.sidebar_input_wrap}
                />
              </form>
            )}
          </Formik>
          {menu?.map((item) => (
            <SidebarLink
              {...item}
              key={item.id}
              clickHandler={() => clickHandler(item)}
              active={item.active}
              activeList={item.activeList}
            />
          ))}
        </div>
      </div>
      <Dropdown
        list={languages}
        onChange={onChangeLanguage}
        activeItem={activeLanguage}
        classNameList={css.sidebar_language_list}
        className={css.sidebar_language}
      />
    </div>
  )
}

export default Sidebar
