import css from './StepItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Components
import { Button } from 'UI'

const StepItem = ({ className, name, text, image, btn }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_name}>{name}</div>
    <div className={css.item_text}>{text}</div>
    {btn && <Button variant="blue">{btn}</Button>}
    <div className={css.item_image}>
      <img src={image} alt={name} />
    </div>
  </div>
)

StepItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.node,
}

export default StepItem
