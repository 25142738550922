import telegramIcon from 'icons/social-network/telegram.svg'
import twitterIcon from 'icons/social-network/twitter.svg'
import discordIcon from 'icons/social-network/discord.svg'
import instagramIcon from 'icons/social-network/instagram.svg'
import githubIcon from 'icons/social-network/github.svg'
import mIcon from 'icons/social-network/m.svg'
import linkedinIcon from 'icons/social-network/linkedin.svg'
import bitcointalkIcon from 'icons/social-network/bitcointalk.png'
import facebookIcon from 'icons/social-network/facebook.svg'
import redditIcon from 'icons/social-network/reddit.svg'
import youtubeIcon from 'icons/social-network/youtube.svg'

export const menuColumn1 = [
  { id: 1, text: 'About Us', href: 'https://www.botpla.net/#aboutus' },
  { id: 2, text: 'Tokenomics', href: 'https://www.botpla.net/#tokenomics' },
  { id: 3, text: 'Roadmap', href: 'https://www.botpla.net/#roadmap' },
  { id: 4, text: 'Team & Partners', href: 'https://www.botpla.net/#team' },
  {
    id: 5,
    text: 'Documentation',
    href: 'https://www.botpla.net/#documentation',
  },
  { id: 6, text: 'Blog', href: 'https://www.botpla.net/blog/' },
]

export const menuColumn2 = [
  { id: 1, text: 'Earn $BOT', href: 'https://www.botpla.net/' },
  { id: 2, text: 'DEX', href: 'https://www.botpla.net/dex/' },
  { id: 3, text: 'Staking', href: 'https://www.botpla.net/staking/' },
  { id: 4, text: 'Farming', href: 'https://www.botpla.net/farming/' },
  { id: 5, text: 'Wallet', href: 'https://www.botpla.net/wallet' },
  {
    id: 6,
    text: 'NFT marketplace',
    href: 'https://www.botpla.net/nft-marketplace',
  },
]

export const menuColumn3 = [
  { id: 1, text: 'Game', href: 'https://www.botpla.net/the-game/' },
  { id: 2, text: 'Lottery', href: 'https://www.botpla.net/lottery/' },
  { id: 3, text: 'Messenger', href: 'https://www.botpla.net/messenger/' },
  { id: 4, text: 'Contact Us', href: 'https://www.botpla.net/contact-us/' },
  {
    id: 5,
    text: 'Branding Kit',
    href: 'https://drive.google.com/drive/folders/1AZpMGrTCX_R6Vr9KbKAnBhKk25cpkONM',
  },
]

export const socialNetwork = [
  { id: 1, href: 'https://t.me/botplanetnews', icon: telegramIcon },
  { id: 2, href: 'https://twitter.com/BotPlanet_', icon: twitterIcon },
  { id: 3, href: 'https://discord.gg/Fzt2MjghYh', icon: discordIcon },
  { id: 4, href: 'https://www.instagram.com/bot.planet/', icon: instagramIcon },
  { id: 5, href: 'https://github.com/BOTDeFi', icon: githubIcon },
  { id: 6, href: 'https://medium.com/bot-planet-ecosystem', icon: mIcon },
  {
    id: 7,
    href: 'https://www.linkedin.com/company/botplanetltd',
    icon: linkedinIcon,
  },
  {
    id: 8,
    href: 'https://bitcointalk.org/index.php?topic=5357949.msg57845384#msg57845384',
    icon: bitcointalkIcon,
  },
  { id: 9, href: 'https://www.facebook.com/BotDefi', icon: facebookIcon },
  {
    id: 10,
    href: 'https://www.reddit.com/r/Bot_Planet_Ecosystem/',
    icon: redditIcon,
  },
  {
    id: 11,
    href: 'https://www.youtube.com/channel/UClMahaW1s5jyH31LT3XuFbQ',
    icon: youtubeIcon,
  },
]

export const links = [
  {
    id: 1,
    href: 'https://www.botpla.net/wp-content/uploads/2022/02/bot_planet_privacy_policy.pdf',
    name: 'Privacy Policy',
  },
  {
    id: 2,
    href: 'https://www.botpla.net/wp-content/uploads/2022/02/Botplanet_Terms_of_Services.pdf',
    name: 'Terms&Conditions',
  },
  { id: 3, href: '/faq', name: 'F.A.Q' },
]
