import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'
import metamaskIcon from 'icons/wallets/metamask.svg'
import botIcon from 'icons/wallets/bot.svg'
import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'

export const personalItems = [
  { id: 1, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 2, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 3, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 4, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
  { id: 5, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 6, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 7, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 8, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
  { id: 9, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 10, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 11, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 12, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
]

export const tabs = [
  {
    id: 2,
    text: 'NFTs',
  },
  {
    id: 3,
    text: 'Cards',
  },
  {
    id: 5,
    text: 'Auction',
  },
]

export const wallets = [
  {
    id: 1,
    image: botIcon,
    checked: true,
    edit: true,
  },
  {
    id: 2,
    name: 'Metamask (connected)',
    image: metamaskIcon,
    active: true,
    edit: false,
  },
]

export const tabsTable = [
  {
    id: 1,
    text: 'Purchase history',
  },
  {
    id: 2,
    text: 'Sales history',
  },
]

export const tableTitles = [
  {
    id: 1,
    text: '#',
  },
  {
    id: 2,
    text: 'Item name',
  },
  {
    id: 3,
    text: 'Race/element',
  },
  {
    id: 4,
    text: 'Cost',
  },
  {
    id: 5,
    text: 'Seller',
  },
  {
    id: 6,
    text: 'Purchase date',
  },
]

export const tableRows = [
  {
    id: 1,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 2,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 3,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 4,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 5,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 6,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 7,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 8,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
]

export const tabsMyNftCards = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'Race1',
  },
  {
    id: 3,
    text: 'Race2',
  },
  {
    id: 4,
    text: 'Race3',
  },
  {
    id: 5,
    text: 'Race4',
  },
  {
    id: 6,
    text: 'Race5',
  },
  {
    id: 7,
    text: 'Race6',
  },
]

export const tabsNftCardsItems = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
  { id: 5, image: nftCard1Img },
  { id: 6, image: nftCard2Img },
  { id: 7, image: nftCard1Img },
  { id: 8, image: nftCard2Img },
]
