import { useEffect } from 'react'
import css from './NftEditCardPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Components
import PrevPage from 'components/PrevPage'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
import MintNftForm from 'components/Forms/MintNftForm'
// Images
import duckImg from 'images/duck.png'
// Helmet
import CustomHelmet from './Helmet'
// Store
import { getNftHandler } from 'store/nft'

const NftEditCardPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const nft = useSelector((state) => state.nft.nft)

  useEffect(() => {
    dispatch(getNftHandler(id))
  }, [dispatch, id])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <PrevPage
          href={'/nft/' + nft._id}
          image={process.env.REACT_APP_IMAGE_URL + nft.file}
          className={css.prevpage}
        >
          Back to the NFT
        </PrevPage>
        <div className={css.wrapper}>
          <div className={css.content}>
            <div className={css.avatar}>
              <img src={duckImg} alt="duck" />
            </div>
            <Title className={css.title} type="h2">
              Edit {nft.name}
            </Title>
            <MintNftForm data={nft} typeForm="EDIT" />
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default NftEditCardPage
