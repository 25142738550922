import { useCallback, useMemo, useRef, useState } from 'react'
import css from './DeckPage.module.scss'
import Slider from 'react-slick'
// Components
import CatalogItem from 'components/Items/CatalogItem'
import PrevPage from 'components/PrevPage'
import CostItem from 'components/Items/CostItem'
import RecommendedSlider from 'components/Slider/RecommendedSlider'
import ArrowRight from 'components/Slider/ArrowRight/ArrowRight'
import ArrowLeft from 'components/Slider/ArrowLeft/ArrowLeft'
import PaginationItem from 'components/Items/PaginationItem'
import SliderInfoItem from 'components/Items/SliderInfoItem'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Utils
import { recommendedCollections, sliderItems, sliderInfoItems } from './utils'
// Hooks
import useWindowSize from 'hooks/useWindowSize'
// Constants
import { BREAKPOINT_SM, BREAKPOINT_XS } from 'constants'
// Helmet
import CustomHelmet from './Helmet'

const DeckPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef()
  const { width: windowWidth } = useWindowSize()

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 2,
      slidesPerRow: 3,
      nextArrow: <ArrowRight />,
      prevArrow: <ArrowLeft />,
      beforeChange: (prev, next) => setCurrentSlide(next),
      responsive: [
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesPerRow: 2,
          },
        },
        {
          breakpoint: BREAKPOINT_XS,
          settings: {
            slidesPerRow: 1,
          },
        },
      ],
    }),
    []
  )

  const sliderContent = useMemo(
    () =>
      recommendedCollections?.map((item) => (
        <CatalogItem
          key={item.id}
          {...item}
          className={css.slider_recommended_item}
        />
      )),
    []
  )

  const paginationNum = useMemo(() => {
    const sliderLength = sliderItems.length
    const oneSlideItems =
      windowWidth <= BREAKPOINT_XS ? 2 : windowWidth <= BREAKPOINT_SM ? 4 : 6

    return Math.ceil(sliderLength / oneSlideItems)
  }, [windowWidth])

  const changeSlide = useCallback((index) => {
    sliderRef.current.slickGoTo(index)
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <PrevPage href="/" className={css.prevpage}>
          Back to the main page
        </PrevPage>
        <div className={css.section_top}>
          <div>
            <Title className={css.h2}>DeckAAA</Title>
            <div className={css.description}>
              A new collection of wars of forgotten China, heirs of forgotten
              wisdom.
            </div>
            <CostItem price="10.417009 ETH" className={css.cost} />
          </div>
        </div>
        <div className={css.slider}>
          <div className={css.slider_flex}>
            <Slider {...settings} ref={sliderRef} className={css.slider_wrap}>
              {sliderItems?.map((item) => (
                <div key={item.id}>
                  <div className={css.slider_item}>
                    <img
                      src={item.image}
                      alt="img"
                      className={css.slider_item_img}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            <div className={css.slider_info}>
              {sliderInfoItems?.map((item) => (
                <SliderInfoItem
                  key={item.id}
                  {...item}
                  className={css.slider_info_item}
                />
              ))}
            </div>
          </div>
          <div className={css.slider_dots}>
            {[...Array(paginationNum).keys()]?.map((item, index) => (
              <PaginationItem
                num={index + 1}
                active={currentSlide === index}
                onClick={() => changeSlide(index)}
                className={css.slider_dot}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={css.container}>
        <RecommendedSlider
          items={sliderContent}
          title="Recommended fit items"
          className={css.slider_recommended}
        />
      </div>
    </LayoutWithBg>
  )
}

export default DeckPage
