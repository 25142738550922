export function formatError(arr) {
  const obj = {}

  arr.forEach((item) => {
    obj[item.key] = item.message[0]
  })

  return obj
}

export const search = (items, term) => {
  if (term.length === 0) {
    return items
  }

  return items.filter((item) => {
    return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
  })
}

export function isEmptyObject(obj) {
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false
    }
  }
  return true
}

export function minMaxNumValidate(min, max, value) {
  return Math.max(min, Math.min(max, Number(value)))
}
