import css from './Layout.module.scss'
import PropTypes from 'prop-types'
// Components
import Footer from 'components/Footer'
import Header from 'components/Header'

const Layout = ({ children, isHasFooter = true }) => (
  <div className={css.bg}>
    <Header />
    <div className={css.wrapper_children}>{children}</div>
    {isHasFooter && (
      <div className={css.footer}>
        <div className={css.container}>
          <Footer />
        </div>
      </div>
    )}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHasFooter: PropTypes.bool,
}

export default Layout
