import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { CustomProvider } from 'rsuite'
// Components
import App from 'components/App'
import ScrollWrapper from 'components/ScrollWrapper'
// Global styles
import 'rsuite/dist/rsuite.min.css'
import 'reactjs-popup/dist/index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-multi-carousel/lib/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/reset.scss'
import 'styles/fonts.scss'
import 'styles/common.scss'
// i18n
import 'utils/i18n'
// Store
import { store } from 'store/index'
// Constants
import { history } from 'constants/history'
import { HistoryRouter } from 'constants/HistoryRouter'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <CustomProvider theme="dark">
    <Provider store={store}>
      <HistoryRouter history={history}>
        <ScrollWrapper>
          <App />
        </ScrollWrapper>
      </HistoryRouter>
    </Provider>
  </CustomProvider>
)
