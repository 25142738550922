import { $api } from '.'
import { ethereum } from 'constants'

export const registerRequest = ({ nickname, wallet, password }) => {
  return $api.post('/auth/registration', { nickname, wallet, password })
}

export const loginNicknameRequest = ({ nickname, password }) => {
  return $api.post('/auth/login-nickname', { nickname, password })
}

export const loginWalletRequest = ({ wallet, password }) => {
  return $api.post('/auth/login-wallet', { wallet, password })
}

export const connectMetamaskRequest = () => {
  return ethereum.request({
    method: 'eth_requestAccounts',
  })
}

export const loginMetamaskRequest = ({ message, account }) => {
  return ethereum.request({
    method: 'personal_sign',
    params: [message, account],
  })
}

export const loginPhantomRequest = () => {
  return window.solana.connect()
}
