import css from './HowPlayPage.module.scss'
// Components
import { Button, Title } from 'UI'
import StepItem from 'components/Items/StepItem'
import LayoutWithBg from 'components/LayoutWithBg'
// Utils
import { stepItems } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const HowPlayPage = () => {
  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.content}>
          <Title className={css.h1} type="h1">
            How to play
          </Title>
          <div className={css.actions}>
            <div className={css.actions_item}>Mint</div>
            <div className={css.actions_item}>Sell</div>
            <div className={css.actions_item}>Invite friends</div>
          </div>
          <div className={css.steps}>
            {stepItems?.map((item) => (
              <StepItem {...item} key={item.id} className={css.steps_item} />
            ))}
          </div>
          <div className={css.btn}>
            <Button>Rules of the game</Button>
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default HowPlayPage
