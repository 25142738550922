import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Images
import russiaIcon from 'icons/languages/russia.svg'
import unitedKingdomIcon from 'icons/languages/united-kingdom.svg'

const getIcon = (value) => {
  switch (value) {
    case 'ru':
      return russiaIcon
    case 'en':
      return unitedKingdomIcon
    default:
      break
  }
}

const useLanguage = () => {
  const { i18n } = useTranslation()
  const [activeLanguage, setActiveLanguage] = useState({})

  const languages = useMemo(() => {
    return i18n.store.options.whitelist.map((language, index) => ({
      id: index + 1,
      text: language,
      language: language,
      icon: getIcon(language),
    }))
  }, [i18n])

  const onChangeLanguage = useCallback(
    (value) => {
      i18n.changeLanguage(value.language)
      setActiveLanguage(value)
    },
    [i18n]
  )

  return [languages, activeLanguage, onChangeLanguage]
}

export default useLanguage
