import { useCallback, useRef } from 'react'
import css from './BannerImage.module.scss'
import cn from 'classnames'
// Components
import { Button } from 'UI'
// Images
import cloudIcon from 'icons/cloud.svg'
import closeIcon from 'icons/close.svg'

const BannerImage = ({ setImage, imageUrl, setImageUrl }) => {
  const inputRef = useRef(null)

  const clickButton = useCallback(() => {
    inputRef.current.click()
  }, [])

  const handleImageChange = useCallback(
    (event) => {
      const imgObj = event.target.files && event.target.files[0]

      if (!imgObj) {
        return
      }

      setImage(imgObj)
      setImageUrl(URL.createObjectURL(imgObj))
    },
    [setImage, setImageUrl]
  )

  const closeImage = useCallback(() => {
    setImage({})
    setImageUrl('')
  }, [setImage, setImageUrl])

  return (
    <div className={cn(css.logo, imageUrl && css.active)}>
      {imageUrl ? (
        <>
          <img src={imageUrl} alt="img" />
          <div className={css.logo_close} onClick={closeImage}>
            <img src={closeIcon} alt="close" />
          </div>
        </>
      ) : (
        <>
          <div className={css.logo_icon}>
            <img src={cloudIcon} alt="cloud" />
          </div>
          <div className={css.logo_title}>Banner image</div>
          <div className={css.logo_description}>
            This image will appear at the top of your collection page. Avoid
            including too much text in this banner image, as the dimensions
            change on different devices. 1400 x 350 recommended.
          </div>
          <Button className={css.logo_btn} onClick={clickButton}>
            Upload Image
          </Button>
        </>
      )}

      <input
        ref={inputRef}
        type="file"
        className={css.hide}
        onChange={handleImageChange}
        accept="image/*"
      />
    </div>
  )
}

export default BannerImage
