import { useLayoutEffect, useRef } from 'react'
import css from './AddLevelsModal.module.scss'
import PropTypes from 'prop-types'
import { FieldArray, Formik } from 'formik'
// Components
import LevelInput from 'components/LevelInput'
import { Popup, Button } from 'UI'

const AddLevelsModal = ({
  open,
  closeModal,
  openModal,
  addedItems,
  setFunc,
}) => {
  const formikRef = useRef()
  const initialLevelObj = { name: '', min: 3, max: 5 }
  const initialLevels = [initialLevelObj]

  const submit = (values) => {
    closeModal()
    const filteredArr = values.levels.filter((item) => item.name.trim() !== '')
    setFunc(filteredArr)
  }

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        formikRef.current.setFieldValue(
          'levels',
          addedItems.length === 0 ? initialLevels : addedItems
        )
      }, 0)
    }
  }, [open])

  return (
    <Popup
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title="Add Levels"
      description="Levels show up underneath your item, are clickable, and can be filtered in your collection's sidebar."
    >
      <Formik
        initialValues={{
          levels: initialLevels,
        }}
        onSubmit={submit}
        innerRef={formikRef}
      >
        {(props) => (
          <div>
            <FieldArray
              name="levels"
              render={(arrayHelpers) => (
                <div>
                  <div className={css.property_titles}>
                    <div className={css.property_title}>Name</div>
                    <div className={css.property_title}>Value</div>
                  </div>
                  {props.values.levels &&
                    props.values.levels.length > 0 &&
                    props.values.levels.map((property, index) => (
                      <LevelInput
                        name1={`levels.${index}.name`}
                        name2={`levels.${index}.min`}
                        name3={`levels.${index}.max`}
                        close={() => arrayHelpers.remove(index)}
                        className={css.property_item}
                        key={index}
                        max={props.values.levels[index].max}
                        onChange={props.setFieldValue}
                      />
                    ))}
                  <Button
                    variant="blue"
                    className={css.property_add}
                    onClick={() => arrayHelpers.push(initialLevelObj)}
                  >
                    Add more
                  </Button>
                </div>
              )}
            />
          </div>
        )}
      </Formik>
      <Button
        className={css.popup_btn}
        onClick={() => formikRef.current.handleSubmit()}
      >
        Save
      </Button>
    </Popup>
  )
}

AddLevelsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  addedItems: PropTypes.array.isRequired,
  setFunc: PropTypes.func.isRequired,
}

export default AddLevelsModal
