import { useEffect } from 'react'
import css from './Snackbar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
// Types
import { snackbarClose } from 'store/snackbar'

const Snackbar = ({ timeout }) => {
  const dispatch = useDispatch()
  const SHOW = useSelector((state) => state.snackbar.toggleSnackbar)
  const MESSAGE = useSelector((state) => state.snackbar.snackbarMessage)
  const IS_ERROR = useSelector((state) => state.snackbar.isError)

  let TIMER
  function handleTimeout() {
    TIMER = setTimeout(() => {
      dispatch(snackbarClose())
    }, timeout)
  }

  // function handleClose() {
  //   clearTimeout(TIMER)
  //   dispatch(snackbarClose())
  // }

  useEffect(() => {
    if (SHOW) {
      handleTimeout()
    }
    return () => {
      clearTimeout(TIMER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SHOW, TIMER])

  return (
    <>
      {SHOW && (
        <div className={cn(css.snackbar, IS_ERROR && css.snackbar_error)}>
          <p>{MESSAGE}</p>
        </div>
      )}
    </>
  )
}

Snackbar.propTypes = {
  timeout: PropTypes.number,
}

Snackbar.defaultProps = {
  timeout: 3000,
}

export default Snackbar
