import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './ProgressBar.module.scss'

const ProgressBar = ({ min, max, className }) => {
  const widthCalc = (min / max) * 100
  const width = widthCalc >= 100 ? 100 : widthCalc

  return (
    <div className={cn(css.item, className)}>
      <div className={css.item_progress} style={{ width: `${width}%` }}></div>
    </div>
  )
}

ProgressBar.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default ProgressBar
