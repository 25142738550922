import css from './CollectionDetailPage.module.scss'
// Components
import CollectionDetail from 'components/CollectionDetail'
import LayoutWithBg from 'components/LayoutWithBg'
// Helmet
import CustomHelmet from './Helmet'

const CollectionDetailPage = () => {
  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <CollectionDetail />
      </div>
    </LayoutWithBg>
  )
}

export default CollectionDetailPage
