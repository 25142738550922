import css from './Backdrop.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'

export default function Backdrop({ onClick, active, className }) {
  return (
    active && <div className={cn(css.backdrop, className)} onClick={onClick} />
  )
}

Backdrop.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
}
