import css from './Datepicker.module.scss'
import { DatePicker } from 'rsuite'
import { isBefore } from 'rsuite/esm/utils/dateUtils'

const Datepicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div className={css.datepicker}>
      <div className={css.input}>
        <div className={css.input_top}>
          <div className={css.input_name}>Duration (start)</div>
        </div>
        <DatePicker
          format="dd-MM-yyyy HH:mm:ss"
          className={css.datepicker_input}
          placeholder="Select Date Range"
          value={startDate}
          onChange={setStartDate}
          disabledDate={date => isBefore(date, endDate)}
        />
      </div>
      <div className={css.input}>
        <div className={css.input_top}>
          <div className={css.input_name}>Duration (end)</div>
        </div>
        <DatePicker
          format="dd-MM-yyyy HH:mm:ss"
          className={css.datepicker_input}
          placeholder="Select Date Range"
          value={endDate}
          onChange={setEndDate}
          disabled={startDate === null}
          disabledDate={date => isBefore(date, startDate)}
        />
      </div>
    </div>
  )
}

export default Datepicker
