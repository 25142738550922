import homeIcon from 'icons/home.svg'
import catalogIcon from 'icons/catalog.svg'
import moreIcon from 'icons/more.svg'
import gamesIcon from 'icons/games.svg'
import createIcon from 'icons/create.svg'
import authIcon from 'icons/auth.svg'
import profileIcon from 'icons/profile.svg'
import collectionIcon from 'icons/collection.svg'

export const menuSidebar = [
  { id: 1, text: 'Home', href: '/', icon: homeIcon },
  { id: 2, text: 'Catalog', href: '/catalog', icon: catalogIcon },
  { id: 3, text: 'Create', href: '/nft/create', icon: createIcon },
  {
    id: 4,
    text: 'Create Collection',
    href: '/collection/create',
    icon: collectionIcon,
  },
  { id: 5, text: 'Profile', href: '/profile', icon: profileIcon },
  {
    id: 6,
    text: 'Games',
    icon: gamesIcon,
    list: [
      { id: 1, text: 'Game Rules', href: '/games/rules' },
      { id: 2, text: 'How To Play', href: '/games/how-to-play' },
      { id: 3, text: 'Best Players', href: '/games/best-players' },
    ],
    listPathname: '/games',
  },
  {
    id: 7,
    text: 'Auth',
    icon: authIcon,
    list: [
      { id: 1, text: 'Sign In', href: '/auth/sign-in' },
      { id: 2, text: 'Sign Up', href: '/auth/sign-up' },
    ],
    listPathname: '/auth',
  },
  {
    id: 8,
    text: 'More',
    icon: moreIcon,
    list: [
      { id: 1, text: 'Privacy Policy', href: '/privacy-policy' },
      { id: 2, text: 'F.A.Q', href: '/faq' },
    ],
  },
  {
    id: 9,
    text: 'Test',
    icon: moreIcon,
    list: [
      { id: 1, text: 'Nft card', href: '/nft/1' },
      { id: 2, text: 'Nft card edit', href: '/nft/1/edit' },
      { id: 3, text: 'Nft card sale', href: '/nft/1/sale' },
      { id: 4, text: 'Nft item', href: '/catalog-card-item/1' },
      { id: 5, text: 'Deck', href: '/deck' },
      { id: 6, text: 'Existing Cards', href: '/existing-cards' },
      { id: 7, text: 'Collection', href: '/collection/1' },
      { id: 8, text: 'Collection activity', href: '/collection/1/activity' },
      { id: 9, text: 'Collection edit', href: '/collection/1/edit' },
    ],
  },
]
