import { useMemo } from 'react'
import css from './BestPlayersPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// Components
import TabItem from 'components/Items/TabItem'
import { Table, Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import arrowIcon from 'icons/arrow.svg'
// Utils
import { tabsTable, tableTitles, tableRows } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const BestPlayersPage = () => {
  const tableTitlesContent = useMemo(
    () => (
      <div className={css.table_titles}>
        {tableTitles?.map((item) => (
          <div className={css.table_title} key={item.id}>
            {item.text}
            <img src={arrowIcon} alt="nft" className={css.table_img} />
          </div>
        ))}
      </div>
    ),
    []
  )

  const tableRowsContent = useMemo(
    () => (
      <div className={css.table_rows}>
        {tableRows?.map((item) => (
          <div className={css.table_row} key={item.id}>
            <div>{item.id}</div>
            <div>{item.name}</div>
            <div>{item.race}</div>
            <div>{item.cost}</div>
            <div>{item.seller}</div>
            <div>{item.date}</div>
          </div>
        ))}
      </div>
    ),
    []
  )

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.content}>
          <Title className={css.h1}>best players</Title>
          <div className={css.text}>
            A huge number of players and NFT collectors on our platform,
            presented in the rating page below
          </div>
          <Tabs className={css.tabs}>
            <TabList className={css.tabs_tablist}>
              <div className={css.tabs_tablist_wrap}>
                {tabsTable?.map((item) => (
                  <Tab
                    key={item.id}
                    selectedClassName="tab-active"
                    className="tab"
                  >
                    <TabItem {...item} className="tab-item-active" />
                  </Tab>
                ))}
              </div>
            </TabList>
            <TabPanel>
              <Table
                titles={tableTitlesContent}
                rows={tableRowsContent}
                className={css.table}
              />
            </TabPanel>
            <TabPanel>
              <Table
                titles={tableTitlesContent}
                rows={tableRowsContent}
                className={css.table}
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default BestPlayersPage
