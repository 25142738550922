import css from './TableItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const TableItem = ({ className, name, subtitle, price, status, avatar }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_left}>
      <img src={avatar} alt={name} className={css.item_img} />
      <div className={css.item_info}>
        {subtitle && <div className={css.item_subtitle}>{subtitle}</div>}
        <div className={css.item_name}>{name}</div>
      </div>
    </div>
    {price && status && (
      <div className={css.item_right}>
        <div className={css.item_price}>{price}</div>
        <div className={css.item_status}>{status}</div>
      </div>
    )}
  </div>
)

TableItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
  status: PropTypes.string,
  avatar: PropTypes.string,
}

export default TableItem
