import css from './CreateCollectionPage.module.scss'
import cn from 'classnames'
// Components
import CreateNftCollectionForm from 'components/Forms/CreateNftCollectionForm'
import { Title } from 'UI'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import duckImg from 'images/duck.png'
// Helmet
import CustomHelmet from './Helmet'

const CreateCollectionPage = () => {
  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.wrapper}>
          <div className={css.content}>
            <div className={css.avatar_wrap}>
              <div className={cn(css.avatar, css.avatar_left)}>
                <img src={duckImg} alt="duck1" />
              </div>
              <div className={css.avatar}>
                <img src={duckImg} alt="duck2" />
              </div>
              <div className={cn(css.avatar, css.avatar_right)}>
                <img src={duckImg} alt="duck3" />
              </div>
            </div>
            <Title className={css.title} type="h2">
              Create a Collection
            </Title>
            <CreateNftCollectionForm typeForm="CREATE" />
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default CreateCollectionPage
