import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'

export const nftItems = [
  {
    id: 1,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around1',
  },
  {
    id: 2,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around2',
  },
  {
    id: 3,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around3',
  },
  {
    id: 4,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around4',
  },
  {
    id: 5,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around5',
  },
  {
    id: 6,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around6',
  },
  {
    id: 7,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around7',
  },
  {
    id: 8,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around8',
  },
  {
    id: 9,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around9',
  },
  {
    id: 10,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around1',
  },
  {
    id: 11,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around2',
  },
  {
    id: 12,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around3',
  },
  {
    id: 13,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around4',
  },
  {
    id: 14,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around5',
  },
  {
    id: 15,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around6',
  },
  {
    id: 16,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around7',
  },
  {
    id: 17,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around8',
  },
  {
    id: 18,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
    description:
      'Classic magic spell. Having uttered this spell, the magician creates a ball of blazing flame and throws it; upon hitting the target, the ball explodes, setting fire to everything around9',
  },
]

export const tabs = [
  {
    id: 1,
    text: 'NFT Item',
  },
  {
    id: 2,
    text: 'NFT Card',
  },
]

export const currencies = [
  { id: 1, text: 'ETH' },
  { id: 2, text: 'NFT' },
]
