import { useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import css from './ProfileSettings.module.scss'
import { Formik } from 'formik'
// Components
import { Input, Button, Title } from 'UI'
import WalletItem from 'components/Items/WalletItem'
// Images
import duckImg from 'images/duck.png'
import editIcon from 'icons/edit.svg'
// Store
import {
  editUserNicknameHandler,
  editUserEmailHandler,
  editUserPasswordHandler,
} from 'store/auth'
import { socials } from './utils'

const ProfileSettings = () => {
  const dispatch = useDispatch()
  const inputImageRef = useRef()
  const profile = useSelector((state) => state.auth.profile)
  const errorsEditNickname = useSelector(
    (state) => state.auth.errorsEditNickname
  )
  const errorsEditEmail = useSelector((state) => state.auth.errorsEditEmail)
  const errorsEditPassword = useSelector(
    (state) => state.auth.errorsEditPassword
  )
  const nicknameFormikRef = useRef()
  const emailFormikRef = useRef()
  const passwordFormikRef = useRef()

  const submitNickname = useCallback(
    (values) => {
      dispatch(editUserNicknameHandler(values.nickname))
    },
    [dispatch]
  )

  const submitEmail = useCallback(
    (values) => {
      dispatch(editUserEmailHandler(values.email))
    },
    [dispatch]
  )

  const submitPassword = useCallback(
    (values) => {
      dispatch(editUserPasswordHandler(values))
    },
    [dispatch]
  )

  const selectImageHandler = useCallback((e) => {
    if (e.target.files) {
      console.log(e.target.files)
    }
  }, [])

  const buttonImageClick = useCallback(() => {
    inputImageRef.current.click()
  }, [])

  useEffect(() => {
    if (nicknameFormikRef.current) {
      nicknameFormikRef.current.setErrors(errorsEditNickname)
    }
  }, [errorsEditNickname])

  useEffect(() => {
    if (emailFormikRef.current) {
      emailFormikRef.current.setErrors(errorsEditEmail)
    }
  }, [errorsEditEmail])

  useEffect(() => {
    if (passwordFormikRef.current) {
      passwordFormikRef.current.setErrors(errorsEditPassword)
    }
  }, [errorsEditPassword])

  return (
    <div className={css.settings}>
      <div className={css.settings_avatar}>
        <img src={duckImg} alt="duck" />
      </div>
      <Button className={css.settings_upload} onClick={buttonImageClick}>
        Upload Image
      </Button>
      <input
        type="file"
        onChange={selectImageHandler}
        className={css.hidden}
        accept="image/*"
        ref={inputImageRef}
      />
       <Formik
        initialValues={{ wallet: profile.email ? profile.email : '' }}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_name}>Wallet Address</div>
            <Input
              name="wallet"
              type="text"
              className={css.form_input}
              classNameInput={css.form_input_wrap}
              disabled
            />
          </form>
        )}
      </Formik>
      <Formik
        initialValues={{ nickname: profile.nickname }}
        onSubmit={(values) => submitNickname(values)}
        innerRef={nicknameFormikRef}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_name}>Nickname</div>
            <Input
              name="nickname"
              type="text"
              className={css.form_input}
              classNameInput={css.form_input_wrap}
              rightIcon={
                <button className={css.form_input_icon} type="submit">
                  <img src={editIcon} alt="edit" />
                </button>
              }
            />
          </form>
        )}
      </Formik>
      <Formik
        initialValues={{ email: profile.email ? profile.email : '' }}
        onSubmit={(values) => submitEmail(values)}
        innerRef={emailFormikRef}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_name}>Email</div>
            <Input
              name="email"
              type="text"
              className={css.form_input}
              classNameInput={css.form_input_wrap}
              rightIcon={
                <button className={css.form_input_icon} type="submit">
                  <img src={editIcon} alt="edit" />
                </button>
              }
            />
          </form>
        )}
      </Formik>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '' }}
        onSubmit={(values) => submitPassword(values)}
        innerRef={passwordFormikRef}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_name}>Change Password</div>
            <Input
              name="oldPassword"
              type="text"
              className={css.form_input}
              classNameInput={css.form_input_wrap}
              placeholder="Old Password"
            />
            <Input
              name="newPassword"
              type="text"
              className={css.form_input}
              classNameInput={css.form_input_wrap}
              placeholder="New Password"
            />
            <Button type="submit" className={css.form_btn}>
              Change Password
            </Button>
          </form>
        )}
      </Formik>
      <div className={css.wallets}>
        <Title className={css.wallets_title} type="h3">
          Socials
        </Title>
        <div className={css.wallets_items}>
          {socials?.map((item) => (
            <WalletItem
              {...item}
              key={item.id}
              className={css.wallets_item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProfileSettings
