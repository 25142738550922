// Images
import editIcon from 'icons/edit.svg'
import dollarIcon from 'icons/dollar.svg'
import plusIcon from 'icons/plus.svg'
import reportIcon from 'icons/report.svg'

export const data = [
  { id: 1, name: 'Edit', icon: editIcon },
  { id: 2, name: 'Creator earnings', icon: dollarIcon },
  { id: 3, name: 'Add item', icon: plusIcon },
  { id: 4, name: 'Report', icon: reportIcon },
]
