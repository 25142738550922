import css from './LayoutWithBg.module.scss'
import PropTypes from 'prop-types'
// Images
import mainBgImg from 'images/main-bg.png'

const LayoutWithBg = ({ children }) => (
  <div>
    {children}
    <img className={css.bg} src={mainBgImg} alt="bg" />
  </div>
)

LayoutWithBg.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithBg
