import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './PrevPage.module.scss'
// Components
import { Link } from 'UI'
// Images
import arrowIcon from 'icons/arrow.svg'

const PrevPage = ({ children, className, href, image }) => (
  <Link href={href} className={cn(css.prevpage, className)}>
    <div className={css.prevpage_wrap}>
      <div className={css.prevpage_icon}>
        <img src={arrowIcon} alt="arrow" />
      </div>
      {image && (
        <div className={css.prevpage_image}>
          <img src={image} alt="img" />
        </div>
      )}
      <div className={css.prevpage_text}>{children}</div>
    </div>
  </Link>
)

PrevPage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
}

export default PrevPage
