import { takeEvery, call, put, select } from 'redux-saga/effects'
import {
  createNftRequest,
  getNftByIdRequest,
  editNftRequest,
  getAllCollectionsRequest,
  getAllCardsRequest,
  removeNftRequest,
  getHistoryNftByIdRequest,
} from 'api/nft'
import {
  cardsSuccess,
  collectionsHandler,
  collectionsSuccess,
  createNftHandler,
  editNftHandler,
  getNftHandler,
  getNftSuccess,
  modalCreateNftModalHandler,
  removeNftHandler,
  modalRemoveNftHandler,
  getHistoryNftSuccess,
} from './nft'
import { loadingHandlerCompleted, loadingHandlerUncompleted } from '../global'
import { snackbarOpen } from '../snackbar'
import { history } from 'constants/history'

function* workCollections() {
  yield put(loadingHandlerUncompleted())

  try {
    const responseCollections = yield call(getAllCollectionsRequest)
    const responseCards = yield call(getAllCardsRequest)
    yield put(collectionsSuccess(responseCollections.data.slice(0, 32)))
    yield put(cardsSuccess(responseCards.data.slice(0, 56)))
  } catch (e) {
  } finally {
    yield put(loadingHandlerCompleted())
  }
}

function* workCreateNft({ payload }) {
  try {
    const { data } = yield call(createNftRequest, payload)
    yield put(getNftSuccess(data.data))
    yield put(modalCreateNftModalHandler(true))
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

function* workNft({ payload }) {
  try {
    const { data } = yield call(getNftByIdRequest, payload)
    const { data: dataHistory } = yield call(getHistoryNftByIdRequest, payload)

    yield put(getHistoryNftSuccess(dataHistory.data))
    yield put(getNftSuccess(data.data))
  } catch (e) {}
}

function* workEditNft({ payload }) {
  try {
    const { data } = yield call(editNftRequest, payload)
    yield put(getNftSuccess(data.data))
    history.push('/nft/' + data.data._id)
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

function* workRemoveNft() {
  try {
    const nft = yield select((state) => state.nft.nft)
    const { data } = yield call(removeNftRequest, nft._id)
    yield put(modalRemoveNftHandler(false))
    history.push('/profile')
    yield put(snackbarOpen({ message: data.data, isError: false }))
  } catch (e) {
    yield put(snackbarOpen({ message: 'Error', isError: true }))
  }
}

export default function* nftSagaWatcher() {
  yield takeEvery(collectionsHandler.type, workCollections)
  yield takeEvery(createNftHandler.type, workCreateNft)
  yield takeEvery(getNftHandler.type, workNft)
  yield takeEvery(editNftHandler.type, workEditNft)
  yield takeEvery(removeNftHandler.type, workRemoveNft)
}
