import css from './NftBoxForm.module.scss'
import { Formik } from 'formik'
import { DatePicker as RsuiteDatepicker, DateRangePicker } from 'rsuite'
// Utils
import { priceOptions } from './utils'
// Components
import { Tooltip, Button, Select, Input } from 'UI'
import TooltipQuestion from 'components/TooltipQuestion'
import Datepicker from '../TimedAuctionForm/Datepicker/Datepicker'

const { beforeToday } = DateRangePicker

const NftBoxForm = () => {
  const onSubmit = (values) => {
    console.log(values)
  }

  return (
    <div className={css.wrapper}>
      <Formik
        initialValues={{
          price: '',
          priceCurrency: priceOptions[0],
        }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>Price</div>
                <TooltipQuestion id="price" />
              </div>
              <div className={css.input_price}>
                <Select
                  options={priceOptions}
                  placeholder=""
                  name="priceCurrency"
                  onChange={props.setFieldValue}
                  className={css.select_price}
                />
                <Input
                  name="price"
                  placeholder="Amount"
                  className={css.input_wrap}
                />
              </div>
            </div>
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>
                  Start date and end date of the auction
                </div>
              </div>
              <Datepicker />
            </div>
            <div className={css.input}>
              <div className={css.input_top}>
                <div className={css.input_name}>Chest opening date</div>
              </div>
              <div className={css.datepicker}>
                <RsuiteDatepicker
                  format="dd-MM-yyyy"
                  className={css.datepicker_input}
                  placeholder="Select Date"
                  disabledDate={beforeToday()}
                />
              </div>
            </div>
            <div className={css.info}>
              <div className={css.info_item}>
                <span className={css.info_subtitle}>Fees</span>
                <TooltipQuestion id="fees" />
              </div>
              <div className={css.info_item}>
                <span>Service Fee</span>
                <span>2.5%</span>
              </div>
              <div className={css.info_item}>
                <span>Creator Fee</span>
                <span>1%</span>
              </div>
            </div>
            <Button type="submit">Complete listing</Button>
          </form>
        )}
      </Formik>
      <Tooltip id="price">
        List price and listing schedule cannot be edited once the item is
        listed. You will need to cancel your listing and relist the item with
        the updated price and dates.
      </Tooltip>
      <Tooltip id="fees">
        Listing is free. Once sold, the following fees will be deducted. Learn
        more
      </Tooltip>
    </div>
  )
}

export default NftBoxForm
