import coinsIcon from 'images/coins.svg'
import cardsIcon from 'images/cards.svg'
import diceIcon from 'images/dice.svg'

export const stepItems = [
  {
    id: 1,
    name: 'Step 1',
    text: 'Register your account and buy NFT cards on our marketplace',
    btn: 'Buy Cards',
    image: coinsIcon,
  },
  {
    id: 2,
    name: 'Step 2',
    text: 'Create your deck, or use ready deck samples',
    image: cardsIcon,
  },
  {
    id: 3,
    name: 'Step 3',
    text: 'Start game',
    image: diceIcon,
  },
]
