import css from './MainTabItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const MainTabItem = ({ className, text, active }) => (
  <div className={cn(css.item, active && css.item_active, className)}>
    <div className={css.item_text}>{text}</div>
  </div>
)

MainTabItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  active: PropTypes.bool,
}

export default MainTabItem
