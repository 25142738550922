import css from './BlockchainItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Images
import closeIcon from 'icons/close.svg'

const BlockchainItem = ({
  className,
  name,
  icon,
  currency,
  disabled,
  close,
}) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_icon}>
      <img src={icon} alt={name} />
    </div>
    <div className={css.item_wrapper}>
      <div className={css.item_name}>{name}</div>
      <div className={css.item_currency}>{currency}</div>
    </div>
    {!disabled && (
      <div onClick={close} className={css.item_close}>
        <img src={closeIcon} alt="close" />
      </div>
    )}
  </div>
)

BlockchainItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  close: PropTypes.func,
}

export default BlockchainItem
