import { createSlice } from '@reduxjs/toolkit'

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    toggleSnackbar: false,
    snackbarMessage: null,
    isError: false,
  },
  reducers: {
    snackbarOpen: (state, action) => {
      return {
        ...state,
        toggleSnackbar: true,
        snackbarMessage: action.payload.message,
        isError: action.payload.isError,
      }
    },
    snackbarClose: (state) => {
      return {
        ...state,
        toggleSnackbar: false,
        snackbarMessage: null,
        isError: false,
      }
    },
  },
})

export const { snackbarOpen, snackbarClose } = snackbarSlice.actions

export default snackbarSlice.reducer
