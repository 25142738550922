import { useCallback } from 'react'
import css from './GameRulesPage.module.scss'
import cn from 'classnames'
// Components
import ExistingTabItem from 'components/Items/ExistingTabItem'
import { Button, Title } from 'UI'
import StatusItem from 'components/Items/StatusItem'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import phoneImg from 'images/phone.png'
import commonImg from 'images/cards/common.svg'
// Utils
import { cardTypes, cardTypes2, statusItems } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const GameRulesPage = () => {
  const scrollTopHandler = useCallback(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.grid}>
          <div className={css.content}>
            <Title className={css.h2} type="h2">
              Rules of the game
            </Title>
            <div className={css.description}>
              The gameplay is possible after the purchase and selection of a
              specific character by the player (NFT). A player who buys NFT
              automatically receives an account in the game and on the
              marketplace. Interaction in a duel occurs between cards, which can
              hit the opponent's cards or the opponent's NFT
            </div>
            <div className={css.info}>
              <div className={css.info_subtitle}>
                Cards have three types of characteristics:
              </div>
              <div className={css.info_items}>
                {cardTypes?.map((item) => (
                  <ExistingTabItem
                    {...item}
                    key={item.id}
                    className={css.info_item}
                    classNameImg={css.info_item_img}
                  />
                ))}
              </div>
            </div>
            <div className={cn(css.description, css.two)}>
              The gameplay is possible after the purchase and selection of a
              specific character by the player (NFT). A player who buys NFT
              automatically receives an account in the game and on the
              marketplace. Interaction in a duel occurs between cards, which can
              hit the opponent's cards or the opponent's NFT
            </div>
            <div className={css.info}>
              <div className={css.info_subtitle}>
                Cards have three types of characteristics:
              </div>
              <div className={css.info_items}>
                {cardTypes2?.map((item) => (
                  <ExistingTabItem
                    {...item}
                    key={item.id}
                    className={css.info_item}
                    classNameImg={css.info_item_img}
                  />
                ))}
              </div>
            </div>
            <div className={css.collections}>
              <div className={css.collection_items}>
                <div className={css.collection}>
                  <div className={css.collection_title}>
                    There are 48 normal cards
                  </div>
                  <div className={css.collection_img}>
                    <img src={commonImg} alt="collection" />
                  </div>
                  <Button>View collection</Button>
                </div>
                <div className={css.collection}>
                  <div className={css.collection_title}>
                    There are 48 normal cards
                  </div>
                  <div className={css.collection_img}>
                    <img src={commonImg} alt="collection" />
                  </div>
                  <Button>View collection</Button>
                </div>
              </div>
              <div className={css.collection_info}>
                <div className={css.collection_title}>
                  10 levels of experience
                </div>
                <div className={css.collection_text}>
                  10 levels of experience (pumping) are provided for NFT, the
                  next level is assigned for every 20 battles.
                </div>
              </div>
              <div className={css.collection_info}>
                <div className={css.collection_title}>available cards</div>
                <div className={css.collection_text}>
                  Of the 48 cards, 30 are available from the first level, then 2
                  are added for each level
                </div>
              </div>
            </div>
            <div className={css.status}>
              <div className={css.status_text}>
                Depending on the status (rarity) and the level of the NFT, his
                cards receive a bonus to damage, status options with bonuses for
                the level
              </div>
              <div className={css.status_items}>
                {statusItems?.map((item) => (
                  <StatusItem
                    {...item}
                    key={item.id}
                    className={css.status_item}
                  />
                ))}
              </div>
            </div>
            <div className={css.principles}>
              <div className={css.principles_title}>
                principle of racial domination
              </div>
              <div className={css.principles_text}>
                When calculating the effectiveness of an attack, the principle
                of racial domination is provided, according to which cards of a
                certain race receive less damage if the opponent is a
                representative of the dominant race:
              </div>
              <div className={css.principles_items}>
                <div className={css.principles_item}>1 dominates 2 and 4</div>
                <div className={css.principles_item}>2 dominates 3 and 5</div>
                <div className={css.principles_item}>3 dominates 4 and 1</div>
                <div className={css.principles_item}>4 dominates 5 and 2</div>
                <div className={css.principles_item}>5 dominates 1 and 3</div>
              </div>
            </div>
            <Button onClick={scrollTopHandler} className={css.up_btn}>
              Up
            </Button>
          </div>
          <div>
            <div className={css.phone}>
              <img src={phoneImg} alt="phone" />
              <div className={css.phone_description}>
                Click on the button to go to the Game
              </div>
              <Button>View collection</Button>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithBg>
  )
}

export default GameRulesPage
