import { createSlice } from '@reduxjs/toolkit'

export const nftSlice = createSlice({
  name: 'nft',
  initialState: {
    collections: [],
    cards: [],
    nft: {},
    isShowCreateNftModal: false,
    isShowRemoveNft: false,
    history: [],
  },
  reducers: {
    createNftHandler: () => {},
    editNftHandler: () => {},
    removeNftHandler: () => {},
    getHistoryNftSuccess: (state, action) => {
      return { ...state, history: action.payload }
    },
    getNftHandler: () => {},
    getNftSuccess: (state, action) => {
      return { ...state, nft: action.payload }
    },
    collectionsHandler: () => {},
    collectionsSuccess: (state, action) => {
      return { ...state, collections: action.payload }
    },
    cardsSuccess: (state, action) => {
      return { ...state, cards: action.payload }
    },
    modalCreateNftModalHandler: (state, action) => {
      return { ...state, isShowCreateNftModal: action.payload }
    },
    modalRemoveNftHandler: (state, action) => {
      return { ...state, isShowRemoveNft: action.payload }
    },
  },
})

export const {
  collectionsHandler,
  collectionsSuccess,
  cardsSuccess,
  createNftHandler,
  getNftHandler,
  getNftSuccess,
  modalCreateNftModalHandler,
  editNftHandler,
  modalRemoveNftHandler,
  removeNftHandler,
  getHistoryNftSuccess,
} = nftSlice.actions

export default nftSlice.reducer
