import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'BotMetamarket, the largest NFT marketplace'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="We’ve also got the same features for minting your NFT-items!"
      />
    </Helmet>
  )
}

export default CustomHelmet
