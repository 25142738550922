// Images
import binanceIcon from 'icons/crypto-logo/binance.svg'

export const blockchains = [
  {
    id: 1,
    label: 'Binance',
    description:
      'We operate the worlds biggest bitcoin exchange and altcoin crypto exchange in the world by volume',
    icon: binanceIcon,
    value: 'Binance',
  },
]
