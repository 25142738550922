import css from './CancelListingModal.module.scss'
import PropTypes from 'prop-types'
// Components
import { Popup, Button } from 'UI'

const CancelListingModal = ({ open, closeModal, openModal }) => {
  return (
    <Popup
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title="Cancel listing?"
      description="This will cancel your listing. You will also be asked to confirm this cancelation from your wallet."
    >
      <div className={css.btns}>
        <Button variant="blue" onClick={closeModal}>
          Go back
        </Button>
        <Button>Continue</Button>
      </div>
    </Popup>
  )
}

CancelListingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default CancelListingModal
