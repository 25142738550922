import css from './WalletItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Components
import { Radio, Button } from 'UI'
// Images
import editIcon from 'icons/edit.svg'

const WalletItem = ({
  className,
  name,
  image,
  active,
  nameRadio,
  checked,
  edit,
}) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_left}>
      {nameRadio && (
        <Radio
          className={css.item_radio}
          name={nameRadio}
          value={name}
          checked={checked}
        />
      )}
      <img src={image} alt={name} className={css.item_img} />
      <div className={css.item_name}>{name}</div>
    </div>
    {edit ? (
      <div className={css.item_edit}>
        <img src={editIcon} alt="edit" />
      </div>
    ) : (
      <Button variant={active ? 'dark' : 'blue'} className={css.item_btn}>
        {active ? 'Disconnect' : 'Connect'}
      </Button>
    )}
  </div>
)

WalletItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.node,
  active: PropTypes.bool,
  checked: PropTypes.bool,
  edit: PropTypes.bool,
}

export default WalletItem
