import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'User profile | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="This is your profile page where you can see and edit your personal data, connect wallet, check your NFTs, collections and much more: everything you need to organize your space on BOT Metamarket"
      />
    </Helmet>
  )
}

export default CustomHelmet
