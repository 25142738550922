import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Create collection | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Over here you can mint your NFT collection, and the process just got smoother than ever"
      />
    </Helmet>
  )
}

export default CustomHelmet
