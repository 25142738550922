import { useCallback, useMemo } from 'react'
import css from './Select.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { ErrorMessage, useField } from 'formik'

const Option = (props) => {
  const { label, description, icon } = props.data

  return (
    <components.Option
      {...props}
      className={cn(css.select_option, props.isSelected && css.active)}
    >
      {icon && (
        <div className={css.select_option_icon}>
          <img src={icon} alt="icon" />
        </div>
      )}
      <div className={css.select_option_inner}>
        <span>{label}</span>
        {description && (
          <div className={css.select_option_description}>{description}</div>
        )}
      </div>
    </components.Option>
  )
}

const LoadingIndicator = () => <div />

export default function SelectInput({
  className,
  classNameInput,
  label,
  options,
  onChangeInput,
  placeholder,
  onChange,
  onFocus,
  disabled = false,
  isSearchable = true,
  isLoading = false,
  customOnChange,
  value,
  defaultValue,
  ...props
}) {
  const [field, meta] = useField(props)

  const onChangeHandler = useCallback(
    (option) => {
      if (customOnChange) {
        customOnChange(option)
      } else {
        onChange(field.name, option)
      }
    },
    [onChange, field.name, customOnChange]
  )

  const onInputChange = useCallback((value) => {
    if (onChangeInput) {
      onChangeInput(value)
    }
  }, [])

  const onFocusHandler = useCallback(() => {
    if (onFocus) {
      onFocus()
    }
  }, [onFocus])

  const customStyles = useMemo(
    () => ({
      control: () => ({
        padding: 7,
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
      }),
      input: (styles) => ({ ...styles, color: 'white', fontSize: 13 }),
      menu: (styles) => ({
        ...styles,
        backgroundColor: 'rgba(10, 0, 44, 0.9)',
        padding: '20px 10px',
      }),
      singleValue: (styles, { data }) => ({
        ...styles,
        color: 'white',
        textAlign: 'left',
      }),
      placeholder: (styles, { data }) => ({
        ...styles,
        textAlign: 'left',
      }),
    }),
    []
  )

  return (
    <div className={cn(css.select, className)} disabled={disabled}>
      <Select
        id={field.name}
        instanceId={field.name}
        isDisabled={disabled}
        styles={customStyles}
        className={cn(
          css.select_field,
          meta.touched && meta.error && css.select_error,
          disabled && css.select_disabled,
          classNameInput
        )}
        options={options}
        name={field.name}
        value={value ? value : field.value}
        onChange={onChangeHandler}
        onBlur={field.onBlur}
        menuPlacement="auto"
        isSearchable={isSearchable}
        onInputChange={onInputChange}
        placeholder={placeholder}
        onFocus={onFocusHandler}
        noOptionsMessage={() => <div>No results</div>}
        components={{ Option, LoadingIndicator }}
        isLoading={false}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className={css.select_error_text}
      />
    </div>
  )
}

SelectInput.propTypes = {
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
}
