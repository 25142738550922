import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link as CustomLink } from 'react-router-dom'
import css from './Link.module.scss'

const Link = ({ href, children, className, ...props }) => (
  <CustomLink to={href} className={cn(css.link, className)} {...props}>
    {children}
  </CustomLink>
)

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Link
