import css from './DeleteCollectionModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
// Components
import { Popup, Button } from 'UI'
// Store
import {
  modalRemoveCollectionHandler,
  removeCollectionHandler,
  selectShowModalRemoveCollection,
} from 'store/collection'

const DeleteCollectionModal = () => {
  const dispatch = useDispatch()
  const isShowModalRemoveCollection = useSelector(
    selectShowModalRemoveCollection
  )

  const close = () => dispatch(modalRemoveCollectionHandler(false))

  return (
    <Popup
      open={isShowModalRemoveCollection}
      closeModal={close}
      openModal={() => dispatch(modalRemoveCollectionHandler(true))}
      title="Remove collection ?"
      description="Are you sure you want to remove the selected collection?"
    >
      <div className={css.btns}>
        <Button variant="blue" onClick={close}>
          Go back
        </Button>
        <Button onClick={() => dispatch(removeCollectionHandler())}>
          Remove
        </Button>
      </div>
    </Popup>
  )
}

export default DeleteCollectionModal
