import css from './ProgressItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Components
import { ProgressBar } from 'UI'

const ProgressItem = ({ className, name, min, max, withProgress = true }) => {
  return (
    <div className={cn(css.item, className)}>
      <div className={css.item_top}>
        <div className={css.item_name}>{name}</div>
        <div className={css.item_info}>
          {min} of {max}
        </div>
      </div>
      {withProgress && (
        <ProgressBar min={min} max={max} className={css.item_progress} />
      )}
    </div>
  )
}

ProgressItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  withProgress: PropTypes.bool,
}

export default ProgressItem
