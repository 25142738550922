import css from './TabItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const TabItem = ({ className, text, onClick, id }) => (
  <div
    className={cn(css.item, className)}
    onClick={onClick ? () => onClick(id) : null}
  >
    <div className={css.item_text}>{text}</div>
  </div>
)

TabItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.number,
}

export default TabItem
