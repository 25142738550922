import { all } from 'redux-saga/effects'
// Watchers
import authSagaWatcher from './auth/authSaga'
import catalogSagaWatcher from './catalog/catalogSaga'
import collectionSagaWatcher from './collection/collectionSaga'
import nftSagaWatcher from './nft/nftSaga'

export function* rootWatcher() {
  yield all([
    authSagaWatcher(),
    nftSagaWatcher(),
    catalogSagaWatcher(),
    collectionSagaWatcher(),
  ])
}
