import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Create collection | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="It is easy to create your own NFT collection now. Just scroll the page filling in the empty spaces and load your images!"
      />
    </Helmet>
  )
}

export default CustomHelmet
