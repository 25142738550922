import axios from 'axios'
import { getCookie } from 'utils/cookie'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config) => {
  config.headers.lang = localStorage.getItem('i18nextLng')
  return config
})

const $apiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

$apiWithToken.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getCookie('token')}`
  config.headers.lang = localStorage.getItem('i18nextLng')
  return config
})

export { $api, $apiWithToken }
