import commonImg from 'images/cards/common.svg'
import monolithImg from 'images/cards/monolith.png'
import corsairImg from 'images/cards/corsair.png'
import dragonsplicersImg from 'images/cards/dragonsplicers.png'
import hullsImg from 'images/cards/hulls.png'
import syntaxImg from 'images/cards/syntax.png'
import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'

export const tabs = [
  {
    id: 1,
    text: 'Common cards',
    image: commonImg,
  },
  {
    id: 2,
    text: 'Monolith Zardom cards',
    image: monolithImg,
  },
  {
    id: 3,
    text: 'Corsair Coalition cards',
    image: corsairImg,
  },
  {
    id: 4,
    text: 'Dragonsplicers cards',
    image: dragonsplicersImg,
  },
  {
    id: 5,
    text: 'Hulls cards',
    image: hullsImg,
  },
  {
    id: 6,
    text: 'The Order of Syntax cards',
    image: syntaxImg,
  },
]

export const items = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
  { id: 5, image: nftCard1Img },
  { id: 6, image: nftCard2Img },
  { id: 7, image: nftCard1Img },
  { id: 8, image: nftCard2Img },
]
