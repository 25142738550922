import { useMemo } from 'react'
import css from './ProfileAuctionDetailPage.module.scss'
// Images
import acceptCircleIcon from 'icons/accept-circle.svg'
import cancelCircleImg from 'icons/cancel-circle.svg'
// Utils
import { tableRows, tableTitles } from './utils'
// Components
import { Table, Button, Title } from 'UI'
import PrevPage from 'components/PrevPage'
import { PickUpBetModal } from 'components/Popups'
import LayoutWithBg from 'components/LayoutWithBg'
// Hooks
import useModal from 'hooks/useModal'
// Helmet
import CustomHelmet from './Helmet'

const ProfileAuctionDetailPage = () => {
  const [pickUpBetModal, pickUpBetModalOpen, pickUpBetModalClose] = useModal()

  const tableTitlesContent = useMemo(
    () => (
      <div className={css.table_titles}>
        {tableTitles?.map((item) => (
          <div className={css.table_title} key={item.id}>
            {item.text}
          </div>
        ))}
      </div>
    ),
    []
  )

  const tableRowsContent = useMemo(
    () => (
      <div className={css.table_rows}>
        {tableRows?.map((item) => (
          <div className={css.table_row} key={item.id}>
            <div>{item.id}</div>
            <div>{item.date}</div>
            <div>{item.cost}</div>
            <div>{item.author}</div>
            <div>
              <img
                src={item.status ? acceptCircleIcon : cancelCircleImg}
                alt="icon"
              />
            </div>
            <div>
              <Button onClick={pickUpBetModalOpen}>Pick up the bet</Button>
            </div>
          </div>
        ))}
      </div>
    ),
    [pickUpBetModalOpen]
  )

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <PrevPage href="/profile">Back to the profile</PrevPage>
        <div className={css.content}>
          <Title className={css.h1}>Auction Nft name</Title>
          <Table
            titles={tableTitlesContent}
            rows={tableRowsContent}
            className={css.table}
          />
        </div>
      </div>
      <PickUpBetModal
        open={pickUpBetModal}
        closeModal={pickUpBetModalClose}
        openModal={pickUpBetModalOpen}
      />
    </LayoutWithBg>
  )
}

export default ProfileAuctionDetailPage
