import { useState } from 'react'
import css from './SellNftForm.module.scss'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useSelector } from 'react-redux'
// Utils
import { sellTabs } from './utils'
// Components
import SellTabItem from 'components/Items/SellTabItem'
import TooltipQuestion from 'components/TooltipQuestion'
import { Tooltip } from 'UI'
import TimedAuctionForm from '../TimedAuctionForm'
import NftCollectionItem from 'components/Items/NftCollectionItem'
import FixedPriceForm from '../FixedPriceForm'
import NftBoxForm from '../NftBoxForm'

const SellNftForm = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const nft = useSelector((state) => state.nft.nft)

  return (
    <div className={css.wrapper}>
      <Tabs className={css.tabs} onSelect={setTabIndex}>
        <div className={css.tabs_top}>
          <div className={css.tabs_subtitle}>Type</div>
          <TooltipQuestion id="type" />
        </div>
        <TabList className={css.tabs_tablist}>
          {sellTabs?.map((item) => (
            <Tab key={item.id}>
              <SellTabItem {...item} active={tabIndex === item.value} />
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <FixedPriceForm />
        </TabPanel>
        <TabPanel>
          <TimedAuctionForm />
        </TabPanel>
        <TabPanel>
          <NftBoxForm />
        </TabPanel>
      </Tabs>
      <div className={css.nft}>
        <div>
          <NftCollectionItem
            image={process.env.REACT_APP_IMAGE_URL + nft.file}
            title={nft.name}
          />
        </div>
      </div>
      <Tooltip id="type">
        Learn more about the two types of listing options in our Help Center
      </Tooltip>
    </div>
  )
}

export default SellNftForm
