import { useState } from 'react'
import css from './InfoNft.module.scss'
import cn from 'classnames'
// Components
import { Tooltip, Link, Checkbox } from 'UI'
import TooltipQuestion from 'components/TooltipQuestion'
// Images
import reviewImg from 'images/review.webp'

const InfoNft = ({ checked, changeChecked }) => {
  const [isShowTable, setIsShowTable] = useState(false)

  const changeShowTable = () => {
    setIsShowTable(!isShowTable)
  }

  return (
    <div className={css.popup}>
      <img src={reviewImg} alt="review" className={css.popup_review} />
      <div className={css.popup_description}>
        Review this information to ensure it’s what you want to buy.{' '}
        <TooltipQuestion id="description" />
      </div>
      <div className={css.table}>
        <div className={css.table_item}>
          <span className={css.table_item_title}>Collection name</span>
          <div>
            <Link href="/">PirateBay</Link>
          </div>
        </div>
        <div className={css.table_item}>
          <span className={css.table_item_title}>Creator</span>
          <div>
            <Link href="/">4B709A</Link> (member since Aug 10, 2022)
          </div>
        </div>
        <div className={css.table_item}>
          <span className={css.table_item_title}>Total sales</span>
          <div>0 sales</div>
        </div>
        <div className={css.table_item}>
          <span className={css.table_item_title}>Total volume</span>
          <div>---</div>
        </div>
        {isShowTable ? (
          <>
            <div className={css.table_item}>
              <span className={css.table_item_title}>Contract address</span>
              <div>
                <Link href="/">0x2953...4963</Link>
              </div>
            </div>
            <div className={css.table_item}>
              <span className={css.table_item_title}>Total items</span>
              <div>2 items</div>
            </div>
            <div className={css.table_item}>
              <span className={css.table_item_title}>Created date</span>
              <div>a month ago</div>
            </div>
          </>
        ) : null}
        <div className={cn(css.table_item, css.show)} onClick={changeShowTable}>
          {isShowTable ? 'Show less' : 'Show more'}
        </div>
      </div>
      <Checkbox
        label="I understand that OpenSea has not reviewed this collection and blockchain transactions are irreversible."
        onChange={changeChecked}
        value={checked}
        id={1}
        className={css.checkbox}
      />
      <Tooltip id="description">
        Learn more about protecting yourself from scams here.
      </Tooltip>
    </div>
  )
}

export default InfoNft
