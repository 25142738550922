import PropTypes from 'prop-types'
import css from './AuthLayout.module.scss'
// Images
import planetImg from 'images/planet.png'
import planetCutImg from 'images/planet-cut.png'
import spaceStationImg from 'images/space-station.png'
// Components
import Layout from 'components/Layout'

const AuthLayout = ({ children }) => (
  <Layout isHasFooter={false}>
    <div className={css.wrap}>
      <div className={css.wrapper}>
        <div className={css.container}>
          <div className={css.content}>
            <div className={css.left}>
              <img src={spaceStationImg} alt="space-station" />
            </div>
            <div className={css.right}>
              <div className={css.planet_right}>
                <img src={planetImg} alt="planet" />
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className={css.planet}>
        <img src={planetCutImg} alt="planet" />
      </div>
    </div>
  </Layout>
)

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthLayout
