import { takeEvery, call, put, select } from 'redux-saga/effects'
import {
  registerRequest,
  connectMetamaskRequest,
  loginPhantomRequest,
  loginMetamaskRequest,
  loginNicknameRequest,
  loginWalletRequest,
} from 'api/auth'
import {
  registerHandler,
  registerError,
  loginHandler,
  loginError,
  loginHandlerMetamask,
  loginHandlerPhantom,
  loginSuccess,
  checkLoginHandler,
  logOut,
  editUserNicknameHandler,
  editUserEmailHandler,
  editErrorNickname,
  editErrorEmail,
  editUserPasswordHandler,
  editErrorPassword,
} from './auth'
import { snackbarOpen } from '../snackbar'
import { formatError } from 'utils'
import { ethereum } from 'constants'
import { nicknameRegExp } from 'utils/regExp'
import { setCookie, getCookie } from 'utils/cookie'
import {
  getUserRequest,
  editUserNicknameRequest,
  editUserEmailRequest,
  editUserPasswordRequest,
} from 'api/user'

function* workRegister({ payload }) {
  if (!payload.isUserAgreementChecked) {
    yield put(
      snackbarOpen({
        message: 'Примите пользовательское соглашение!',
        isError: true,
      })
    )
    return
  }

  if (!payload.isPrivacyPolicyChecked) {
    yield put(
      snackbarOpen({
        message: 'Примите политику конфиденциальности!',
        isError: true,
      })
    )
    return
  }

  try {
    const { data } = yield call(registerRequest, payload)
    yield put(snackbarOpen({ message: data.message }))
    payload.navigate('/auth/sign-in')
  } catch (e) {
    const error = e.response.data

    if (error.hasOwnProperty('errors')) {
      const errors = formatError(error.errors)
      yield put(registerError(errors))
    } else {
      yield put(snackbarOpen({ message: error.message, isError: true }))
    }
  }
}

function* workLogin({ payload }) {
  try {
    let values = {
      password: payload.password,
    }

    const isNicknameOrWallet = nicknameRegExp.test(payload.walletOrNickname)

    if (isNicknameOrWallet) {
      values.nickname = payload.walletOrNickname
    } else {
      values.wallet = payload.walletOrNickname
    }

    const { data } = yield call(
      isNicknameOrWallet ? loginNicknameRequest : loginWalletRequest,
      values
    )

    setCookie('token', data.token)
    setCookie('userId', data.user.id)
    yield put(loginSuccess(data.user))
    payload.navigate('/profile')
  } catch (e) {
    const error = e.response.data

    if (error.hasOwnProperty('errors')) {
      const errors = formatError(error.errors)
      errors.walletOrNickname = errors.nickname || errors.wallet
      yield put(loginError(errors))
    } else {
      yield put(snackbarOpen({ message: error.message, isError: true }))
    }
  }
}

function* workLoginMetamask({ payload }) {
  if (!ethereum) {
    yield put(
      snackbarOpen({ message: 'Please install metamask', isError: true })
    )
    return
  }

  try {
    const account = yield call(connectMetamaskRequest)
    const data = yield call(loginMetamaskRequest, {
      message: 'Welcome!',
      account: account[0],
    })
    console.log(account[0], ' WELCOME ' + data)
    yield put(loginSuccess())
    payload.navigate('/profile')
  } catch (e) {
    yield put(snackbarOpen({ message: 'Что-то пошло не так!', isError: true }))
  }
}

function* workLoginPhantom({ payload }) {
  try {
    if ('solana' in window) {
      yield call(loginPhantomRequest)

      const provider = window.solana

      if (provider.isPhantom) {
        yield put(loginSuccess())
        payload.navigate('/profile')
        console.log('key', provider.publicKey.toString())
      }
    } else {
      yield put(
        snackbarOpen({ message: 'Please install phantom', isError: true })
      )
      return
    }
  } catch (e) {
    yield put(snackbarOpen({ message: 'Что-то пошло не так!', isError: true }))
  }
}

function* workCheckLogin() {
  try {
    const id = getCookie('userId')
    const { data } = yield call(getUserRequest, id)
    yield put(loginSuccess(data))
  } catch (e) {
    yield put(logOut())
  }
}

function* workEditNicknameUser({ payload }) {
  try {
    const profile = yield select((state) => state.auth.profile)

    const values = {
      id: profile.id,
      nickname: payload,
    }
    const { data } = yield call(editUserNicknameRequest, values)
    yield put(snackbarOpen({ message: data.message }))
  } catch (e) {
    const error = e.response.data

    if (error.hasOwnProperty('errors')) {
      const errors = formatError(error.errors)
      yield put(editErrorNickname(errors))
    } else {
      yield put(snackbarOpen({ message: error.message, isError: true }))
    }
  }
}

function* workEditEmailUser({ payload }) {
  try {
    const profile = yield select((state) => state.auth.profile)

    const values = {
      id: profile.id,
      email: payload,
    }
    const { data } = yield call(editUserEmailRequest, values)
    yield put(snackbarOpen({ message: data.message }))
  } catch (e) {
    const error = e.response.data

    if (error.hasOwnProperty('errors')) {
      const errors = formatError(error.errors)
      yield put(editErrorEmail(errors))
    } else {
      yield put(snackbarOpen({ message: error.message, isError: true }))
    }
  }
}

function* workEditPasswordUser({ payload }) {
  try {
    const profile = yield select((state) => state.auth.profile)

    const values = {
      id: profile.id,
      ...payload,
    }
    const { data } = yield call(editUserPasswordRequest, values)
    yield put(snackbarOpen({ message: data.message }))
  } catch (e) {
    const error = e.response.data

    if (error.hasOwnProperty('errors')) {
      const errors = formatError(error.errors)
      yield put(editErrorPassword(errors))
    } else {
      yield put(snackbarOpen({ message: error.message, isError: true }))
    }
  }
}

export default function* authSagaWatcher() {
  yield takeEvery(registerHandler.type, workRegister)
  yield takeEvery(loginHandler.type, workLogin)
  yield takeEvery(loginHandlerMetamask.type, workLoginMetamask)
  yield takeEvery(loginHandlerPhantom.type, workLoginPhantom)
  yield takeEvery(checkLoginHandler.type, workCheckLogin)
  yield takeEvery(editUserNicknameHandler.type, workEditNicknameUser)
  yield takeEvery(editUserEmailHandler.type, workEditEmailUser)
  yield takeEvery(editUserPasswordHandler.type, workEditPasswordUser)
}
