import css from './NftCardItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useCallback, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const NftCardItem = ({ className, image, id }) => {
  const [isLoadingImg, setIsLoadingImg] = useState(true)

  const onLoadHandler = useCallback(() => {
    setIsLoadingImg(false)
  }, [])

  return (
    <div className={cn(css.item, className)}>
      <div className={cn(css.item_skeleton, !isLoadingImg && css.active)}>
        <SkeletonTheme
          baseColor="#050641"
          highlightColor="rgba(16, 24, 109, 0.5)"
        >
          <Skeleton height="100%" />
        </SkeletonTheme>
      </div>
      <LazyLoadImage
        alt={id}
        src={image}
        className={cn(css.item_img, !isLoadingImg && css.active)}
        effect="blur"
        afterLoad={onLoadHandler}
      />
    </div>
  )
}

NftCardItem.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
}

export default NftCardItem
