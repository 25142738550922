import cn from 'classnames'
import css from './Title.module.scss'

const Title = ({ type = 'h1', children, className }) => {
  const innerText = (
    <>
      <span>{children}</span>
      <span>{children}</span>
    </>
  )

  let Title
  switch (type) {
    case 'h1':
      Title = (
        <h1 className={cn(css.title, css[type], className)}>{innerText}</h1>
      )
      break
    case 'h2':
      Title = (
        <h2 className={cn(css.title, css[type], className)}>{innerText}</h2>
      )
      return Title
    case 'h3':
      Title = (
        <h3 className={cn(css.title, css[type], className)}>{innerText}</h3>
      )
      return Title
    default:
      Title = (
        <h2 className={cn(css.title, css[type], className)}>{innerText}</h2>
      )
      return Title
  }

  return Title
}

export default Title
