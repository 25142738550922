import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'
import nftCollection1Img from 'images/nft-collection1.png'
import nftCollection2Img from 'images/nft-collection2.png'
import nftCollection3Img from 'images/nft-collection3.png'
import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'

export const tabsCatalog = [
  {
    id: 1,
    text: 'NFTs',
  },
  {
    id: 2,
    text: 'Collections',
  },
]

export const tabsNftCards = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'Common',
  },
  {
    id: 3,
    text: 'Venus',
  },
  {
    id: 4,
    text: 'Mars',
  },
  {
    id: 5,
    text: 'Mercury',
  },
  {
    id: 6,
    text: 'Jupiter',
  },
  {
    id: 7,
    text: 'Saturn',
  },
]

export const filterDropdown = [
  { id: 1, text: 'Recent' },
  { id: 2, text: 'Last' },
]

export const items = [
  { id: 1, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 2, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 3, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 4, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
  { id: 5, name: 'Fireball', category: 'Common', image: catalog1Img },
  { id: 6, name: 'Moon-Lee', category: 'Common', image: catalog2Img },
  { id: 7, name: 'Fireball', category: 'Common', image: catalog3Img },
  { id: 8, name: 'Moon-Lee', category: 'Common', image: catalog1Img },
]

export const tabsNftCardsItems = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
  { id: 5, image: nftCard1Img },
  { id: 6, image: nftCard2Img },
  { id: 7, image: nftCard1Img },
  { id: 8, image: nftCard2Img },
]

export const independentNftsItems = [
  { id: 1, title: 'Aurora', price: '0.144 eth', image: catalog1Img },
  { id: 2, title: 'Kingdom come', price: '0.144 eth', image: catalog2Img },
  { id: 3, title: 'Home way', price: '0.144 eth', image: catalog3Img },
  { id: 4, title: 'Zu-zu road', price: '0.144 eth', image: nftCollection1Img },
  {
    id: 5,
    title: 'Hillway track',
    price: '0.144 eth',
    image: nftCollection2Img,
  },
  {
    id: 6,
    title: 'Loo-meens hat',
    price: '0.144 eth',
    image: nftCollection3Img,
  },
  { id: 7, title: 'Aurora', price: '0.144 eth', image: catalog1Img },
  { id: 8, title: 'Kingdom come', price: '0.144 eth', image: catalog2Img },
  { id: 9, title: 'Home way', price: '0.144 eth', image: catalog3Img },
  { id: 10, title: 'Zu-zu road', price: '0.144 eth', image: nftCollection1Img },
  {
    id: 11,
    title: 'Hillway track',
    price: '0.144 eth',
    image: nftCollection2Img,
  },
  {
    id: 12,
    title: 'Loo-meens hat',
    price: '0.144 eth',
    image: nftCollection3Img,
  },
  { id: 13, title: 'Aurora', price: '0.144 eth', image: catalog1Img },
  { id: 14, title: 'Kingdom come', price: '0.144 eth', image: catalog2Img },
  { id: 15, title: 'Home way', price: '0.144 eth', image: catalog3Img },
  { id: 16, title: 'Zu-zu road', price: '0.144 eth', image: nftCollection1Img },
  {
    id: 17,
    title: 'Hillway track',
    price: '0.144 eth',
    image: nftCollection2Img,
  },
  {
    id: 18,
    title: 'Loo-meens hat',
    price: '0.144 eth',
    image: nftCollection3Img,
  },
]
