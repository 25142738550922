import catalog1Img from 'images/catalog1.jpeg'
import catalog2Img from 'images/catalog2.jpeg'
import catalog3Img from 'images/catalog3.jpeg'

export const auctionNfts = [
  {
    id: 1,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
  },
  {
    id: 2,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
  },
  {
    id: 3,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
  },
  {
    id: 4,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
  },
  {
    id: 5,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
  },
  {
    id: 6,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
  },
  {
    id: 7,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
  },
  {
    id: 8,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
  },
  {
    id: 9,
    name: 'Fireball',
    category: 'Common',
    image: catalog1Img,
  },
  {
    id: 10,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog2Img,
  },
  {
    id: 11,
    name: 'Fireball',
    category: 'Common',
    image: catalog3Img,
  },
  {
    id: 12,
    name: 'Moon-Lee',
    category: 'Common',
    image: catalog1Img,
  },
]

export const tabsNftCards = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'Common',
  },
  {
    id: 3,
    text: 'Venus',
  },
  {
    id: 4,
    text: 'Mars',
  },
  {
    id: 5,
    text: 'Mercury',
  },
  {
    id: 6,
    text: 'Jupiter',
  },
  {
    id: 7,
    text: 'Saturn',
  },
]

export const categories = [
  { id: 1, label: 'Art' },
  { id: 2, label: 'Collectibles' },
  { id: 3, label: 'Music' },
  { id: 4, label: 'Photography' },
  { id: 5, label: 'Sports' },
  { id: 6, label: 'Trading Cards' },
  { id: 7, label: 'Utility' },
]
