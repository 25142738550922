import nftCard1Img from 'images/nft-card1.png'
import nftCard2Img from 'images/nft-card2.png'
import nftCollection1Img from 'images/nft-collection1.png'
import nftCollection2Img from 'images/nft-collection2.png'
import nftCollection3Img from 'images/nft-collection3.png'

export const recomendedItems = [
  { id: 1, image: nftCard1Img },
  { id: 2, image: nftCard2Img },
  { id: 3, image: nftCard1Img },
  { id: 4, image: nftCard2Img },
]

export const recommendedCollections = [
  {
    id: 1,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 2,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 3,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 4,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 5,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 6,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
  {
    id: 7,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection3Img,
  },
  {
    id: 8,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection1Img,
  },
  {
    id: 9,
    title: 'Future sight',
    price: '0.311412 eth',
    href: '/',
    image: nftCollection2Img,
  },
]
