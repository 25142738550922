import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'Auction | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Welcome back to BOT Metamarket - a perfect NFT marketplace to buy and sell NFT items, cards, collections to play Nexus Shift and much more!"
      />
    </Helmet>
  )
}

export default CustomHelmet
