import { useCallback, useEffect, useRef } from 'react'
import { Formik } from 'formik'
import css from './CreateNftCollectionForm.module.scss'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { Select, Tooltip, Input, Button } from 'UI'
import FeaturedImage from './FeaturedImage/FeaturedImage'
import BannerImage from './BannerImage/BannerImage'
import Links from './Links/Links'
import LogoImage from './LogoImage/LogoImage'
import CreatorEarnings from './CreatorEarnings/CreatorEarnings'
import Blockchain from './Blockchain/Blockchain'
import SensitiveContent from '../MintNftForm/SensitiveContent/SensitiveContent'
import PaymentTokens from './PaymentTokens/PaymentTokens'
import TooltipQuestion from 'components/TooltipQuestion'
import {
  DeleteCollectionModal,
  CreateCollectionSuccessModal,
} from 'components/Popups'
// Utils
import { categories, schema } from './utils'
import { isEmptyObject } from 'utils'
import { blockchains } from './Blockchain/utils'
// Store
import {
  createCollectionHandler,
  modalRemoveCollectionHandler,
  editCollectionHandler,
} from 'store/collection'
// Images
import logoImg from 'icons/wallets/bot-small.svg'

const defaultValues = {
  name: '',
  description: '',
  url: '',
  explicitAndSensitiveContent: false,
  blockchain: blockchains[0],
  globalLink: '',
  discord: '',
  instagram: '',
  telegram: '',
  medium: '',
  creatorEarnings: [],
  paymentTokens: [],
  category: '',
}

const CreateNftCollectionForm = ({ typeForm, data }) => {
  const formikRef = useRef()
  const dispatch = useDispatch()
  const [logoImage, setLogoImage] = useState({})
  const [logoImageUrl, setLogoImageUrl] = useState('')
  const [featuredImage, setFeaturedImage] = useState({})
  const [featuredImageUrl, setFeaturedImageUrl] = useState('')
  const [bannerImage, setBannerImage] = useState({})
  const [bannerImageUrl, setBannerImageUrl] = useState('')

  const submitHandler = useCallback(
    (values) => {
      const result = {
        ...values,
        logoImage,
        featuredImage,
        bannerImage,
        links: {
          site: values.globalLink,
          discord: values.discord,
          instagram: values.instagram,
          telegram: values.telegram,
          medium: values.medium,
        },
        category: values.category ? values.category.label : '',
        paymentTokens: values.paymentTokens.map((item) => item.value),
      }

      if (typeForm === 'CREATE') {
        dispatch(createCollectionHandler(result))
      }

      if (typeForm === 'EDIT') {
        dispatch(editCollectionHandler({ ...result, id: data._id }))
      }
    },
    [typeForm, logoImage, featuredImage, bannerImage, dispatch, data]
  )

  useEffect(() => {
    const formik = formikRef.current

    if (formik && typeForm === 'EDIT' && !isEmptyObject(data)) {
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('description', data.description)
      formik.setFieldValue('url', data.url)
      formik.setFieldValue('supply', data.supply)
      formik.setFieldValue(
        'explicitAndSensitiveContent',
        data.explicitAndSensitiveContent
      )
      formik.setFieldValue('creatorEarnings', data.creatorEarnings)
      formik.setFieldValue(
        'category',
        categories.find((item) => item.label === data.category)
      )
      // formik.setFieldValue(
      //   'paymentTokens',
      //   data.paymentTokens[0].split(',').map((item) => {
      //     const paymentToken = paymentTokensOptions.find(
      //       (token) => token.value === item
      //     )

      //     paymentToken.name = paymentToken.label
      //     paymentToken.currency = paymentToken.description

      //     return paymentToken
      //   })
      // )
      setLogoImageUrl(process.env.REACT_APP_IMAGE_URL + data.logoImage)
      if (data.featuredImage) {
        setFeaturedImageUrl(
          process.env.REACT_APP_IMAGE_URL + data.featuredImage
        )
      }
      if (data.bannerImage) {
        setBannerImageUrl(process.env.REACT_APP_IMAGE_URL + data.bannerImage)
      }
    }
  }, [typeForm, data])

  return (
    <div className={css.form}>
      <LogoImage
        setImage={setLogoImage}
        imageUrl={logoImageUrl}
        setImageUrl={setLogoImageUrl}
      />
      <FeaturedImage
        setImage={setFeaturedImage}
        imageUrl={featuredImageUrl}
        setImageUrl={setFeaturedImageUrl}
      />
      <BannerImage
        setImage={setBannerImage}
        imageUrl={bannerImageUrl}
        setImageUrl={setBannerImageUrl}
      />
      <Formik
        initialValues={defaultValues}
        validationSchema={schema}
        onSubmit={submitHandler}
        innerRef={formikRef}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                Name <span className={css.red}>*</span>
              </div>
              <Input
                name="name"
                type="text"
                className={css.input}
                classNameInput={css.form_input_wrap}
              />
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                URL <span className={css.red}>*</span>
              </div>
              <Input
                name="url"
                type="text"
                className={css.input}
                classNameInput={css.form_input_wrap}
                leftIcon={
                  <img src={logoImg} alt="icon" className={css.input_icon} />
                }
                prefix={process.env.REACT_APP_URL}
              />
            </div>
            <div className={css.form_textarea}>
              <div className={css.form_input_name}>
                Description <TooltipQuestion id="description" />
              </div>
              <Input
                textarea
                name="description"
                type="text"
                className={css.textarea}
              />
              <div className={css.form_textarea_up}>
                {props.values.description.length} up to 250
              </div>
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                Category <TooltipQuestion id="category" />
              </div>
              <Select
                options={categories}
                placeholder=""
                name="category"
                onChange={props.setFieldValue}
                className={css.select}
                classNameInput={css.input_wrap}
              />
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>Links</div>
              <Links />
            </div>
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                Creator earnings <TooltipQuestion id="creator" />
              </div>
              <CreatorEarnings creatorEarnings={props.values.creatorEarnings} />
            </div>
            <Blockchain
              onChange={props.setFieldValue}
              tooltip={<TooltipQuestion id="blockchain" />}
            />
            <div className={css.form_input}>
              <div className={css.form_input_name}>
                Payment tokens <span className={css.red}>*</span>
                <TooltipQuestion id="payment" />
              </div>
              <PaymentTokens
                paymentTokens={props.values.paymentTokens}
                isEdit={typeForm === 'EDIT'}
              />
            </div>
            <SensitiveContent
              className={css.form_collection_item}
              onChange={props.setFieldValue}
              name="explicitAndSensitiveContent"
              checked={props.values.explicitAndSensitiveContent}
            />
            {typeForm === 'CREATE' ? (
              <Button type="submit" className={css.form_btn}>
                Create
              </Button>
            ) : (
              <div className={css.form_btns}>
                <Button type="submit">Submit changes</Button>
                <Button
                  variant="blue"
                  onClick={() => dispatch(modalRemoveCollectionHandler(true))}
                >
                  Delete collection
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
      <Tooltip id="url">
        Customize your URL on OpenSea. Must only contain lowercase letters,
        numbers, and hyphens.
      </Tooltip>
      <Tooltip id="description">
        Markdown syntax is supported. 0 of 250 characters used.
      </Tooltip>
      <Tooltip id="category">
        Adding a category will help make your item discoverable on OpenSea.
      </Tooltip>
      <Tooltip id="creator">
        Earn a percentage of the sale price every time one of your items is
        sold. Adding multiple addresses may increase gas fees for buyers.
      </Tooltip>
      <Tooltip id="blockchain">
        Select the blockchain where you'd like new items from this collection to
        be added by default.
      </Tooltip>
      <Tooltip id="payment">
        These tokens can be used to buy and sell your items.
      </Tooltip>
      {typeForm === 'CREATE' && <CreateCollectionSuccessModal />}
      {typeForm === 'EDIT' && <DeleteCollectionModal />}
    </div>
  )
}

export default CreateNftCollectionForm
