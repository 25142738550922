export const tabsTable = [
  {
    id: 1,
    text: 'PvP',
  },
  {
    id: 2,
    text: 'PvE',
  },
]

export const tableTitles = [
  {
    id: 1,
    text: 'Rank',
  },
  {
    id: 2,
    text: 'Rating',
  },
  {
    id: 3,
    text: 'Player',
  },
  {
    id: 4,
    text: 'Favorite race',
  },
  {
    id: 5,
    text: 'Wins',
  },
  {
    id: 6,
    text: 'Defeats',
  },
]

export const tableRows = [
  {
    id: 1,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 2,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 3,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 4,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 5,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 6,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 7,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
  {
    id: 8,
    name: 'Fireball',
    race: 'Mars',
    cost: '0.143 ETH',
    seller: 'Custom_seller',
    date: '10/10/2022 10:10 AM',
  },
]
