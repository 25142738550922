import css from './Blockchain.module.scss'
import cn from 'classnames'
// Components
import { Select } from 'UI'
// Utils
import { blockchains } from './utils'

const Blockchain = ({ className, onChange, disabled }) => {
  return (
    <div className={cn(css.form_input, className)}>
      <div className={css.form_input_name}>Blockchain</div>
      <Select
        options={blockchains}
        placeholder=""
        name="blockchain"
        onChange={onChange}
        className={css.select}
        classNameInput={css.input_wrap}
        isSearchable={false}
        disabled={disabled}
      />
    </div>
  )
}

export default Blockchain
