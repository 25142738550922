export const links = [
  {
    id: 1,
    text: 'NFT Item',
    type: 'item',
  },
  {
    id: 2,
    text: 'NFT Card',
    type: 'card',
  },
]
