import ethereumPinkIcon from 'icons/crypto-logo/ethereum-pink.svg'
import ethereumIcon from 'icons/crypto-logo/ethereum.svg'
import daiIcon from 'icons/crypto-logo/dai.svg'

export const methodsOptions = [
  { id: 1, label: 'Sell with declining price', value: 1 },
]

export const priceOptions = [
  { label: 'ETH', icon: ethereumIcon, value: 'eth' },
  { label: 'WETH', icon: ethereumPinkIcon, value: 'weth' },
  { label: 'DAI', icon: daiIcon, value: 'dai' },
]
