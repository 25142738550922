import { useCallback, useRef } from 'react'
import css from './RecommendedSlider.module.scss'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import cn from 'classnames'
// Components
import ArrowRight from 'components/Slider/ArrowRight/ArrowRight'
import ArrowLeft from 'components/Slider/ArrowLeft/ArrowLeft'
import { Title } from 'UI'
// Constants
import { BREAKPOINT_XS, BREAKPOINT_SM, BREAKPOINT_MD } from 'constants'

const responsiveSlider = {
  desktop: {
    breakpoint: { max: 3000, min: BREAKPOINT_MD },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: BREAKPOINT_MD, min: BREAKPOINT_SM },
    items: 3,
  },
  mobile: {
    breakpoint: { max: BREAKPOINT_SM, min: BREAKPOINT_XS },
    items: 2,
  },
  mobileX: {
    breakpoint: { max: BREAKPOINT_XS, min: 0 },
    items: 1,
  },
}

const RecommendedSlider = ({ items, title, className }) => {
  const sliderRef = useRef()

  const slideLeft = useCallback(() => {
    if (sliderRef.current.state.currentSlide !== 0) {
      sliderRef.current.goToSlide(sliderRef.current.state.currentSlide - 1)
    }
  }, [])

  const slideRight = useCallback(() => {
    if (
      sliderRef.current.state.currentSlide !==
      sliderRef.current.state.totalItems - sliderRef.current.state.slidesToShow
    ) {
      sliderRef.current.goToSlide(sliderRef.current.state.currentSlide + 1)
    }
  }, [])

  return (
    <div className={cn(css.slider, className)}>
      <div className={css.slider_top}>
        <Title className={css.slider_title} type="h3">
          {title}
        </Title>
        <div className={css.slider_arrows}>
          <ArrowLeft onClick={slideLeft} />
          <ArrowRight onClick={slideRight} />
        </div>
      </div>
      <div className={css.slider_items}>
        <Carousel
          responsive={responsiveSlider}
          autoPlay={false}
          arrows={false}
          itemClass={css.slider_item}
          ref={sliderRef}
        >
          {items}
        </Carousel>
      </div>
    </div>
  )
}

RecommendedSlider.propTypes = {
  items: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default RecommendedSlider
