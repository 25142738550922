import css from './Spinner.module.scss'
import cn from 'classnames'

const Spinner = ({ className }) => {
  return (
    <div className={cn(css.spinner, className)}>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner
