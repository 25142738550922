import { useCallback } from 'react'
import { Formik } from 'formik'
import css from './Footer.module.scss'
// Images
import logoImg from 'images/logo.svg'
import bscscanIcon from 'icons/social-network/bscscan.svg'
// Components
import { Button, Input, Link } from 'UI'
// Utils
import {
  links,
  menuColumn1,
  menuColumn2,
  menuColumn3,
  socialNetwork,
} from './utils'

const Footer = () => {
  const renderMenu = useCallback(
    (arr) =>
      arr.map((item) => (
        <a
          className={css.footer_menu_item}
          key={item.id}
          href={item.href}
          target="_blank"
          rel="noreferrer"
        >
          {item.text}
        </a>
      )),
    []
  )

  const submitEmail = useCallback((values) => {
    console.log(values)
  }, [])

  return (
    <footer className={css.footer}>
      <div className={css.footer_wrap}>
        <Link className={css.footer_logo} href="/">
          <img src={logoImg} alt="logo" />
        </Link>
        <div className={css.footer_menu}>
          <div className={css.footer_menu_column}>
            {renderMenu(menuColumn1)}
          </div>
          <div className={css.footer_menu_column}>
            {renderMenu(menuColumn2)}
          </div>
          <div className={css.footer_menu_column}>
            {renderMenu(menuColumn3)}
          </div>
        </div>
        <div className={css.footer_join}>
          <div className={css.footer_join_us}>
            <div className={css.footer_subtitle}>
              Sign up for Bot Planet newsletter
            </div>
            <div className={css.footer_description}>
              Join the BOT Planet community and be at the forefront of all
              updates!
            </div>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values) => submitEmail(values)}
            >
              {(props) => (
                <form className={css.footer_form} onSubmit={props.handleSubmit}>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                    className={css.footer_input}
                    classNameInput={css.footer_input_wrap}
                  />
                  <Button
                    variant="blue"
                    type="submit"
                    className={css.footer_form_btn}
                  >
                    Join Us
                  </Button>
                </form>
              )}
            </Formik>
          </div>
          <div className={css.footer_socials}>
            <div className={css.footer_subtitle}>Join our community</div>
            <div className={css.footer_description}>
              We will keep you posted!
            </div>
            <div className={css.footer_socials_items}>
              {socialNetwork?.map((item) => (
                <a
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className={css.footer_socials_item}
                  key={item.id}
                >
                  <img src={item.icon} alt={item.id} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <a
          className={css.footer_bscscan}
          href="https://bscscan.com/address/0x1ab7e7deda201e5ea820f6c02c65fce7ec6bed32#code"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bscscanIcon} alt="bscscan" />
        </a>
        <div className={css.footer_links}>
          {links?.map((item) => (
            <a
              className={css.footer_links_item}
              key={item.id}
              href={item.href}
              target="_blank"
              rel="noreferrer"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className={css.footer_allrights}>
          © 2022 Bot Planet. All rights reserved
        </div>
      </div>
      <div className={css.footer_bg} />
    </footer>
  )
}

export default Footer
