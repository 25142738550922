import css from './SensitiveContent.module.scss'
import cn from 'classnames'
// Components
import { Switch } from 'UI'
// Images
import warningIcon from 'icons/warning.svg'

const SensitiveContent = ({ className, onChange, name, checked }) => {
  return (
    <div className={cn(css.item, className)}>
      <div className={css.item_wrap}>
        <img src={warningIcon} alt="img" className={css.item_icon} />
        <div className={css.item_info}>
          <div className={css.item_title}>Explicit & Sensitive Content</div>
          <div className={css.item_description}>
            Set this item as explicit and sensitive content
          </div>
        </div>
      </div>
      <Switch onChange={(bool) => onChange(name, bool)} checked={checked} />
    </div>
  )
}

export default SensitiveContent
