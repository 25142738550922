import { useCallback } from 'react'
import css from './Connect.module.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Images
import metamaskIcon from 'icons/social-network/metamask.svg'
import botIcon from 'icons/wallets/bot-small.svg'
// Store
import { loginHandlerMetamask, loginHandlerPhantom } from 'store/auth'

const Connect = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const metamaskHandler = useCallback(() => {
    dispatch(loginHandlerMetamask({ navigate }))
  }, [dispatch, navigate])

  const botHandler = useCallback(() => {
    dispatch(loginHandlerPhantom({ navigate }))
  }, [dispatch, navigate])

  return (
    <div className={css.connect}>
      <div className={css.connect_title}>Or connect trough the:</div>
      <div className={css.connect_items}>
        <div className={css.connect_item} onClick={metamaskHandler}>
          <div className={css.connect_item_icon}>
            <img src={metamaskIcon} alt="icon" />
          </div>
          <div className={css.connect_item_name}>Metamask</div>
        </div>
        <div className={css.connect_item} onClick={botHandler}>
          <div className={css.connect_item_icon}>
            <img src={botIcon} alt="icon" />
          </div>
          <div className={css.connect_item_name}>Bot</div>
        </div>
      </div>
    </div>
  )
}

export default Connect
