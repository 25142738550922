import css from './NftCollectionItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
// Components
import { Button, Link } from 'UI'

const NftCollectionItem = ({ className, title, price, image, href }) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_wrapper}>
      <img src={image} alt={title} className={css.item_img} />
    </div>
    <div className={css.item_title}>{title}</div>
    <div className={css.item_bottom}>
      <div className={css.item_price}>{price ? `${price} ETH` : ''}</div>
      <Link href={'/'}>
        <Button className={css.item_btn}>Buy</Button>
      </Link>
    </div>
  </div>
)

NftCollectionItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  image: PropTypes.string,
  href: PropTypes.string,
}

export default NftCollectionItem
