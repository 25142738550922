import { useCallback, useMemo } from 'react'
import css from './UserPage.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Formik } from 'formik'
import cn from 'classnames'
// Components
import MainTabItem from 'components/Items/MainTabItem'
import { Button, Input } from 'UI'
import TabItem from 'components/Items/TabItem'
import NftCardItem from 'components/Items/NftCardItem'
import ProfileAuction from 'components/ProfileAuction'
import ProfileMyNfts from 'components/ProfileMyNfts'
import LayoutWithBg from 'components/LayoutWithBg'
// Images
import planetsImg from 'images/planets.jpeg'
import duckImg from 'images/duck.png'
import searchIcon from 'icons/search.svg'
// Utils
import { tabs, tabsMyNftCards, tabsNftCardsItems } from './utils'
// Helmet
import CustomHelmet from './Helmet'

const UserPage = () => {
  const myCardsContent = useMemo(
    () => (
      <div className={css.items_cards}>
        {tabsNftCardsItems?.map((item) => (
          <NftCardItem {...item} key={item.id} />
        ))}
      </div>
    ),
    []
  )

  const submitSearch = useCallback((values) => {
    console.log(values)
  }, [])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div>
          <div className={css.section_top}>
            <img
              src={planetsImg}
              alt="planets"
              className={css.section_top_bg}
            />
            <div className={css.section_top_avatar}>
              <img src={duckImg} alt="duck" />
            </div>
          </div>
          <div className={css.name}>John Watts</div>
        </div>
        <Tabs className={css.tabs}>
          <TabList className={css.tabs_tablist}>
            <div className={cn(css.tabs_titles, 'main-tabs')}>
              {tabs?.map((item) => (
                <Tab
                  key={item.id}
                  selectedClassName="main-tab-active"
                  className="main-tab"
                >
                  <MainTabItem {...item} className="main-tab-item-active" />
                </Tab>
              ))}
            </div>
          </TabList>
          <TabPanel>
            <ProfileMyNfts />
          </TabPanel>
          <TabPanel>
            <Tabs className={css.tabs}>
              <TabList className={css.tabs_tablist}>
                <div className={css.tabs_tablist_wrap}>
                  {tabsMyNftCards?.map((item) => (
                    <Tab
                      key={item.id}
                      selectedClassName="tab-active"
                      className="tab"
                    >
                      <TabItem {...item} className="tab-item-active" />
                    </Tab>
                  ))}
                </div>
              </TabList>
              <Formik
                initialValues={{ search: '' }}
                onSubmit={(values) => submitSearch(values)}
              >
                {(props) => (
                  <form className={css.tabs_form} onSubmit={props.handleSubmit}>
                    <Input
                      name="search"
                      type="text"
                      placeholder="Search nft"
                      className={css.tabs_input}
                      leftIcon={<img src={searchIcon} alt="search" />}
                    />
                  </form>
                )}
              </Formik>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <TabPanel className={css.tabs_tabpanel}>
                {myCardsContent}
              </TabPanel>
              <div className={css.tabs_btn_wrap}>
                <Button className={css.tabs_btn}>Load More</Button>
              </div>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <ProfileAuction />
          </TabPanel>
        </Tabs>
      </div>
    </LayoutWithBg>
  )
}

export default UserPage
