import { useEffect, useMemo } from 'react'
import css from './NftCardPage.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// Components
import PrevPage from 'components/PrevPage'
import ItemInfo from 'components/ItemInfo'
import NftCollectionItem from 'components/Items/NftCollectionItem'
import RecommendedSlider from 'components/Slider/RecommendedSlider'
import { Button, Collapse } from 'UI'
import HistoryPurchaseTable from 'components/HistoryPurchaseTable'
import { CancelListingModal } from 'components/Popups'
import LayoutWithBg from 'components/LayoutWithBg'
import AddedItemProperty from 'components/Items/AddedItemProperty'
import ProgressItem from 'components/Items/ProgressItem'
// Utils
import { recommendedCollections } from './utils'
// Hooks
import useModal from 'hooks/useModal'
// Helmet
import CustomHelmet from './Helmet'
// Store
import { getNftHandler } from 'store/nft'
// Images
import propertiesIcon from 'icons/properties.svg'
import starIcon from 'icons/star.svg'
import statsIcon from 'icons/stats.svg'

const NftCardPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [cancelListingModal, cancelListingModalOpen, cancelListingModalClose] =
    useModal()
  const nft = useSelector((state) => state.nft.nft)

  const sliderContent = useMemo(
    () =>
      recommendedCollections?.map((item) => (
        <NftCollectionItem key={item.id} {...item} />
      )),
    []
  )

  const properties = useMemo(
    () =>
      nft.properties &&
      nft.properties.length !== 0 &&
      nft.properties.map((item) => (
        <AddedItemProperty {...item} key={item._id} />
      )),
    [nft]
  )

  const levels = useMemo(
    () =>
      nft.levels &&
      nft.levels.length !== 0 &&
      nft.levels.map((item) => (
        <ProgressItem
          {...item}
          min={item.value}
          max={item.total}
          key={item._id}
        />
      )),
    [nft]
  )

  const stats = useMemo(
    () =>
      nft.stats &&
      nft.stats.length !== 0 &&
      nft.stats.map((item) => (
        <ProgressItem
          {...item}
          min={item.value}
          max={item.total}
          key={item._id}
        />
      )),
    [nft]
  )

  useEffect(() => {
    dispatch(getNftHandler(id))
  }, [dispatch, id])

  return (
    <LayoutWithBg>
      <CustomHelmet />
      <div className={css.container}>
        <div className={css.top}>
          <Button onClick={() => navigate(`/nft/${nft._id}/edit`)}>Edit</Button>
          <Button
            variant="blue"
            onClick={() => navigate(`/nft/${nft._id}/sale`)}
          >
            Sell
          </Button>
          <Button variant="blue" onClick={cancelListingModalOpen}>
            Cancel Listing
          </Button>
        </div>
        <PrevPage href="/catalog">Back to the catalog</PrevPage>
        <div className={css.main}>
          <div className={css.main_img}>
            <img src={process.env.REACT_APP_IMAGE_URL + nft.file} alt="file" />
          </div>
          <ItemInfo
            title={nft.name}
            subtitle={nft.type}
            description={nft.description}
            category={nft.blockchain}
            price="0.417009 ETH"
          />
        </div>
        <div className={css.properties}>
          <div>
            <Collapse
              title="Properties"
              icon={propertiesIcon}
              className={css.properties_collapse}
            >
              <div className={css.properties_items}>{properties}</div>
            </Collapse>
            <Collapse
              title="Levels"
              icon={starIcon}
              className={css.properties_collapse}
            >
              <div className={css.properties_items}>{levels}</div>
            </Collapse>
            <Collapse
              title="Stats"
              icon={statsIcon}
              className={css.properties_collapse}
            >
              <div className={css.properties_items}>{stats}</div>
            </Collapse>
          </div>
        </div>
        <HistoryPurchaseTable />
        <RecommendedSlider
          items={sliderContent}
          title="Recommended nft collections"
          className={css.slider}
        />
      </div>
      <CancelListingModal
        open={cancelListingModal}
        closeModal={cancelListingModalClose}
        openModal={cancelListingModalOpen}
      />
    </LayoutWithBg>
  )
}

export default NftCardPage
