// import css from './Switch.module.scss'
import PropTypes from 'prop-types'
import { default as CustomSwitch } from 'react-switch'

const Switch = (props) => (
  <CustomSwitch
    onColor="#d62efc"
    onHandleColor="#fff"
    handleDiameter={20}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    type="checkbox"
    {...props}
  />
)

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default Switch
