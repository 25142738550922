import { takeEvery, call, put } from 'redux-saga/effects'
import {
  collectionsFetch,
  collectionsFetchSuccess,
  nftFetchError,
  nftsFetch,
  nftsFetchSuccess,
} from './catalog'
import { getNftItemsRequest } from 'api/nft'
import { getAllCollectionsRequest } from 'api/collection'

function* workCollections() {
  try {
    const { data } = yield call(getAllCollectionsRequest)
    yield put(
      collectionsFetchSuccess({
        data: data.data,
      })
    )
  } catch (e) {
    yield put(nftFetchError())
  }
}

function* workNfts() {
  try {
    const { data } = yield call(getNftItemsRequest)
    yield put(nftsFetchSuccess({ data: data.data }))
  } catch (e) {
    yield put(nftFetchError())
  }
}

export default function* catalogSagaWatcher() {
  yield takeEvery(collectionsFetch.type, workCollections)
  yield takeEvery(nftsFetch.type, workNfts)
}
