import css from './ItemInfo.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
// Components
import CostItem from 'components/Items/CostItem'

const ItemInfo = ({
  className,
  subtitle,
  title,
  description,
  category,
  price,
}) => (
  <div className={cn(css.info, className)}>
    <div className={css.info_text}>
      <div className={css.info_subtitle}>{subtitle}</div>
      <div className={css.info_title}>{title}</div>
      <div className={css.info_description}>{description}</div>
    </div>
    <div className={css.info_category}>{category}</div>
    <CostItem price={price} className={css.info_cost} />
  </div>
)

ItemInfo.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  price: PropTypes.string,
}

export default ItemInfo
