import cn from 'classnames'
import PropTypes from 'prop-types'
import css from './Button.module.scss'

const Button = ({
  onClick,
  children,
  className,
  variant = 'purple',
  type = 'button',
  disabled = false,
  ...props
}) => (
  <button
    onClick={onClick}
    className={cn(
      css.button,
      css[`_${variant}`],
      disabled && css.disabled,
      className
    )}
    type={type}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['purple', 'blue', 'dark']),
  type: PropTypes.string,
}

export default Button
