import css from './ProfileAuction.module.scss'
// Components
import CatalogItem from 'components/Items/CatalogItem'
// Images
import { auctionNfts } from './utils'

const ProfileAuction = () => {
  return (
    <div className={css.auction}>
      <div className={css.auction_items}>
        {auctionNfts.map((item) => (
          <CatalogItem {...item} key={item.id} />
        ))}
      </div>
    </div>
  )
}

export default ProfileAuction
