import { useCallback, useEffect, useRef, useState } from 'react'
import css from './SignUpPage.module.scss'
import { Formik } from 'formik'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Components
import { Checkbox, Button, Input, Link, Title } from 'UI'
import Connect from 'components/Connect'
// Images
import eyeIcon from 'icons/eye.svg'
import eyeHideIcon from 'icons/eye-hide.svg'
// Store
import { registerHandler } from 'store/auth'
// Helmet
import CustomHelmet from './Helmet'

const SignInPage = () => {
  const dispath = useDispatch()
  const formikRef = useRef()
  const [isUserAgreementChecked, setIsUserAgreementChecked] = useState(false)
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false)
  const [isTypePassword, setIsTypePassword] = useState(false)
  const errors = useSelector((state) => state.auth.errorsReg)
  const navigate = useNavigate()

  const onSubmitHandler = useCallback(
    (values) => {
      dispath(
        registerHandler({
          nickname: values.nickname,
          wallet: values.wallet,
          password: values.password,
          isUserAgreementChecked,
          isPrivacyPolicyChecked,
          navigate,
        })
      )
    },
    [dispath, isUserAgreementChecked, isPrivacyPolicyChecked, navigate]
  )

  const handleUserAgreement = useCallback(() => {
    setIsUserAgreementChecked(!isUserAgreementChecked)
  }, [isUserAgreementChecked])

  const handlePrivacyPolicy = useCallback(() => {
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)
  }, [isPrivacyPolicyChecked])

  const toggleTypePassword = useCallback(() => {
    setIsTypePassword(!isTypePassword)
  }, [isTypePassword])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(errors)
    }
  }, [errors])

  return (
    <>
      <CustomHelmet />
      <Formik
        innerRef={formikRef}
        initialValues={{ nickname: '', wallet: '', password: '' }}
        onSubmit={(values) => onSubmitHandler(values)}
      >
        {(props) => (
          <form className={css.form} onSubmit={props.handleSubmit}>
            <Title className={css.h1}>sign up</Title>
            <Input
              label="Nickname"
              name="nickname"
              type="text"
              placeholder="Johny-tungle"
              className={css.input}
              classNameInput={css.input_wrap}
            />
            <Input
              label="Wallet Number"
              name="wallet"
              type="text"
              placeholder="1044005fddfe994"
              className={css.input}
              classNameInput={css.input_wrap}
            />
            <Input
              type={isTypePassword ? 'text' : 'password'}
              label="Password"
              name="password"
              placeholder="********"
              className={cn(css.input, css.input_password)}
              classNameInput={css.input_wrap}
              rightIcon={
                <div className={css.input_icon}>
                  {isTypePassword ? (
                    <img src={eyeHideIcon} alt="eye" />
                  ) : (
                    <img src={eyeIcon} alt="eye" />
                  )}
                </div>
              }
              onClickRightIcon={toggleTypePassword}
            />
            <Checkbox
              onChange={handleUserAgreement}
              value={isUserAgreementChecked}
              label={
                <div>
                  I have read and accept the{' '}
                  <Link href="/" className={css.link}>
                    user agreement
                  </Link>
                </div>
              }
              id={1}
              className={css.checkbox}
            />
            <Checkbox
              onChange={handlePrivacyPolicy}
              value={isPrivacyPolicyChecked}
              label={
                <div>
                  I have read and accept the{' '}
                  <Link href="/privacy-policy" className={css.link}>
                    privacy policy
                  </Link>
                </div>
              }
              id={2}
              className={css.checkbox}
            />
            <div className={css.btn}>
              <Button type="submit">Sign Up</Button>
            </div>
            <Connect />
            <div className={css.signin}>
              Already have an account?{' '}
              <Link href="/auth/sign-in" className={css.link}>
                Sign in
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SignInPage
