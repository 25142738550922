import { forwardRef } from 'react'
import css from './Input.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import InputMask from 'react-input-mask'

const Input = forwardRef(
  (
    {
      label,
      className,
      classNameInput,
      textarea,
      mask,
      rightIcon,
      onClickRightIcon,
      leftIcon,
      onClickLeftIcon,
      onChange,
      prefix,
      ...other
    },
    ref
  ) => {
    const [field, meta] = useField(other)

    const onChangeHandler = (e) => {
      if (onChange) {
        onChange(e)
      } else {
        field.onChange(e)
      }
    }

    return (
      <div className={cn(css.input, className)}>
        {leftIcon && (
          <div className={cn(css.left_icon)} onClick={onClickLeftIcon}>
            {leftIcon}
          </div>
        )}
        {label && (
          <label htmlFor={field.name} className={css.input_label}>
            {label}
          </label>
        )}
        <div className={cn(css.input_wrap, prefix && css.prefix)}>
          {prefix && (
            <div
              className={cn(
                css.input_prefix,
                leftIcon && css.input_prefix_left_icon
              )}
            >
              {prefix}
            </div>
          )}
          {textarea ? (
            <textarea
              className={cn(
                css.input_field,
                prefix && css.prefix,
                meta.touched && meta.error && css.input_error
              )}
              {...field}
              {...other}
            />
          ) : (
            <InputMask
              className={cn(
                css.input_field,
                meta.touched && meta.error && css.input_error,
                rightIcon && css.input_right_icon,
                leftIcon && css.input_left_icon,
                prefix && css.input_field_prefix,
                classNameInput
              )}
              mask={mask}
              maskChar={null}
              {...field}
              {...other}
              ref={ref}
              onChange={onChangeHandler}
            />
          )}
          {rightIcon && (
            <div className={css.right_icon} onClick={onClickRightIcon}>
              {rightIcon}
            </div>
          )}
          <ErrorMessage component="div" name={field.name}>
            {(msg) => (
              <div
                className={cn(css.input_error_text, textarea && css.textarea)}
              >
                {msg}
              </div>
            )}
          </ErrorMessage>
        </div>
      </div>
    )
  }
)

Input.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  textarea: PropTypes.bool,
  rightIcon: PropTypes.node,
  onClickRightIcon: PropTypes.func,
  leftIcon: PropTypes.node,
  onClickLeftIcon: PropTypes.func,
}

export default Input
