import css from './SidebarLink.module.scss'
import cn from 'classnames'
// Components
import { Link } from 'UI'
// Images
import arrowIcon from 'icons/arrow.svg'

const SidebarLink = ({
  icon,
  text,
  className,
  list,
  clickHandler,
  active,
  activeList,
}) => {
  return (
    <div
      className={cn(css.item, active ? css.active : null, className)}
      onClick={clickHandler}
    >
      <div className={css.item_box}>
        <div className={css.item_wrap}>
          <div className={css.item_img}>
            <img src={icon} alt="icon" />
          </div>
          <span>{text}</span>
        </div>
        {list && (
          <div className={cn(css.item_arrow, activeList && css.active)}>
            <img src={arrowIcon} alt="" />
          </div>
        )}
      </div>
      {activeList && (
        <div className={css.item_list}>
          {list.map((item) => (
            <Link href={item.href} className={css.item_list_link} key={item.id}>
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default SidebarLink
