import { Helmet } from 'react-helmet'

const CustomHelmet = () => {
  const title = 'FAQ | BOTMETAMARKET'

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Here are the most frequently asked questions from our community. Please, make sure to read them through carefully and let us know if you still have any questions left. "
      />
    </Helmet>
  )
}

export default CustomHelmet
