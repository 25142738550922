import css from './Radio.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const Radio = ({ className, name, value, onChange, checked = false, id }) => (
  <div className={cn(css.radio, className)}>
    <div className={css.radio_wrap}>
      <input
        className={css.radio_input}
        type="radio"
        name={name}
        value={value}
        id={id}
        onChange={onChange}
        defaultChecked={checked}
      />
      <div className={css.radio_input_circle} />
    </div>
  </div>
)

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  id: PropTypes.number,
}

export default Radio
