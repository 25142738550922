import css from './NftInfoItem.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Pencil } from 'react-bootstrap-icons'
// Components
import { Button } from 'UI'
import ProgressItem from 'components/Items/ProgressItem'
import AddedItemProperty from 'components/Items/AddedItemProperty'

const NftInfoItem = ({
  className,
  title,
  icon,
  description,
  btnClickHandler,
  addedItems,
  type = 'default',
  typeForm = 'CREATE',
}) => (
  <div className={cn(css.item, className)}>
    <div className={css.item_wrap}>
      <img src={icon} alt={title} className={css.item_icon} />
      <div className={css.item_info}>
        <div className={css.item_title}>{title}</div>
        <div className={css.item_description}>{description}</div>
      </div>
    </div>
    <Button className={css.item_btn} onClick={btnClickHandler}>
      {typeForm === 'CREATE' ? '+' : <Pencil />}
    </Button>
    {addedItems.length !== 0 ? (
      <>
        {type === 'progress' || type === 'withoutProgress' ? (
          <div className={css.item_added_progress}>
            {addedItems.map((item, index) => (
              <ProgressItem
                name={item.name}
                min={item.min}
                max={item.max}
                key={index}
                className={css.item_added_progress_item}
                withProgress={type !== 'withoutProgress'}
              />
            ))}
          </div>
        ) : (
          <div className={css.item_added}>
            {addedItems.map((item, index) => (
              <AddedItemProperty {...item} key={index} />
            ))}
          </div>
        )}
      </>
    ) : null}
  </div>
)

NftInfoItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  description: PropTypes.string,
  btnClickHandler: PropTypes.func,
  addedItems: PropTypes.array,
  type: PropTypes.oneOf(['default', 'progress', 'withoutProgress']),
  typeForm: PropTypes.oneOf(['CREATE', 'EDIT']),
}

export default NftInfoItem
