import instagramIcon from 'icons/social-network/instagram.svg'
import twitterIcon from 'icons/social-network/twitter.svg'

export const socials = [
  {
    id: 1,
    name: 'Instagram',
    image: instagramIcon,
    checked: false,
    edit: false,
  },
  {
    id: 2,
    name: 'Twitter',
    image: twitterIcon,
    active: false,
    edit: false,
  },
]
