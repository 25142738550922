import { useLayoutEffect, useRef } from 'react'
import css from './AddPropertiesModal.module.scss'
import PropTypes from 'prop-types'
import { FieldArray, Formik } from 'formik'
// Components
import { Popup, Button } from 'UI'
import PropertyInput from 'components/PropertyInput'

const AddPropertiesModal = ({
  open,
  closeModal,
  openModal,
  addedItems,
  setFunc,
}) => {
  const formikRef = useRef()
  const initialProperty = [{ type: '', name: '' }]

  const submit = (values) => {
    closeModal()
    const filteredArr = values.properties.filter(
      (item) => item.name.trim() !== '' && item.type.trim() !== ''
    )
    setFunc(filteredArr)
  }

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        formikRef.current.setFieldValue(
          'properties',
          addedItems.length === 0 ? initialProperty : addedItems
        )
      }, 0)
    }
  }, [open])

  return (
    <Popup
      open={open}
      closeModal={closeModal}
      openModal={openModal}
      title="Add Properties"
      description="Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar."
    >
      <Formik
        initialValues={{
          properties: initialProperty,
        }}
        onSubmit={submit}
        innerRef={formikRef}
      >
        {(props) => (
          <div>
            <FieldArray
              name="properties"
              render={(arrayHelpers) => (
                <div>
                  <div className={css.property_titles}>
                    <div className={css.property_title}>Type</div>
                    <div className={css.property_title}>Name</div>
                  </div>
                  {props.values.properties &&
                    props.values.properties.length > 0 &&
                    props.values.properties.map((property, index) => (
                      <PropertyInput
                        name1={`properties.${index}.type`}
                        name2={`properties.${index}.name`}
                        close={() => arrayHelpers.remove(index)}
                        className={css.property_item}
                        key={index}
                      />
                    ))}
                  <Button
                    variant="blue"
                    className={css.property_add}
                    onClick={() =>
                      arrayHelpers.push({
                        type: '',
                        name: '',
                      })
                    }
                  >
                    Add more
                  </Button>
                </div>
              )}
            />
          </div>
        )}
      </Formik>
      <Button
        className={css.popup_btn}
        onClick={() => formikRef.current.handleSubmit()}
      >
        Save
      </Button>
    </Popup>
  )
}

AddPropertiesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  addedItems: PropTypes.array.isRequired,
  setFunc: PropTypes.func.isRequired,
}

export default AddPropertiesModal
