import css from './Table.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

const Table = ({ className, titles, rows, classNameTitles, classNameRows }) => (
  <div className={cn(css.table, className)}>
    <div className={cn(css.table_titles, classNameTitles)}>{titles}</div>
    <div className={cn(css.table_rows, classNameRows)}>{rows}</div>
  </div>
)

Table.propTypes = {
  className: PropTypes.string,
  titles: PropTypes.node,
  rows: PropTypes.node,
  classNameTitles: PropTypes.string,
  classNameRows: PropTypes.string,
}

export default Table
