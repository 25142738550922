import * as yup from 'yup'

export const schema = yup.object().shape({
  description: yup.string().max(250, 'Максимум 250 символов'),
  name: yup.string().required('Введите что-нибудь!'),
  externalLink: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required('Please enter url'),
})

export const formatData = (arr) => {
  return arr.map((item) => ({
    name: item.name,
    value: item.min,
    total: item.max,
  }))
}

export const formatDataToEdit = (arr) => {
  return arr.map((item) => ({
    name: item.name,
    min: item.value,
    max: item.total,
  }))
}

export const formatDataPropertiesToEdit = (arr) => {
  return arr.map((item) => ({
    type: item.type,
    name: item.name,
  }))
}
